<nav aria-label="breadcrumb">
    <ol class="breadcrumb nav-breadcrumb pl-5">
      <li><fa-icon [icon]="faWallet" class="mr-2 h2"></fa-icon></li>
      <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate }}</a></li>
      <li class="breadcrumb-item"><a routerLink="/admin/report" class="text-primary-color-3">{{ "admin.report" | translate }}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{ "admin.wallet-recharges" | translate }}</li>
    </ol>
</nav> 
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-10">          
		<div class="row my-lg-4 my-md-4">
			<div class="col">
				<input class="form-control mr-sm-4" name="search" type="search" placeholder='{{ "list.search" | translate }}' aria-label="Search" (keyup)="onFilterTextBoxChanged($event)">
			</div>
			<div class="col">
                <a class="btn btn-secondary"  [href]="getExportUrlGeneral()">
                    <fa-icon [icon]="faDownload" class="mr-2"></fa-icon>{{ "general.download" | translate }}
                </a>
			</div>
		</div>
		<ag-grid-angular 
			style="width: 100%; height: 520px;"
			class="ag-theme-alpine"
			[rowData]="data"
			[pagination]="true"
			[paginationPageSize]="10"
			[gridOptions]="gridOptions"
			(gridReady)="onGridReady($event)"
			[localeText]="gridLocale"
			>
		</ag-grid-angular>
	</div>   
</div>

    





