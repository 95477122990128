import { Router } from '@angular/router';
import { AuthService } from '../auth-module/auth.service';
import { Component, OnInit } from '@angular/core';
import { faUserCircle, faChartBar } from '@fortawesome/free-regular-svg-icons';
import * as amplitude from "@amplitude/analytics-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  year = new Date().getFullYear();
  faUserCircle = faUserCircle;
  faChartBar = faChartBar;

  constructor(
    public auth: AuthService,
    private router: Router,
  ) {
    const per = this.auth.permissionsValue;
    const isAdmin = per?.find((r) => r == 'Administrador')

    if (auth.currentUserValue && !isAdmin) {
      this.auth.permissions.subscribe((permissions) => {
        permissions?.find((r) => r == 'Administrador') ?
          this.router.navigate(['/admin']) : this.router.navigate(['/raffle']);;
      });
    }
  }

  ngOnInit() {
  }

  visitFacebook(){
    amplitude.track('home_facebook');
  }

  visitInstagram(){
    amplitude.track('home_instagram');
  }

  visitTwitter(){
    amplitude.track('home_twitter');
  }

  visitLinkedIn(){
    amplitude.track('home_linkedin');
  }

  visitWhatsApp(){
    amplitude.track('home_whatsapp');
  }

  visitGooglePlay(){
    amplitude.track('home_google-play');
  }

  visitAppStore(){
    amplitude.track('home_app-store');
  }
}
