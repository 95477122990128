import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RafflerPaymentService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('raffler-payment');
  }

  generatePayment(id: string): Observable<any> {
    return this.http.post(this.apiUrl + `/raffler-payment/generate-payment/${id}`, {});
  }

  uploadEvidence(data: any, rafflerPaymentId): Observable<any> {
    return this.http.post(this.apiUrl + `/raffler-payment/${rafflerPaymentId}/upload-evidence`, data);
  }
}
