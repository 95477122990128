<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faDollarSign" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate
				}}</a>
		</li>
		<li class="breadcrumb-item"><a routerLink="/admin/raffler-payment" class="text-primary-color-3">{{
				"admin.raffler-payments" | translate }}</a>
		</li>
		<li class="breadcrumb-item active" aria-current="page">{{ (view() ? "raffler-payment.view" : (create() ?
			"raffler-payment.create" : "raffler-payment.edit" )) | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-12">
		<form [formGroup]="form" (ngSubmit)="checkUpload()" class="bg-form shadow">
			<div class="d-flex justify-content-center">
				<div class="col-md-6 text-center">
					<h5>{{ "admin.raffler-payments" | translate }}</h5>
				</div>
			</div>
			<h5> {{ "raffler-payment.raffler_detail" | translate }} </h5>
			<div class="form-group" >
				
				<label for="name"> {{ "raffler-payment.name" | translate }}: <strong> {{ data?.raffler?.name }}</strong></label><br>
				<label for="RFC"> {{ "raffler-payment.rfc" | translate }}: <strong> {{ data?.application?.rfc  || 'N/A' }}</strong></label><br>
				<label for="bank_information"> {{ "raffler-payment.ID" | translate }}: <br>
					<a class="btn btn-secondary"  target="_blank" [href]="data?.application?.url_id_front">
						<fa-icon [icon]="faDownload" class="mr-2"></fa-icon>{{ "general.download" | translate }}
					</a>
				</label><br>
				<label for="bank_information"> {{ "raffler-payment.bank_account" | translate }}: <br>
					<a class="btn btn-secondary"  target="_blank" [href]="data?.application?.url_bank_information">
						<fa-icon [icon]="faDownload" class="mr-2"></fa-icon>{{ "general.download" | translate }}
					</a>
				</label><br>
			</div>
			<div class="">
				<h5> {{ "raffler-payment.payment_details" | translate }} </h5>
				<div>
					<table class="table table-striped">
						<thead>
							<tr>
							  <th>{{ "raffler-payment.total_tickets" | translate }}</th>
							  <th>{{ "raffler-payment.sold_tickets" | translate }}</th>
							  <th>{{ "raffler-payment.ticket_price" | translate }}</th>
							  <th>{{ "raffler-payment.ticket_amount" | translate }}</th>
							  <th>{{ "raffler-payment.commission_amount" | translate }}</th>
							  <th>{{ "raffler-payment.iva" | translate }}</th>
							  <th>{{ "raffler-payment.isr" | translate }}</th>
							  <th>{{ "raffler-payment.raffler_amount" | translate }}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
							  <td>{{ data?.raffle?.num_tickets | number:'1.0' }}</td>
							  <td>{{ data?.raffle?.sold_tickets | number:'1.0' }}</td>
							  <td>{{ (data?.raffle?.ticket_price || 0) | currency }}</td>
							  <td>{{ (data?.ticket_amount || 0) | currency }}</td>
							  <td>{{ (data?.commission_amount || 0) | currency }}</td>
							  <td>{{ (data?.commission_iva || 0) | currency }}</td>
							  <td>{{ (data?.isr_amount || 0) | currency }}</td>
							  <td>{{ (data?.raffler_amount || 0) | currency }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="form-group">
				<label for="active"> {{ "raffler-payment.status" | translate }}:</label><br>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status"
						type="radio" name="status" id="status1" value="pending">
					<label class="form-check-label" for="status1">{{ "raffler-payment.status_raffler.pending" |
						translate}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status"
						type="radio" name="status" id="status2" value="rejected">
					<label class="form-check-label" for="status2">{{ "raffler-payment.status_raffler.rejected" |
						translate}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status"
						type="radio" name="status" id="status3" value="accepted">
					<label class="form-check-label" for="status3">{{ "raffler-payment.status_raffler.accepted" |
						translate}}</label>
				</div>
			</div>
			<div class="form-group" *ngIf="f.status.value == 'rejected'">
				<label for="comments"> {{ "raffler-payment.comments" | translate }}:</label><br>
				<textarea class="form-control" [readonly]="view()" formControlName="comments" rows="3" [ngClass]="{ 'is-invalid': submitted && f.comments.errors }">
				</textarea>
				<div *ngIf="submitted && f.status.value == 'rejected'" class="invalid-feedback2">
					<div *ngIf="submitted && f.status.value == 'rejected'">{{ "raffler-payment.comments_required" | translate }}</div>
				</div>
			</div>

			<div class="d-flex justify-content-center" *ngIf="imageUrl">
                <div class="col-md-4 col-sm-12">
                    <label for="name">{{ "raffler-payment.evidence" | translate }}:</label>
                    <img  class="img-fluid" src="{{ imageUrl }}">
                </div>
            </div>
			<div class="form-group" *ngIf="!view()">
				<label for="zone">{{ "crop.image" | translate }}:</label><br>
				<div class="input-group mb-3">
					<div class="custom-file">
						<input type="file" class="custom-file-input" (change)="fileChangeEvent($event)"
							id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
						<label class="custom-file-label" for="inputGroupFile01">{{ "crop.image_chose" | translate
							}}</label>
					</div>
					<div *ngIf="submitted && f.status.value === 'accepted' && !imageUrl" class="invalid-feedback2">
						<div *ngIf="submitted && f.status.value === 'accepted' && !imageUrl">{{ "raffler-payment.evidence_required" | translate }}</div>
					</div>
				</div>
				<label class="text-danger">*{{ "crop.max_image_size" | translate }}</label>
				<div class="row">
					<div class="col-md-6">
						<label *ngIf="showCropper">{{ "crop.pick_face" | translate }}</label>
						<image-cropper 
							[imageChangedEvent]="imageChangedEvent"
							[maintainAspectRatio]="false"
							[containWithinAspectRatio]="false"
							[onlyScaleDown]="true" format="png"
							[cropperMinWidth]="128"
							[style.display]="showCropper ? null : 'none'"
							(imageCropped)="imageCropped($event)"
							(imageLoaded)="imageLoaded()"
							(cropperReady)="cropperReady($event)"
							(loadImageFailed)="loadImageFailed()">
						</image-cropper>
						<button type="button" class="btn btn-success mr-3" *ngIf="showCropper" (click)="endCrop()">{{
							"crop.accept" | translate }}</button>
					</div>
					<div class="col-md-6 cropper-image">
						<label *ngIf="showCropper" for="">{{ "crop.preview" | translate }}</label>
						<br>
						<img *ngIf="showCropper" [src]="croppedImage" width="400px" />
						<br>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button class="btn btn-danger mr-3" routerLink="/admin/raffler-payment">{{ "form.cancel" | translate
					}}</button>
				<button type="submit" class="btn btn-primary" *ngIf="create()">{{ "form.save" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="edit()">{{ "form.update" | translate }}</button>
			</div>
		</form>
	</div>
</div>