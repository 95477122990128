import { ProfileRaffleDetailComponent } from './profile-raffle-detail/profile-raffle-detail.component';
import { ProfileRafflesComponent } from './profile-raffles/profile-raffles.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfirmationEmailComponent } from './confirmation-email/confirmation-email.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';
import { ResendComponent } from './resend/resend.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyComponent } from './verify/verify.component';


const routes: Routes = [
  {
    path: 'admin/login',
    component: LoginComponent
  }, {
    path: 'profile',
    component: ProfileComponent
  },{
    path: 'verify',
    component: VerifyComponent
  },{
    path: 'resend',
    component: ResendComponent
  },
  {
    path: 'confirmation-email',
    component: ConfirmationEmailComponent
  },{
    path: 'reset-password',
    component: ResetPasswordComponent
  },{
    path: 'profile/raffles',
    component: ProfileRafflesComponent
  }, {
    path: 'profile/raffles/:id/:slug',
    component: ProfileRaffleDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
