<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faUserCircle" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate
				}}</a></li>
		<li class="breadcrumb-item active" aria-current="page">{{ "admin.client-users" | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-10">
		<div class="row my-lg-4 my-md-4">
			<div class="col">
				<input class="form-control mr-sm-4" type="search" placeholder='{{ "list.search" | translate }}'
					aria-label="Search" (keyup)="onFilterTextBoxChanged($event)">
			</div>
		</div>
		<ag-grid-angular style="width: 100%; height: 520px;" class="ag-theme-alpine" [rowData]="data"
			[pagination]="true" [paginationPageSize]="10" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)"
			[localeText]="gridLocale">
		</ag-grid-angular>
	</div>
</div>