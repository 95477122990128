import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../shared/alertify.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  submitted;
  loading;
  redirect;

  email = '';
  password = '';
  confirm = '';
  token = '';

  constructor(public auth: AuthService,
    public route: ActivatedRoute,
    public alertify: AlertifyService,
    public router: Router) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['token'];
    this.email = this.route.snapshot.queryParams['email'];
    this.redirect = '/';
  }

  submit() {
    if (this.email !== '' && this.password !== '' && this.confirm !== '') {
      const data = {
        email: this.email,
        password: this.password,
        password_confirmation: this.confirm,
        token: this.token
      };
      this.loading = true;
      this.auth.resetConfirm(data).subscribe((response) => {
        if (response) {
          this.alertify.success('Contraseña actualizada');
          this.router.navigate([this.redirect]);
        } else {
          this.alertify.error('El token ha expirado');
          this.router.navigate([this.redirect]);
        }
        this.loading = false;
      }, (error) => {
        this.alertify.error('Error al restablecer la contraseña');
        this.loading = false;
      });
    } else {
      this.alertify.error('Complete los campos');
    }
    return;
  }
}
