import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  protected apiUrl: string;
  protected object: string;

  constructor(protected http: HttpClient) {
    this.apiUrl = environment.apiUrl;
    this.object = 'report';
  }

  raffles(): Observable<any> {
    return this.http.get(this.apiUrl + '/report/raffles');
  }

  sales(): Observable<any> {
    return this.http.get(this.apiUrl + '/report/sales');
  }

  winners(): Observable<any> {
    return this.http.get(this.apiUrl + '/report/winners');
  }

  recharges(): Observable<any> {
    return this.http.get(this.apiUrl + '/report/wallet-recharges');
  }

}
