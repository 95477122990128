// Angular
import { Component } from '@angular/core';
// AG-Grid
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import {
	faEye,
	faTrash,
	faPencilAlt,
	faKey,
	faPlus,
	faBan,
	faMoneyBill
} 
from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-actions-cell-renderer',
	templateUrl: './actions-cell.renderer.html',
	styleUrls: ['./actions-cell.renderer.css']
})
export class ActionsCellRenderer implements ICellRendererAngularComp {
	faEye = faEye;
	faTrash = faTrash;
	faPencilAlt = faPencilAlt;
	faKey = faKey;
	faPlus = faPlus;
	faBan = faBan;
	faMoneyBill = faMoneyBill;
	icons: Array<any>;
	public actions: any;
	public id;
	public disabled: boolean;
	private data: any;

	/**
	 * When the component renders, it got the possible value
	 * @param params
	 */
	agInit(params: ICellRendererParams): void {
		this.data = params.data;
		this.icons = [];
		this.icons['faKey']       = this.faKey;
		this.icons['faEye']       = this.faEye;
		this.icons['faPencilAlt'] = this.faPencilAlt;
		this.icons['faTrash']     = this.faTrash;
		this.icons['faPlus']      = this.faPlus;
		this.icons['faBan']       = this.faBan;
		this.icons['faMoneyBill'] = this.faMoneyBill;
		this.id = params['value'];
		this.actions = params['actions'];

		this.disabled = true;
	}

	/**
	 * Put back the value
	 * @param params
	 */
	refresh(params: ICellRendererParams): boolean {
		return true;
	}

	onClick(params) {
		params.action(this.id);
	}

	isDisabled(params): boolean {
		if (typeof params['disabled'] === 'function') {
			return params['disabled'](this.data);
		}

		return false;
	}
}
