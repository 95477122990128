<div class="header" *ngIf="!auth.currentUserValue">
    <div class="row justify-content-between align-items-center mb-4 mt-2">
      <div class="col-auto">
        <div>
          <a routerLink="/"><img src="assets/images/rafiki_logo.png" style="width: 150px"></a>
        </div>
      </div>
    </div>
</div>
<div class="container mt-4">
  <div class="row">
    <div class="col-12 bodyPdf">
      <div class="font-weight-bold text-orange h3 ml-2">
          <p> {{ "home.terms" | translate }} </p>
      </div>
      <div class="ml-2">
          <pdf-viewer 
          src="assets/docs/terminos.pdf"
          [render-text]="true"
          [original-size]="false"
          [zoom]="screenSizeClass === 'screen-xs' ? 2 : 1"
          ></pdf-viewer>
      </div>
    </div>
  </div>
</div>

