import { Component, OnInit, ViewChild } from '@angular/core';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { ListService } from '../../shared/list-service.service';
import { ListComponent } from '../../shared/list/list.component';

import { TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../shared/list/locale/es.locale';
import { RaffleService } from '../raffle.service';
import { faPlus, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { ActionsCellRenderer } from '../../shared/list/renderer/actions-cell.renderer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-raffle-list',
  templateUrl: './raffle-list.component.html',
  styleUrls: ['./raffle-list.component.css'],
  providers: [ListService, DecimalPipe, CurrencyPipe]
})
export class RaffleListComponent extends ListComponent implements OnInit {
  @ViewChild('cancelModal', { static: false }) cancelModal;
  faPlus = faPlus;
  faTicketAlt = faTicketAlt;
  errorModal = false;
  modal;
  currentId;
  comments = '';

  //private gridApi;
  //public gridOptions: any;
  //public gridLocale;

  constructor(
    public service: RaffleService,
    public listService: ListService,
    public modalService: NgbModal,
    public translateService: TranslateService,
    public currencyPipe: CurrencyPipe
  ) {
    super(service, listService);
    this.setObject('admin/raffle');
  }

  ngOnInit() {
    super.ngOnInit();
    this.gridLocale = AG_GRID_LOCALE_ES;
    this.gridOptions = {
      columnDefs: [
        { headerName: 'raffle.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this), width: 300 },
        { headerName: 'raffle.raffler', field: 'raffler.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        { headerName: 'raffle.start', field: 'start', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        { headerName: 'raffle.end', field: 'finish', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        {
          headerName: 'raffle.status',
          field: 'status',
          sortable: true,
          cellRenderer: params =>
            this.translate.instant('raffle.status_def.' + params.value)
          ,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          headerName: 'raffle.sold_tickets',
          field: 'sold_tickets',
          sortable: true,
          cellRenderer: params =>
            params.data.sold_tickets + ' / ' + params.data.num_tickets
          ,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          headerName: 'raffle.accumulated',
          field: 'sold_tickets',
          sortable: true,
          cellRenderer: params =>
            this.currencyPipe.transform(params.data.sold_tickets * params.data.ticket_price, 'USD', 'symbol', '1.2-2')
          ,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          headerName: 'list.actions',
          field: 'id',
          cellRenderer: 'actionsCellRenderer',
          cellRendererParams: {
            actions: [
              {
                icon: 'faEye',
                action: (id) => {
                  this.show(id);
                }
              },
              {
                icon: 'faBan',
                action: (id) => {
                  this.cancel(id);
                },
                disabled: (data) =>
                  data.status != 'pending'
              }
            ]
          },
          headerValueGetter: this.localizeHeader.bind(this),
          width: 200
        }
      ],
      frameworkComponents: {
        'actionsCellRenderer': ActionsCellRenderer
      }
    };

    this.loadData();
  }

  cancel(id) {
    this.currentId = id;
    this.modal = this.modalService.open(this.cancelModal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      //this.cleanModal();
    }, (reason) => {
      //this.cleanModal();
    });
  }

  cancelRaffle(id, comment) {

    if (comment == undefined || comment.trim() == '') {
      this.errorModal = true;
      return;
    }

    this.errorModal = false;

    this.service.cancel(id, comment).subscribe((raffle) => {
      this.alertify.success(this.translate.instant('raffle.cancel.confirmation'));
      this.loadData();
      this.modalService.dismissAll();
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));
      this.modalService.dismissAll();
    });
  }

  onFilterTextBoxChanged($event): void {
    this.gridApi.setQuickFilter($event.target.value);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.refreshHeader();
    this.gridApi.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    }
  }

  loadData() {
    this.service.admin().subscribe((data) => {
      this.data = data;
      this.listService.setData(data);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.list.load_error'));
    });
  }
}
