import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuardGuard } from '../admin/admin-guard.guard';
import { AuthGuard } from '../auth-module/auth.guard';
import { RaffleFormComponent } from './raffle-form/raffle-form.component';
import { RaffleListComponent } from './raffle-list/raffle-list.component';

const routes: Routes = [
  {
		path: 'admin',
		children: [
      {
        path: 'raffle',
        component: RaffleListComponent,
        canActivate: [AuthGuard]
      },{
        path: 'raffle/createbk',
        component: RaffleFormComponent,
        canActivate: [AuthGuard]
      },{
        path: 'raffle/view/:id',
        component: RaffleFormComponent,
        canActivate: [AuthGuard]
      },{
        path: 'raffle/edit/:id',
        component: RaffleFormComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RaffleRoutingModule { }
