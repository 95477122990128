<div class="line">
	<div class="line-short yellow"></div>
	<div class="line-large green"></div>
</div>
<div id="footer" class="container section">
	<div class="row title">
		<div class="col-12">
			<h1>M&Aacute;S INFORMACI&Oacute;N</h1>
			<div class="points-white">
				. . . . . . . . . . . . . . . . . .
			</div>
		</div>
	</div>
	<div class="row content">
		<div class="col-md col-sm-auto">
			<h3>General</h3>
			<div class="thin-line"></div>
			<ul>
				<li><a class="link" target="_blank" href="/privacy" >Aviso de Privacidad</a></li>
				<li><a class="link" target="_blank" href="/terms" >T&eacute;rminos y condiciones</a></li>
			</ul>
		</div>
		<div class="col-md col-sm-auto">
			<h3>Ayuda</h3>
			<div class="thin-line"></div>
			<ul>
				<li><a class="link" target="_blank" href="/faqs" (click)="visitFAQ()">Preguntas frecuentes</a></li>
				<li><a class="link" href="mailto:soporte@rafiki.mx" (click)="emailSupport()">soporte@rafiki.mx</a></li>
			</ul>
		</div>
		<div class="col-md col-sm-auto">
			<h3>S&iacute;guenos en</h3>
			<div class="thin-line"></div>
			<a href="https://facebook.com/rafikimx" target="_blank" (click)="visitFacebook()"><img
					src="assets/images/home/footer/svg/facebook.svg" class="social-icon" /></a>
			<a href="https://instagram.com/rafikimexico" target="_blank" (click)="visitInstagram()"><img
					src="assets/images/home/footer/svg/instagram.svg" class="social-icon" /></a>
      <a href="https://twitter.com/rafikimx" target="_blank" (click)="visitTwitter()"><img
        src="assets/images/home/footer/svg/twitter.svg" class="social-icon" /></a>
<!--      <a href="https://linkedin.com/company/rafikimx/" target="_blank" (click)="visitLinkedIn()"><img-->
<!--        src="assets/images/home/footer/svg/linkedin.svg" class="social-icon" /></a>-->
      <a href="https://wa.me/4421568386" target="_blank" (click)="visitWhatsApp()"><img
        src="assets/images/home/footer/svg/whats.svg" class="social-icon" /></a>
		</div>
	</div>
	<div class="row r">
		<div class="col-md-12 col-sm-12">
			<div class="logo-line left"></div>
			<div class="logo-circle">
				<img src="assets/images/home/footer/svg/R.svg" />
			</div>
			<div class="logo-line right"></div>
		</div>
	</div>
	<div class="row rights">
		<div class="col-md-6 col-sm-12">
			&copy; {{ year }} Servicios Comerciales Rafiki SA de CV - Todos los derechos reservados
		</div>
<!--		<div class="col-md-6 col-sm-12">-->
<!--			Desarrollado por <a href="https://www.strappcorp.com" target="_blank">Strappcorp</a>-->
<!--		</div>-->
	</div>
</div>
