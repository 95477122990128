import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { SubcategoryService } from '../subcategory.service';
import { CategoryService } from '../../category/category.service';
import { Validators } from '@angular/forms';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-subcategory-form',
	templateUrl: './subcategory-form.component.html',
	styleUrls: ['./subcategory-form.component.css']
})
export class SubcategoryFormComponent extends FormComponent implements OnInit {
	public categories: Array<any>;
	faClipboardList = faClipboardList;

	constructor(
		public subcategoryService: SubcategoryService,
		public categoryService: CategoryService,
	) {
		super(subcategoryService);
	}

	ngOnInit() {
		super.ngOnInit();

		this.redirect = '/admin/subcategory';
		this.form = this.formBuilder.group({
			category_id: ['', Validators.required],
			name: ['', Validators.required],
			description: ['', Validators.required],
			active: ['1']
		});
	}

	getExtras() {
		this.categoryService.all().subscribe((response) => {
			this.categories = response;
		}, (error) => {
			this.alertify.error(this.translate.instant('general.error'));
		});
	}

	dataToForm(data) {
		return {
			category_id: data.category_id,
			name: data.name,
			description: data.description,
			active: '' + data.active
		};
	}

	formToData() {
		this.data = {
			category_id: this.f.category_id.value,
			name: this.f.name.value,
			description: this.f.description.value,
			active: this.f.active.value
		};
	}
}
