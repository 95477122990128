import { NotificationService } from 'src/app/shared/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from './../shared/alertify.service';
import { AuthService } from '../auth-module/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { faSearch, faPlusCircle, faSignOutAlt, faInfoCircle, faClipboardCheck, faUser, faAngleRight, faCreditCard, faBell, faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-user-nav',
  templateUrl: './user-nav.component.html',
  styleUrls: ['./user-nav.component.css']
})
export class UserNavComponent implements OnInit {
  faSearch = faSearch;
  faPlus = faPlusCircle;
  faSignOut = faSignOutAlt;
  faInfo = faInfoCircle;
  faClipboardCheck = faClipboardCheck;
  faUser = faUser;
  faAngleRight = faAngleRight;
  faCreditCard = faCreditCard;
  faBell = faBell;
  faHome = faHome;
  search = "";
  notifications = 0;

  constructor(
    public auth: AuthService,
    private router: Router,
    public alertify: AlertifyService,
    public modalService: NgbModal,
    public notificationService: NotificationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe((params) => this.search = params.search);

    this.notificationService.index().subscribe((response) => {
      this.notifications = response.data.length;
    }, (error) => {
      console.log(error);
    });
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/']);
  }

  searchInput(event) {
    if (event.keyCode == 13) {
      this.router.navigate(['/raffle'],
        { queryParams: { search: this.search }, queryParamsHandling: 'merge' });
    }
  }

  createRaffle(modal) {
    if (this.auth.currentUserValue?.user?.role === 'Rifante') {
      this.router.navigate(['/raffle/create'])
    } else {
      this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: 'sm' });
    }
  }

  goToProfile(modal) {
    modal.dismiss('')
    this.router.navigate(['/profile'])
  }
}
