import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { RaffleReportService } from '../raffle-report.service';
import { Validators } from '@angular/forms';
import { faExclamationCircle, } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-raffle-report-form',
	templateUrl: './raffle-report-form.component.html',
	styleUrls: ['./raffle-report-form.component.css']
})
export class RaffleReportFormComponent extends FormComponent implements OnInit {
	faExclamationCircle = faExclamationCircle;
	
	constructor(
		public raffleReportService: RaffleReportService
	) {
		super(raffleReportService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.redirect = '/admin/raffle-report';
		this.form = this.formBuilder.group({
			raffle: [''],
			user: [''],
			admin: [''],
			status: [''],
			comments: [''],
			admin_comments: ['', Validators.required]
		});
	}

	dataToForm(data) {
		return {
			raffle: data.raffle.name,
			user: data.user.name,
			admin: data.admin ? data.admin.name : '',
			status: data.status,
			comments: data.comments,
			admin_comments: data.admin_comments
		};
	}

	formToData() {
		this.data = {
			admin_comments: this.f.admin_comments.value,
			status: this.f.status.value
		};
	}

	onSubmit() {
		this.submitted = true;

		if ((this.f.status.value != 'pending' && this.form.invalid) || !this.validation()) {
			this.alertify.error(this.translate.instant('general.form.complete_fields'));
			return false;
		}

		this.formToData();

		if (this.create()) {
			this.save();
		}

		if (this.edit()) {
			this.update();
		}
	}
}
