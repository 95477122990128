<nav aria-label="breadcrumb">
  <ol class="breadcrumb nav-breadcrumb pl-5">
    <li><img src="assets/images/roles.png" class="breadcrum-image"></li>
    <li class="breadcrumb-item"><a routerLink="/home" class="text-primary-color-3">{{ "home.home" | translate }}</a></li>
    <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
    <li class="breadcrumb-item" aria-current="page"><a routerLink="/role" class="text-primary-color-3">{{ "admin.roles" | translate }}</a></li>
  </ol>
</nav>
<div class="d-flex justify-content-center pt-3">
    <div class="col-md-10">
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
                <h5>{{ "admin.roles" | translate }}</h5>
          </div>
        <div class="form-group">
          <label for="name">{{ "role.form.name" | translate }}:</label>
          <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">{{ "role.errors.name" | translate }}</div>
          </div>
        </div>
        <div formArrayName="permissions"
        *ngFor="let permission of permissions; let i = index;">
          <div class="form-check">
            <input class="form-check-input" [formControlName]="i" type="checkbox">
            <label class="form-check-label" for="name">{{permissions[i].name}}</label>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="btn btn-danger mr-3" routerLink="/role">{{ "form.cancel" | translate }}</button>
          <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
          <button type="submit" class="btn btn-primary" *ngIf="edit()" >{{ "form.update" | translate }}</button>
        </div>
      </form>
    </div>
  </div>
