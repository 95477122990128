export const environment = {
  production: true,
  apiUrl: 'https://back.rafiki.mx/api',
  baseUrl: 'https://back.rafiki.mx',
  homeUrl: 'https://rafiki.mx/home',
  facebookId: '282592933636471',
  conekta_public_key: 'key_UsropRvsQKq4sY2rx75qJyA',
  browserUrl: '../browser',
  amplitude_api_key: '7289174f4db9570b802d7802e8113725'
};
