import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { ApplicationService } from '../application.service';
import { Validators } from '@angular/forms';
import { faIdCard } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-application-form',
	templateUrl: './application-form.component.html',
	styleUrls: ['./application-form.component.css']
})
export class ApplicationFormComponent extends FormComponent implements OnInit {
	faIdCard = faIdCard;
	constructor(
		public applicationService: ApplicationService
	) {
		super(applicationService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.redirect = '/admin/application';
		this.form = this.formBuilder.group({
			rejection_reason: ['', Validators.required],
			approval: ['1'],
			url_id_front: [],
			url_id_back: [],
			url_bank_information: [],
			rfc: [],
			url_rfc_documentation: [],
		});
	}

	dataToForm(data) {
		return {
			rejection_reason: data.rejection_reason,
			approval: '' + data.approval,
			url_id_front: data.url_id_front,
			url_id_back: data.url_id_back,
			url_bank_information: data.url_bank_information,
			rfc: data.rfc,
			url_rfc_documentation: data.url_rfc_documentation,
		};
	}

	formToData() {
		this.data = {
			rejection_reason: this.f.rejection_reason.value,
			approval: this.f.approval.value
		};
	}

	onSubmit() {
		this.submitted = true;
		if ((this.f.approval.value == '0' && this.form.invalid) || !this.validation()) {
			this.alertify.error(this.translate.instant('general.form.complete_fields'));
			return;
		}

		this.formToData();

		if (this.create()) {
			this.save();
		}

		if (this.edit()) {
			this.update();
		}
	}
}
