<div class="row height-100">
  <div class="col-12">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 mb-4 px-4">
        <img src="assets/images/home/header/webp/logo.webp" class="logo"/>
      </div>
      <div class="col-12 px-4">
          <p class="h4 font-weight-bold text-success">{{ "index.welcome" | translate }}</p>
      </div>
      <div class="col-12 px-4">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="col-12 text-primary-color-3">
          <div class="row justify-content-around">
            <div class="form-group col-6 px-2">
              <label for="name">{{ "register.name" | translate }}:</label>
              <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                  <div *ngIf="f.name.errors.required">{{ "register.errors.required" | translate }}</div>
              </div>
            </div>
            <div class="form-group col-6 px-2">
              <label for="last_name">{{ "register.last_name" | translate }}:</label>
              <input class="form-control" [readonly]="view()" formControlName="last_name" type="text" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }">
              <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                  <div *ngIf="f.last_name.errors.required">{{ "register.errors.required" | translate }}</div>
              </div>
            </div>

            <div class="form-group col-6 px-2">
              <label for="nickname">{{ "register.nickname" | translate }}:</label>
              <input class="form-control" [readonly]="view()" (keyup)="keyupNickname($event)" formControlName="nickname" type="text" [ngClass]="{ 'is-invalid': submitted && f.nickname.errors }">
              <div *ngIf="f.nickname.errors" class="invalid-feedback">
                  <div *ngIf="submitted &&  f.nickname.errors.required">{{ "register.errors.required" | translate }}</div>
                  <div *ngIf="f.nickname.errors.not_valid">Este nombre de usuario no esta disponible</div>
              </div>
              <div *ngIf="!validNickname" style="display: block;" class="invalid-feedback">
                <div>Este nombre de usuario no esta disponible</div>
              </div>
            </div>

            <div class="form-group col-6 px-2">
              <label for="user.email">{{ "register.email" | translate }}:</label>
              <input class="form-control" [readonly]="view() || edit()" (keyup)="keyupEmail($event)" name="email" type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
              <div *ngIf="f.email.errors" class="invalid-feedback">
                  <div *ngIf="submitted && f.email.errors.required">{{ "register.errors.required" | translate }}</div>
                  <div *ngIf="submitted && f.email.errors.email">{{ "client-user.errors.email_valid" | translate }}</div>
                  <div *ngIf="f.email.errors.not_valid">Este correo ya esta registrado en el sistema</div>
              </div>
              <div *ngIf="!validEmail" style="display: block;" class="invalid-feedback">
                <div>Este correo ya esta registrado en el sistema</div>
              </div>
            </div>

            <div class="form-group col-6 px-2">
              <label for="code_area">{{ "register.code_area" | translate }}:</label>
              <input class="form-control" name="code_area" formControlName="code_area" type="number" maxlength=4>
          </div>

            <div class="form-group col-6 px-2">
              <label for="phone">{{ "register.phone" | translate }}:</label>
              <input class="form-control" name="phone" formControlName="phone" type="number" pattern="[0-9]{8,13}" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
              <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                  <div *ngIf="f.phone.errors.required">{{ "register.errors.required" | translate }}</div>
                  <div *ngIf="f.phone.errors.pattern">{{ "register.errors.phone_pattern" | translate }}</div>
              </div>
          </div>

            <div class="form-group col-6 px-2">
              <label for="user.password ">{{ "register.password" | translate }}:</label>
              <input class="form-control" name="password" type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">{{ "register.errors.required" | translate }}</div>
              </div>
            </div>
            <div class="form-group col-6 px-2">
              <label for="repeat-password">{{ "register.confirm_password" | translate }}:</label>
              <input class="form-control" name="repeat-password" type="password" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" pattern="{{ f.password.value }}">
              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">{{ "client-user.errors.confirm_password" | translate }}</div>
                <div *ngIf="f.confirmPassword.errors.pattern">{{ "client-user.change_password.confirmation_password_failed" | translate }}</div>
              </div>
            </div>
            <div class="form-check col-auto my-2">
              <input type="checkbox" class="form-check-input" id="terms" #terms (change)="changeTerms(terms)">
              <label class="form-check-label" for="terms">Aceptar <a routerLink="/terms" target="_blank">términos y condiciones</a></label>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <button type="submit" [disabled]="loading || !acceptTerms" class="btn btn-success text-white font-weight-bold btn-block rounded-pill" >
              {{ "index.sign_up" | translate }}
            </button>
            <img
                  *ngIf="loading"
                  class="pl-2"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
