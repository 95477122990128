import { UserModule } from './../user-module/user.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { PublicModuleRoutingModule } from './public-module-routing.module';
import { RaffleComponent } from './raffle/raffle.component';
import { RaffleDetailComponent } from './raffle-detail/raffle-detail.component';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RaffleFormComponent } from './raffle-form/raffle-form.component';
import { RafflerWinnerDeliveryComponent } from './raffler-winner-delivery/raffler-winner-delivery.component';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { DeliveryInformationComponent } from './delivery-information/delivery-information.component';
import { ArchwizardModule } from 'angular-archwizard';
import { RaffleTicketComponent } from './raffle-ticket/raffle-ticket.component';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ResultsComponent } from './results/results.component';
import { WalletComponent } from './wallet/wallet.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DigitalPrizeComponent } from './digital-prize/digital-prize.component';
import { MyAddressComponent } from './my-address/my-address.component';
import { MyAddressFormComponent } from './my-address-form/my-address-form.component';
import { AuthModule } from '../auth-module/auth.module';
import { AngularFormsInputMasksModule } from 'angular-forms-input-masks';

@NgModule({
  declarations: [
    RaffleComponent,
    RaffleDetailComponent,
    RaffleFormComponent,
    RaffleTicketComponent,
    RafflerWinnerDeliveryComponent,
    MyTicketsComponent,
    DeliveryInformationComponent,
    ResultsComponent,
    WalletComponent,
    NotificationsComponent,
    DigitalPrizeComponent,
    MyAddressComponent,
    MyAddressFormComponent,
  ],
  imports: [
    CommonModule,
    PublicModuleRoutingModule,
    TranslateModule,
    AgGridModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgbModule,
    NgxLoadingModule,
    ImageCropperModule,
    ArchwizardModule,
    UserModule,
    ShareButtonsModule,
    ShareIconsModule,
    AuthModule,
    AngularFormsInputMasksModule
  ]
})
export class PublicModule { }
