import { AddressService } from './../address.service';
import { Component, OnInit } from '@angular/core';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RaffleWinnerService } from '../raffler-winner-delivery/raffle-winner.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-delivery-information',
  templateUrl: './delivery-information.component.html',
  styleUrls: ['./delivery-information.component.css']
})
export class DeliveryInformationComponent implements OnInit {

  currentID: string;
  addressID: string;
  winnerID: any;
  raffles: any = [];
  address: any = [];
  form: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  sizeImage2: string = '300px';
  deliveryVoucherUrl: any;
  receivedPrize: any;
  productType: any;
  enableProductType: boolean;
  urlInstructions: any;

  constructor(
    private addressService: AddressService,
    private raffleService: RaffleService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private raffleWinnerService: RaffleWinnerService,
    private alertify: AlertifyService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  public listRaffles: Array<any> = [];

  ngOnInit(): void {
    this.createForm();
    this.currentID = this.route.snapshot.paramMap.get('id');
    this.raffleService.get(this.currentID).subscribe((response) => {
      this.raffles = response;
      this.addressID = this.raffles?.raffle_winner?.address_id;
      this.winnerID = this.raffles?.raffle_winner?.id;
      this.deliveryVoucherUrl = this.raffles?.raffle_winner?.delivery_voucher_url;
      this.receivedPrize = this.raffles?.raffle_winner?.received_prize;
      this.productType = this.raffles?.product_type;
      this.enableProductType = this.productType == 'Digital' ? true : false;
      this.urlInstructions = this.raffles?.digital_prize?.url_instructions;
      this.getAddress();
    });
  }

  getAddress() {
    this.addressService.index().subscribe((record) => {
      this.address = record;
    });
  }

  getFormatDate(date) {
    let day = new Date(date).toLocaleDateString('es', { weekday: 'short' });
    day = day.replace(/^\w/, (c) => c.toUpperCase());
    const month = new Date(date).toLocaleDateString('es', { month: 'short' });
    const numDay = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return day + ', ' + numDay + ' ' + month + ', ' + year;
  }

  createForm() {
    this.form = this.fb.group({
      address_id: ['', Validators.required]
    });
  }

  get f() {
    return this.form.controls;
  }

  updateAddress() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      const data = this.form.value;
      this.loading = true;
      this.raffleWinnerService.updateAddress(data, this.winnerID).subscribe((res) => {
        this.f.address_id.setValue(res.address_id);
        this.loading = false;
        this.alertify.success("Se actualizó la dirección");
        this.router.navigate(['/my-tickets']);
      }, (error) => {
        this.loading = false;
        this.alertify.error(error || "Ocurrió un error");
      });
    }
  }

  showDeliveryCompany(contentDeliveryCompany) {
    this.modalService.open(contentDeliveryCompany, { ariaLabelledBy: 'showDeliveryCompany' })
      .result.then((result) => { }, (reason) => { });
  }

  showTrackId(contentTrackId) {
    this.modalService.open(contentTrackId, { ariaLabelledBy: 'showTrackId' })
      .result.then((result) => { }, (reason) => { });
  }

  showTrackUrl(contentTrackUrl) {
    this.modalService.open(contentTrackUrl, { ariaLabelledBy: 'showTrackUrl' })
      .result.then((result) => { }, (reason) => { });
  }

  showDeliveryVoucherUrl(contentDeliveryVoucherUrl) {
    this.modalService.open(contentDeliveryVoucherUrl, { ariaLabelledBy: 'showDeliveryVoucherUrl' })
      .result.then((result) => { }, (reason) => { });
  }

  downloadVourcher() {
    return this.deliveryVoucherUrl;
  }

  updatePrizeStatus() {
    const formattedDate = (moment(new Date())).format('YYYY-MM-DD HH:mm:ss')
    const data = {
      'received_prize': 1,
      'reception_date': formattedDate
    };
    this.raffleWinnerService.updatePrizeStatus(data, this.winnerID).subscribe((res) => {
      this.receivedPrize = res.received_prize;
    }, (error) => {
      this.loading = false;
      this.alertify.error(error || "Ocurrió un error");
    });
  }

  showInstructions(contentInstructions) {
    this.modalService.open(contentInstructions, { ariaLabelledBy: 'showInstructions' })
      .result.then((result) => { }, (reason) => { });
  }

  showRedeemCode(contentRedeemCode) {
    this.modalService.open(contentRedeemCode, { ariaLabelledBy: 'showRedeemCode' })
      .result.then((result) => { }, (reason) => { });
  }

  showExternalLink(contentExternalLink) {
    this.modalService.open(contentExternalLink, { ariaLabelledBy: 'showExternalLink' })
      .result.then((result) => { }, (reason) => { });
  }

  showUrlInstructions(contentUrlInstructions) {
    this.modalService.open(contentUrlInstructions, { ariaLabelledBy: 'showUrlInstructions' })
      .result.then((result) => { }, (reason) => { });
  }

  downloadUrlInstructions() {
    return this.urlInstructions;
  }
}
