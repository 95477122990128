import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { CategoryService } from '../category.service';
import { Validators } from '@angular/forms';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-category-form',
	templateUrl: './category-form.component.html',
	styleUrls: ['./category-form.component.css']
})
export class CategoryFormComponent extends FormComponent implements OnInit {
	faClipboard = faClipboard;

	constructor(
		public categoryService: CategoryService
	) {
		super(categoryService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.redirect = '/admin/category';
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			description: ['', Validators.required],
			active: ['1']
		});
	}

	dataToForm(data) {
		return {
			name: data.name,
			description: data.description,
			active: '' + data.active
		};
	}

	formToData() {
		this.data = {
			name: this.f.name.value,
			description: this.f.description.value,
			active: this.f.active.value
		};
	}
}
