import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from '../../../shared/list-service.service';
import { ListComponent } from '../../../shared/list/list.component';
import { ActionsCellRenderer } from '../../../shared/list/renderer/actions-cell.renderer';
import { CategoryService } from '../category.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_EN } from '../../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../../shared/list/locale/es.locale';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-category-list',
	templateUrl: './category-list.component.html',
	styleUrls: ['./category-list.component.css'],
	providers: [ListService, DecimalPipe]
})
export class CategoryListComponent extends ListComponent implements OnInit {
	//private gridApi;
	//public gridOptions: any;
	//public gridLocale;
	faClipboard = faClipboard;

	constructor(
		public categoryService: CategoryService,
		public listService: ListService,
		public translateService: TranslateService
	) {
		super(categoryService, listService);
		this.setObject('admin/category');
	}

	ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'category.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'category.description', field: 'description', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'category.active',
					field: 'active',
					sortable: true,
					cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.active.yes');
						} else {
							return this.translate.instant('form.active.no');
						}
					},
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'list.actions',
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'faEye',
								action: (id) => {
									this.show(id);
								}
							},
							{
								icon: 'faPencilAlt',
								action: (id) => {
									this.edit(id);
								}
							}
						]
					},
					headerValueGetter: this.localizeHeader.bind(this)
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}
}
