import { AuthService } from './../auth-module/auth.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { SizeDetectorService } from '../shared/size-detector.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  public screenSizeClass: string = '';

	constructor(
		public sizeDetectorService: SizeDetectorService,
		public auth: AuthService
		) {}

	ngOnInit() {
	}

	@HostListener("window:resize", [])
	private onResize() {
		this.screenSizeClass = this.sizeDetectorService.getScreenSizeClass();
	}

	ngAfterViewInit() {
		this.screenSizeClass = this.sizeDetectorService.getScreenSizeClass();
	}

}
