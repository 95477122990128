<div class="line">
	<div class="line-short green"></div>
	<div class="line-large orange"></div>
</div>
<div id="header" class="container section">
	<div class="row menu">
		<div class="col-4">
			<button type="button" class="btn btn-outline-success text-white font-weight-bold rounded-pill btn-padding" (click)="loginpopup.loginOpen()">
                Iniciar Sesión
            </button>
		</div>
		<div class="col-4 logo">
			<img src="assets/images/home/header/webp/logo.webp" />
		</div>
		<div class="col-4 social">
      <a href="https://facebook.com/rafikimx" target="_blank" (click)="visitFacebook()"><img
        src="assets/images/home/footer/svg/facebook.svg" class="social-icon" /></a>
      <a href="https://instagram.com/rafikimexico" target="_blank" (click)="visitInstagram()"><img
        src="assets/images/home/footer/svg/instagram.svg" class="social-icon" /></a>
      <a href="https://twitter.com/rafikimx" target="_blank" (click)="visitTwitter()"><img
        src="assets/images/home/footer/svg/twitter.svg" class="social-icon" /></a>
      <a href="https://wa.me/4421568386" target="_blank" (click)="visitWhatsApp()"><img
        src="assets/images/home/footer/svg/whats.svg" class="social-icon" /></a>
			<a href="/raffle" class="link">Rifas</a>
		</div>
	</div>
	<div class="row content">
		<div class="col-md-6 col-sm-12 left">
			<h1>TU CENTRAL DE RIFAS</h1>
			<p>
				La plataforma para albergar rifas para una amplia variedad de art&iacute;culos.
			</p>
			<div class="points-yellow">
				. . . . . . . . . . . . . . . . . . . .
			</div>
			<div class="download-app">
				<img src="assets/images/home/header/svg/icon-ticket.svg" class="img-fluid" />
				<div class="label">Descarga la app</div>
				<a target="_blank" href="https://apps.apple.com/mx/app/rafiki-tu-central-de-rifas/id1569088336" (click)="visitAppStore()">
					<img src="assets/images/home/header/svg/app-store.svg" class="img-fluid" />
				</a>
				<a target="_blank" href="https://play.google.com/store/apps/details?id=com.strappcorp.rafiki" (click)="visitGooglePlay()">
					<img src="assets/images/home/header/svg/google-play.svg" class="img-fluid" />
				</a>
			</div>
		</div>
		<div class="col-md-6 col-sm-12 right">
			<img src="assets/images/home/header/webp/collage-main-banner.webp" class="img-fluid" />
		</div>
	</div>
</div>
<div id="play" class="container section">
	<div class="row content">
		<div class="col-md-4 col-sm-12 left">
			<h1>ORGANIZA TU RIFA Y EMPIEZA A GANAR</h1>
			<div class="points-orange">
				. . . . . . . . . . . . . . . . .
			</div>
		</div>
		<div class="col-md-4 col-sm-12 center">
			<img src="assets/images/home/play/webp/collage-telefonos.webp" class="img-fluid" />
		</div>
		<div class="col-md-4 col-sm-12 right">
			<h1>JUEGA Y GANA ART&Iacute;CULOS DESDE TU CELULAR</h1>
			<div class="points-orange">
				. . . . . . . . . . . . . . . . .
			</div>
		</div>
	</div>
	<div class="row sub-content">
		<div class="col-6">
			<img src="assets/images/home/play/webp/organizador.webp" class="img-fluid" />
			<h1>Organizador</h1>
			<p>Organiza y promueve tus rifas usando las herramientas disponibles en la app.</p>
		</div>
		<div class="col-6">
			<img src="assets/images/home/play/webp/participante.webp" class="img-fluid" />
			<h1>Participante</h1>
			<p>Como participante encuentra lo que te guste, compra boletos y monitorea el evento.</p>
		</div>
	</div>
</div>
<div class="line-up">
</div>
<div id="how-it-works" class="container section">
	<div class="row top">
		<div class="col-md-4 col-sm-12 left">
			<h1>¿C&Oacute;MO FUNCIONA?</h1>
			<div class="points-white">
				. . . . . . . . . . . . . . . . . .
			</div>
		</div>
		<div class="col-md-6 col-sm-12 center">
			<img src="assets/images/home/how-it-works/webp/telefono.webp" class="img-fluid" />
			<p>App/Sitio web con suite de herramientas que facilitan la creaci&oacute;n de un evento personal al
				organizador.</p>
		</div>
		<div class="col-md-2 col-sm-12 right">

		</div>
	</div>
	<div class="row  down">
		<div class="col-md-3 col-sm-12">
			<img src="assets/images/home/how-it-works/webp/paso-2.webp" class="img-fluid" />
			<p>Organizador administra y opera su evento, permitiendo la compra de boletos emitidos.</p>
		</div>
		<div class="col-md-6 col-sm-12 center">
			<div class="row">
				<div class="col-6">
					<img src="assets/images/home/how-it-works/webp/paso-3.webp" class="img-fluid" />
					<p>Participante compra boleto(s).</p>
				</div>
				<div class="col-6">
					<img src="assets/images/home/how-it-works/webp/paso-4.webp" class="img-fluid" />
					<p>Se lleva a cabo digitalmente y con autonom&iacute;a del evento de rifa.</p>
				</div>
			</div>
		</div>
		<div class="col-md-3 col-sm-12">
			<img src="assets/images/home/how-it-works/webp/paso-5.webp" class="img-fluid" />
			<p>Organizador env&iacute;a el producto al participante ganador, se valida la entrega y recibe su dinero.
			</p>
		</div>
	</div>
</div>
<div class="line-down">
</div>
<div id="raffle" class="container section">
	<div class="row">
		<div class="col-md-6 col-sm-12 left">
			<img src="assets/images/home/raffle/webp/collage-rifaygana.webp" class="img-fluid" />
		</div>
		<div class="col-md-6 col-sm-12 right">
			<h1>¡RIFA Y GANA!</h1>
			<div class="points-white">
				. . . . . . . . . . . . . . . . . . . . . . .
			</div>
			<p>
				Podr&aacute;s ganar art&iacute;culos como:
			</p>
			<ul class="first">
				<li>> Dispositivos m&oacute;viles</li>
				<li>> Relojes inteligentes</li>
				<li>> Aparatos dom&eacute;sticos</li>
			</ul>
			<ul class="second">
				<li>> Autom&oacute;viles</li>
				<li>> Ropa, bolsas y accesorios</li>
				<li>> Y m&aacute;s</li>
			</ul>
			<button>¡Rifa ahora!</button>
		</div>
	</div>
</div>

<app-loginpopup #loginpopup></app-loginpopup>
