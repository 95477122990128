import { Injectable } from '@angular/core';
import { CrudService } from '../../shared/crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('application');
  }

  getStatus(): Observable<any> {
    return this.http.get(this.apiUrl + '/application/status');
  }

  store(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/application', data);
  }

  updateRFC(data: any, idApplication): Observable<any> {
    const id: any = data.id;
    return this.http.post(this.apiUrl + `/application/${idApplication}/rfc`, data);
  }
}
