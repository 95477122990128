<div class="d-flex justify-content-center">
    <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="submitAddress()">
            <div class="row align-items-center">
                <div class="col-auto my-4">
                    <button class="btn btn-back" [routerLink]="'/my-address'">
                        <fa-icon [icon]="faAngleLeft" class="h4"></fa-icon> 
                    </button>
                </div>
                <div class="col-auto">
                    <div class="text-orange font-weight-bold h2">
                        {{ titleAddress }}
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-6">
                    <div class="form-group">
                        <label for="name">Dirección:</label>
                        <input type="text" name="name" id="name" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name?.errors }">
                        <div *ngIf="submitted && f.name?.errors" class="invalid-feedback">
                            <div *ngIf="f.name?.errors.required">{{ "general.errors.required" | translate }} </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="contact_name">Nombre de contacto:</label>
                        <input type="text" name="contact_name" id="contact_name" class="form-control" formControlName="contact_name" [ngClass]="{ 'is-invalid': submitted && f.contact_name?.errors }">
                        <div *ngIf="submitted && f.contact_name?.errors" class="invalid-feedback">
                            <div *ngIf="f.contact_name?.errors.required">{{ "general.errors.required" | translate }} </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <label for="code_area">Lada:</label>
                        <input type="text" name="code_area" id="code_area" class="form-control" formControlName="code_area" [ngClass]="{ 'is-invalid': submitted && f.code_area?.errors }" maxlength="4">
                        <div *ngIf="submitted && f.code_area?.errors" class="invalid-feedback">
                            <div *ngIf="f.code_area?.errors.required">{{ "general.errors.required" | translate }} </div>
                        </div>
                    </div>
                </div>              
                <div class="col-6">
                    <div class="form-group">
                        <label for="phone">Teléfono:</label>
                        <input type="text" name="phone" id="phone" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone?.errors }" maxlength="20">
                        <div *ngIf="submitted && f.phone?.errors" class="invalid-feedback">
                            <div *ngIf="f.phone?.errors.required">{{ "general.errors.required" | translate }} </div>
                        </div>
                    </div>
                </div>              
                <div class="col-6">
                    <div class="form-group">
                        <label for="country">País:</label>
                        <input type="text" readonly name="country" id="country" class="form-control" formControlName="country" [ngClass]="{ 'is-invalid': submitted && f.country?.errors }">
                        <div *ngIf="submitted && f.country?.errors" class="invalid-feedback">
                            <div *ngIf="f.country?.errors.required">{{ "general.errors.required" | translate }} </div>
                        </div>
                    </div>
                </div>    
                <div class="col-6 locations">
                    <div class="form-group">
                        <label for="state">Estado:</label>
                        <select class="form-control" name="state" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state?.errors }">
                            <option value="">Seleccione un estado</option>
                            <option *ngFor="let state of states" [ngValue]="state.name">{{state.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.state?.errors" class="invalid-feedback">
                            <div *ngIf="f.state.errors.required">{{ "general.errors.required" | translate }}</div>
                        </div>
                    </div> 
                </div>     
                <div class="col-6">
                    <div class="form-group">
                        <label for="city">Ciudad:</label>
                        <input type="text" name="city" id="city" class="form-control" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city?.errors }">
                        <div *ngIf="submitted && f.city?.errors" class="invalid-feedback">
                            <div *ngIf="f.city?.errors.required">{{ "general.errors.required" | translate }} </div>
                        </div>
                    </div>
                </div>       
                <div class="col-6">
                    <div class="form-group">
                        <label for="cp">Código postal:</label>
                        <input type="text" name="cp" id="cp" class="form-control" formControlName="cp" [ngClass]="{ 'is-invalid': submitted && f.cp?.errors }" maxlength="5">
                        <div *ngIf="submitted && f.cp?.errors" class="invalid-feedback">
                            <div *ngIf="f.cp?.errors.required">{{ "general.errors.required" | translate }} </div>
                        </div>
                    </div>
                </div>       
            </div>
            <div class="row my-4">
                <div class="col-12 mb-5">
                    <button type="submit" class="btn rounded-pill font-weight-bold create" *ngIf="create()">{{ "form.save" | translate }}</button>
                    <button type="button" class="btn rounded-pill font-weight-bold create-outline mr-3" *ngIf="edit()" (click)="delete(modalConfirm)">Eliminar</button>
                    <button type="submit" class="btn rounded-pill font-weight-bold create" *ngIf="edit()">{{ "form.update" | translate }}</button>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #modalConfirm let-modal>
    <div class="modal-header">
        <span class="font-weight-bold text-center w-100">Eliminar dirección</span>
    </div>
    <div class="modal-body rounded">
        <div class="text-sm text-center">
          <p class="m-0">¿Estas seguro de eliminar la dirección?</p>
        </div>
        <div class="row mt-4 justify-content-center">
          <div class="col-12 mb-3">
            <button class="btn btn-success btn-block rounded font-weight-bold" (click)="deleteAddress(modal)">Aceptar</button>
          </div>  
          <div class="col-12">
              <button class="btn btn-outline-sec btn-block rounded font-weight-bold" (click)="modal.dismiss('')">Cancelar</button>
          </div>
        </div>
    </div>
  </ng-template>