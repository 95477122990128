import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SizeDetectorService {

	constructor() {}

	public getScreenSizeClass(): string {
		const screenSize = window.innerWidth;
		let screenSizeClass  = '';

		if (screenSize <= 575) {
			screenSizeClass = 'screen-xs';
		} else if (screenSize >= 576 && screenSize <= 767) {
			screenSizeClass = 'screen-sm';
		} else if (screenSize >= 768 && screenSize <= 991) {
			screenSizeClass = 'screen-md';
		} else {
			screenSizeClass = 'screen-lg';
		}

		return screenSizeClass;
	}
}