import { Component, OnInit } from '@angular/core';
import { faUserCircle,
          faUsers,
          faClipboardCheck,
          faHome,
          faSitemap,
          faClipboard,
          faClipboardList,
          faIdCard,
          faTicketAlt,
          faTimesCircle,
          faUserTimes,
          faSync,
          faBaseballBall,
          faPuzzlePiece,
          faUserPlus,
          faSchool,
          faGraduationCap,
          faDollarSign,
          faWallet,
          faExclamationCircle,
          faFileAlt
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  faExclamationCircle = faExclamationCircle;
  faUserCircle = faUserCircle;
  faUsers = faUsers;
  faClipboardCheck = faClipboardCheck;
  faClipboard = faClipboard;
  faClipboardList = faClipboardList;
  faHome = faHome;
  faSitemap = faSitemap;
  faIdCard = faIdCard;
  faTicketAlt = faTicketAlt;
  faTimesCircle = faTimesCircle;
  faUserTimes = faUserTimes;
  faSync = faSync;
  faBaseballBall = faBaseballBall;
  faPuzzlePiece = faPuzzlePiece;
  faUserPlus = faUserPlus;
  faSchool = faSchool;
  faGraduationCap = faGraduationCap;
  faDollarSign = faDollarSign;
  faWallet = faWallet; 
  faFileAlt = faFileAlt; 
  constructor() { }

  ngOnInit() {
  }

}
