<div class="d-flex justify-content-center pt-3">
	<div class="col-md-10">
		<ngx-loading [show]="loader"
		[config]="{primaryColour: '#1D4E2B', secondaryColour: '#1D4E2B', tertiaryColour: '#1D4E2B' }"></ngx-loading>
		<form [formGroup]="form" (ngSubmit)="submitToConekta()">
			<div class="d-flex justify-content-center">
				<div class="col-md-12 text-center text-success">
					<h5>{{ "card.create" | translate }}</h5>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-12">
					<label for="name" class="text-primary-color-1">{{ "card.form.name" | translate }}:</label>
					<input class="form-control" maxlength="200" [readonly]="view()"
						placeholder="{{ 'card.placeholder.name' | translate }}" formControlName="name" type="text"
						[ngClass]="{ 'is-invalid': submitted && f.name.errors }">
					<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
						<div *ngIf="f.name.errors.required">{{ "card.errors.required" | translate }}</div>
					</div>
				</div>
				<div class="form-group col-12">
					<label for="alias" class="text-primary-color-1">{{ "card.form.alias" | translate }}:</label>
					<input class="form-control" maxlength="200" placeholder="{{ 'card.placeholder.alias' | translate }}"
						[readonly]="view()" formControlName="alias" type="text"
						[ngClass]="{ 'is-invalid': submitted && f.alias.errors }">
					<div *ngIf="submitted && f.alias.errors" class="invalid-feedback">
						<div *ngIf="f.alias.errors.required">{{ "card.errors.required" | translate }}</div>
					</div>
				</div>
				<div class="form-group col-12">
					<label for="number" class="text-primary-color-1">{{ "card.form.number" | translate }}:</label>
					<input class="form-control" maxlength="20" placeholder="XXXX XXXX XXXX XXXX" [readonly]="view()"
						formControlName="number" type="text"
						[ngClass]="{ 'is-invalid': submitted && (f.number.errors || !validNumber) }">
					<div *ngIf="submitted && f.number.errors" class="invalid-feedback">
						<div *ngIf="f.number.errors.required">{{ "card.errors.required" | translate }}</div>
					</div>
					<div *ngIf="submitted" class="invalid-feedback">
						<div *ngIf="!validNumber">{{ "card.errors.invalid-card" | translate }}</div>
					</div>
				</div>
				<div class="form-inline col-12">
					<div class="form-inline col-12">
						<div class="form-group col-4">
							<label for="expiration_month" class="text-primary-color-1">{{ "card.form.expiration_month" |
								translate }}:</label>
							<select class="form-control card-little-form" name="expiration_month"
								formControlName="expiration_month"
								[ngClass]="{ 'is-invalid': submitted && (f.expiration_month.errors ||  !validExpiration) }">
								<option></option>
								<option *ngFor="let month of months" [ngValue]="month">{{month}}</option>
							</select>
							<div *ngIf="submitted && f.expiration_month.errors" class="invalid-feedback">
								<div *ngIf="f.expiration_month.errors.required">{{ "card.errors.required" | translate }}
								</div>
							</div>
						</div>
						<div class="form-group col-4">
							<label for="expiration_year" class="text-primary-color-1">{{ "card.form.expiration_year" |
								translate }}:</label>
							<select class="form-control card-little-form" name="expiration_year"
								formControlName="expiration_year"
								[ngClass]="{ 'is-invalid': submitted && ( f.expiration_year.errors ||  !validExpiration )}">
								<option></option>
								<option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
							</select>
							<div *ngIf="submitted && f.expiration_year.errors" class="invalid-feedback">
								<div *ngIf="f.expiration_year.errors.required">{{ "card.errors.required" | translate }}
								</div>
							</div>
						</div>
						<div class="form-group col-4">
							<label for="cvc" class="text-primary-color-1">{{ "card.form.cvc" | translate }}:</label>
							<input class="form-control card-little-form card-cvc" maxlength="4" placeholder="CVC"
								[readonly]="view()" formControlName="cvc" type="password"
								[ngClass]="{ 'is-invalid': submitted && (f.cvc.errors || !validCvc)  }">
							<div *ngIf="submitted && f.cvc.errors" class="invalid-feedback">
								<div *ngIf="f.cvc.errors.required">{{ "card.errors.required" | translate }}</div>
							</div>
							<div *ngIf="submitted" class="invalid-feedback">
								<div *ngIf="!validCvc">{{ "card.errors.invalid-cvc" | translate }}</div>
							</div>
						</div>
					</div>
					<div class="form-inline col-4">
						<div *ngIf="submitted" class="text-danger">
							<div *ngIf="!validExpiration">{{ "card.errors.invalid-date" | translate }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center buttons">
				<button *ngIf="!cancel" type="button" class="btn btn-outline-danger mr-3 font-weight-bold" routerLink="/home">{{ "form.cancel" |
					translate }}</button>
				<button *ngIf="cancel" type="button" class="btn btn-outline-danger mr-3 font-weight-bold" (click)="cancelActionExternal()">{{
					"form.cancel" | translate }}</button>
				<button type="submit" class="btn btn-sec font-weight-bold" *ngIf="create()">{{ "form.save" | translate }}</button>
				<button type="submit" class="btn btn-sec font-weight-bold" *ngIf="edit()">{{ "form.update" | translate }}</button>
			</div>
		</form>
	</div>
</div>
