import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CrudService } from '../shared/crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('register');
  }

  save(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/register', data);
  }

  verify(id, hash, params: string): Observable<any> {
    return this.http.get(this.apiUrl + `/email/verify/${id}/${hash}?${params}`);
  }

  resend(id, url = null): Observable<any> {
    if (id !== null) {
      return this.http.get(this.apiUrl + `/get/resend/${id}`);
    } else if (url) {
      return this.http.post(url, {}, {headers: {'Content-Type':  'application/json'}});
    }
  }

  checkEmail(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/register/check-email', data);
  }

  checkNickname(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/register/check-nickname', data);
  }
}
