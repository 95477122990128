import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { CustomerLoginComponent } from './customer-login/customer-login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from '../home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterComponent } from './register/register.component';
import { ResendComponent } from './resend/resend.component';
import { VerifyComponent } from './verify/verify.component';
import { ConfirmationEmailComponent } from './confirmation-email/confirmation-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProfileRafflesComponent } from './profile-raffles/profile-raffles.component';
import { ProfileRaffleDetailComponent } from './profile-raffle-detail/profile-raffle-detail.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LoginpopupComponent } from './loginpopup/loginpopup.component';


@NgModule({
  declarations: [
    LoginComponent,
    CustomerLoginComponent,
    ForgetPasswordComponent,
    HomeComponent, 
    ProfileComponent,
    RegisterComponent,
    ResendComponent,
    VerifyComponent,
    ConfirmationEmailComponent,
    ResetPasswordComponent,
    ProfileRafflesComponent,
    LoginpopupComponent,
    ProfileRaffleDetailComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),
    FontAwesomeModule,
    TranslateModule,
    NgCircleProgressModule.forRoot({}),
  ],
  exports:[
    LoginpopupComponent
  ]
})
export class AuthModule { }
