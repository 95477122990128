
<div class="container">
    <div class="row justify-content-center">
        <div class="col-11">
            <form [formGroup]="form" (ngSubmit)="checkUpload()">
                <div class="row justify-content-between py-5">
                    <div class="col-auto">
                        <div class="text-orange font-weight-bold h2">
                            Crear rifa
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="button" routerLink="/raffle" class="btn btn-outline-success rounded-pill font-weight-bold">Cancelar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col font-weight-bold h5">
                    Artículo a rifar
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row justify-content-center align-items-center border photo">
                            <div class="col">
                                <div class="row" *ngIf="images.length > 0">
                                    <div class="col">
                                        <ngb-carousel #carousel="ngbCarousel" (slide)="slideChange($event)">
                                            <ng-template ngbSlide *ngFor="let image of images; let i = index" [id]="i">
                                                <div class="picsum-img-wrapper">
                                                    <img class="img-fluid mx-auto d-block imgCarousel" [src]="image.url_image">
                                                </div>
                                            </ng-template>
                                        </ngb-carousel>
                                    </div>
                                </div>
                                <div class="row justify-content-center align-items-center">
                                    <div class="text-center pointer" (click)="impFile.click()"
                                        [ngClass]="{ 'col-12': images.length == 0, 'col-auto':images.length > 0 }">
                                        <input type="file" class="custom-file-input" #impFile (change)="fileChangeEvent($event,imageCrop)" hidden>
                                        <fa-icon [icon]="faCamera" class="h3"></fa-icon>
                                    </div>
                                    <div class="col-auto text-center pointer" (click)="impFile.click()">
                                        Agregar foto
                                    </div>
                                    <!-- <div class="col text-right" *ngIf="images.length > 0">
                                        <button type="button" class="btn btn-default" (click)="confirmDeleteImage(carousel)">
                                        <img src="assets/images/icon_borrar2x.png" class="icon-list">
                                        </button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="text-xsm my-1">
                                    <span class="font-weight-bold">Fotos {{images.length}}/10.</span>  Primero elige la foto principal de la publicación
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <ngx-loading [show]="loaderSearch"
                            [config]="{primaryColour: '#1D4E2B', secondaryColour: '#1D4E2B', tertiaryColour: '#1D4E2B' }"></ngx-loading>
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="name">Nombre del artículo</label>
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Nombre de la publicación"
                                    formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name?.errors" class="invalid-feedback">
                                        <div *ngIf="f.name?.errors.required">{{ "general.errors.required" | translate }}:</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="searchPrices(content)">Buscar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 formInputs">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="suggested_value">Valor promedio con base a Rafiki</label>
                            <div class="input-group">
                                <input type="text" readonly name="suggested_value" id="suggested_value" class="form-control rounded_right"
                                placeholder="Valor promedio" formControlName="suggested_value" thousandsSeparator="," angularFormsCurrency>
                                <div class="input-group-append">
                                    <button type="button" class="btn rounded_left bg-EFF3F6" (click)="information(content)"><fa-icon [icon]="faInfo" class=""></fa-icon> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="prize_value">Valor del premio</label>
                            <div class="input-group">
                                <input type="text" name="prize_value" id="prize_value" class="form-control rounded_right" placeholder="$"
                                formControlName="prize_value" [ngClass]="{ 'is-invalid': submitted && f.prize_value.errors }"
                                (change)="[checkLimitAmounts(),checkPercentagePrizeValue()]" thousandsSeparator="," angularFormsCurrency>
                                <div class="input-group-append">
                                    <button type="button" class="btn rounded_left bg-EFF3F6"><fa-icon [icon]="faInfo" class=""></fa-icon> </button>
                                </div>
                                <div *ngIf="submitted && f.prize_value?.errors" class="invalid-feedback">
                                    <div *ngIf="f.prize_value?.errors.required">{{ "general.errors.required" | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="total_amount">Monto generado por rifa</label>
                            <div class="input-group">
                                <input type="text" name="total_amount" id="total_amount" class="form-control rounded_right" placeholder="$"
                                formControlName="total_amount" [ngClass]="{ 'is-invalid': submitted && (this.formatCurrency(f.total_amount?.value) < minTotalAmount()) }"
                                (change)="[checkPercentagePrizeValue(),suggestTicketPrice()]" thousandsSeparator="," angularFormsCurrency>
                                <div class="input-group-append">
                                    <button type="button" class="btn rounded_left bg-EFF3F6"><fa-icon [icon]="faInfo" class=""></fa-icon> </button>
                                </div>
                                <div *ngIf="submitted && (this.formatCurrency(f.total_amount?.value) < minTotalAmount())" class="invalid-feedback">
                                    <div>{{  ("raffle.errors.majorValue" | translate) + ( minTotalAmount() | currency) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="num_tickets">Boletos</label>
                            <input type="text" name="num_tickets" id="num_tickets" class="form-control"
                            formControlName="num_tickets" (change)="checkTicketPrice('number')"
                            [ngClass]="{ 'is-invalid': submitted && f.num_tickets.errors }">
                            <div *ngIf="submitted && f.num_tickets?.errors" class="invalid-feedback">
                                <div *ngIf="f.num_tickets?.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="ticket_price">Precio por boleto</label>
                            <input type="text" name="ticket_price" id="ticket_price" class="form-control" placeholder="$"
                            formControlName="ticket_price" (change)="checkTicketPrice('price')"
                            [ngClass]="{ 'is-invalid': submitted && f.ticket_price.errors }" thousandsSeparator="," angularFormsCurrency>
                            <div *ngIf="submitted && f.ticket_price?.errors" class="invalid-feedback">
                                <div *ngIf="f.ticket_price?.errors.min">{{ "raffle.errors.minValue" | translate }} $50</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="brand">{{ "raffle.brand" | translate }}:</label>
                            <input class="form-control" [readonly]="view()" formControlName="brand" type="text" [ngClass]="{ 'is-invalid': submitted && f.brand?.errors }">
                            <div *ngIf="submitted && f.brand?.errors" class="invalid-feedback">
                                <div *ngIf="f.brand?.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="model">{{ "raffle.model" | translate }}:</label>
                            <input class="form-control" [readonly]="view()" formControlName="model" type="text" [ngClass]="{ 'is-invalid': submitted && f.model?.errors }">
                            <div *ngIf="submitted && f.model?.errors" class="invalid-feedback">
                                <div *ngIf="f.model?.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="description">Descripción:</label>
                            <textarea class="form-control" formControlName="description" placeholder="Escriba una descripción"
                                rows="3"></textarea>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="category_id">Categoría:</label>
                            <select class="form-control" name="category_id" #cat (change)="changeCategory(cat.value)"
                                formControlName="category_id" [ngClass]="{ 'is-invalid': submitted && f.category_id.errors }">
                                <option value="">Seleccione una categoria</option>
                                <option *ngFor="let category of categories" [value]="category.id">{{category.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.category_id.errors" class="invalid-feedback">
                                <div *ngIf="f.category_id.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="subcategory_id">Sub-categoría:</label>
                            <select class="form-control" name="subcategory_id"
                                formControlName="subcategory_id" [ngClass]="{ 'is-invalid': submitted && f.subcategory_id.errors }">
                                <option value="">Seleccione una sub-categoría</option>
                                <option *ngFor="let subcategory of subcategories" [ngValue]="subcategory.id">{{subcategory.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.subcategory_id.errors" class="invalid-feedback">
                                <div *ngIf="f.subcategory_id.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="product_type">Tipo de premio:</label>
                            <select class="form-control" name="product_type"
                                formControlName="product_type" [ngClass]="{ 'is-invalid': submitted && f.product_type.errors }">
                                <option value="">Seleccione tipo de premio</option>
                                <option value="Physical">{{ "raffle.product_types.physical" | translate }}</option>
                                <option value="Digital">{{ "raffle.product_types.digital" | translate }}</option>
                                <option value="Service">{{ "raffle.product_types.service" | translate }}</option>
                            </select>
                            <div *ngIf="submitted && f.product_type.errors" class="invalid-feedback">
                                <div *ngIf="f.product_type.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="prize_type">Estado del premio:</label>
                            <select class="form-control" name="prize_type"
                                formControlName="prize_type" [ngClass]="{ 'is-invalid': submitted && f.prize_type.errors }">
                                <option value="">Seleccione estado del premio</option>
                                <option value="New">{{ "raffle.prize_types.new" | translate }}</option>
                                <option value="Used">{{ "raffle.prize_types.used" | translate }}</option>
                            </select>
                            <div *ngIf="submitted && f.prize_type.errors" class="invalid-feedback">
                                <div *ngIf="f.prize_type.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-5">
                <div class="row">
                    <div class="col">
                        <p class="h5 font-weight-bold">
                            <fa-icon [icon]="faDollar" class="text-success"></fa-icon>
                            Resumen
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row border summary">
                            <div class="col-12 my-2">
                                <div class="row">
                                    <div class="col-8">
                                        Monto máximo
                                    </div>
                                    <div class="col-4 text-success font-weight-bold">
                                        <p> {{detail.total | currency}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 my-2">
                                <div class="row">
                                    <div class="col-8">
                                        Comisión Rafiki 20%
                                    </div>
                                    <div class="col-4 text-danger font-weight-bold">
                                        <p> {{detail.comissionRafiki | currency}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 my-2">
                                <div class="row">
                                    <div class="col-8">
                                        Comisión bancaria 3%
                                    </div>
                                    <div class="col-4 text-danger font-weight-bold">
                                        <p>{{detail.comissionTotal | currency}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 font-weight-bold my-2">
                                <div class="row">
                                    <div class="col-8">
                                        Total
                                    </div>
                                    <div class="col-4 text-success">
                                       <p>{{detail.totalLessComissions | currency}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <hr>
                                <div class="alert" role="alert">
                                    Nota: considera adicionalmente los gastos de envío que tienes presupuestado, el IVA de la comisión Rafiki y la retención del impuesto sobre la renta correspondiente.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="start">Fecha inicio:</label>
                                    <div class="input-group">
                                        <input class="form-control bg-white rounded_right" readonly formControlName="start" ngbDatepicker #s="ngbDatepicker"
                                        type="text" [ngClass]="{ 'is-invalid': submitted && f.start?.errors }" (click)="s.toggle()">
                                        <div class="input-group-append">
                                            <button type="button" class="btn iconDate rounded_left" type="button" (click)="s.toggle()"><fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon></button>
                                        </div>
                                        <div *ngIf="submitted && f.start?.errors" class="invalid-feedback">
                                            <div *ngIf="f.start?.errors.required">{{ "general.errors.required" | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="finish">Fecha fin:</label>
                                    <div class="input-group">
                                        <input class="form-control bg-white rounded_right" readonly formControlName="finish" ngbDatepicker #ed="ngbDatepicker"
                                        type="text" [ngClass]="{ 'is-invalid': submitted && f.finish?.errors }" (click)="ed.toggle()">
                                        <div class="input-group-append">
                                            <button type="button" class="btn iconDate rounded_left" type="button" (click)="ed.toggle()"><fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon></button>
                                        </div>
                                        <div *ngIf="submitted && f.finish?.errors" class="invalid-feedback">
                                            <div *ngIf="f.finish?.errors.required">{{ "general.errors.required" | translate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-5">
                <div class="row">
                    <div class="col">
                        <p class="h5 font-weight-bold">
                            <fa-icon [icon]="faLocation" class="text-success"></fa-icon>
                            Ubicación del premio
                        </p>
                    </div>
                </div>
                <div class="row locations mt-2">
                    <div class="col-4">
                        <div class="form-group">
                            <label for="country">{{ "raffle.country" | translate }}:</label>
                            <input class="form-control" [readonly]="true" type="text" value="México">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="cat_state_id">Estado:</label>
                            <select class="form-control" name="cat_state_id"
                                formControlName="cat_state_id" [ngClass]="{ 'is-invalid': submitted && f.cat_state_id.errors }">
                                <option value="">Seleccione un estado</option>
                                <option *ngFor="let state of states" [ngValue]="state.id">{{state.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.cat_state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.cat_state_id.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group">
                            <label for="city">Ciudad:</label>
                            <input class="form-control" name="city"
                                formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">{{ "general.errors.required" | translate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-12 text-center mb-5">
                        <button type="submit" class="btn rounded-pill font-weight-bold create">Crear rifa</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>



<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ "raffle.products" | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-inline">
        <div *ngFor="let product of products" class="col-2 col-md-2">
          <img [src]="product.thumbnail" width="100px" >
          <br>
          <span> {{ product.title }}</span>
          <br>
          <span> <strong>${{ product.price | number:'1.2-2'}}</strong> </span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate }}</button>
    </div>
</ng-template>



<ng-template #imageCrop let-modal>
    <ngx-loading [show]="loading"
    [config]="{primaryColour: '#1D4E2B', secondaryColour: '#1D4E2B', tertiaryColour: '#1D4E2B' }"></ngx-loading>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Recortar imagen</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <label *ngIf="showCropper">{{ "crop.pick_face" | translate }}</label>
                <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [maintainAspectRatio]="false"
                    [containWithinAspectRatio]="false"
                    [onlyScaleDown]="true"
                    format="png"
                    [cropperMinWidth]="128"
                    [style.display]="showCropper ? null : 'none'"
                    (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()"
                    (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"
                ></image-cropper>
            </div>
            <div class="col-md-6 cropper-image">
                <label *ngIf="showCropper" for="">{{ "crop.preview" | translate }}</label>
                <br>
                <img *ngIf="showCropper" [src]="croppedImage" width="400px" />
                <br>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate }}</button>
        <button type="button" class="btn btn-success mr-3" *ngIf="showCropper" (click)="endCrop(modal)">{{ "crop.accept" | translate }}</button>
    </div>
</ng-template>
