<div class="d-flex justify-content-center b-EFF3F6">
    <div class="container mb-5">
        <div class="row b-EFF3F6 align-items-center">
            <div class="col-auto my-4">
                <button class="btn btn-back" [routerLink]=" isFinish() ? '/results' : '/raffle'">
                    <fa-icon [icon]="faAngleLeft" class="h4"></fa-icon>
                </button>
            </div>
            <div class="col-auto">
                <div class="text-orange font-weight-bold m-0 text-lg">
                    Volver al listado
                </div>
            </div>
        </div>
        <div class="row rowbody p-4">
            <div class="col">
                <div class="row mt-2">
                    <div class="col-6">
                        <div class="col-auto text-center" *ngIf="raffle?.images.length > 0" >
                            <ngb-carousel #c="ngbCarousel" class="carousel">
                                <ng-template ngbSlide *ngFor="let image of raffle.images; let i = index" [id]="i">
                                    <div class="picsum-img-wrapper">
                                        <img class="img-fluid mx-auto d-block" [src]="image.url_image">
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                    <div class="col-6 ">
                        <div class="row mb-2 justify-content-between">
                            <div class="col-auto">
                                <div class="text-capitalize font-weight-bold text-lg">{{ raffle?.name }}</div>
                            </div>
                            <div class="col-auto">
                                <button class="btn" (click)="openShare(modalShare)">
                                    <fa-icon [icon]="faShare" class="text-orange h4 share"></fa-icon>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="text-xsm">Precio por boleto</div>
                                <p class="font-weight-bold">${{raffle?.ticket_price}}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p>{{raffle?.description}}</p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col ">
                                <p class="font-weight-bold">
                                    <fa-icon [icon]="faTicketAlt" class="text-success"></fa-icon>
                                    Detalles
                                </p>
                            </div>
                        </div>
                        <div class="row text-sm">
                            <div class="col-6 text-left font-weight-bold">
                                Estado
                            </div>
                            <div class="col-6 text-right">
                                {{raffle?.prize_type ==='New' ? 'Nuevo' : 'Usado'}}
                            </div>
                            <div class="col-6 text-left font-weight-bold">
                                Valor promedio
                            </div>
                            <div class="col-6 text-right">
                                {{raffle?.prize_value | currency}}
                            </div>
                        </div>
                        <div class="row my-4">
                            <div class="col-12">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style]="'width:'+(raffle?.sold_tickets/raffle?.num_tickets*100)+'%'"
                                     [attr.aria-valuenow]="raffle?.sold_tickets" aria-valuemin="0" [attr.aria-valuemax]="raffle?.num_tickets"></div>
                                </div>
                            </div>
                            <div class="col">
                                <p class="text-xxsm">Nota:Se requiere un minimo de {{raffle?.min_percentage}}% de boletos vendidos para la reaización de esta rifa.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12 font-weight-bold">
                                <p>
                                    <fa-icon [icon]="faTicketAlt" class="text-success"></fa-icon>
                                    Información del rifador
                                </p>
                            </div>
                            <div class="col-12">
                                <div class="row align-items-center mb-4">
                                    <div class="col-auto" *ngIf="raffle?.raffler.url_photo">
                                        <img  class="img-fluid img-raffler" [src]="raffle?.raffler.url_photo">
                                    </div>
                                    <div class="col-auto">
                                        <p class="m-0">{{raffle?.raffler.nickname}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 text-sm mb-2">
                                <div>Fecha de inicio</div>
                                <div class="row b-EFF3F6 dates rounded align-items-center justify-content-between mx-1">
                                    <div class="col-auto">{{getFormatDate(raffle?.start)}}</div>
                                    <div class="col-auto">
                                        <fa-icon [icon]="faCalendarAlt" class="text-orange text-right"></fa-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 text-sm mb-2">
                                <div>Fecha fin (día de rifa)</div>
                                <div class="row b-EFF3F6 dates rounded align-items-center justify-content-between mx-1">
                                    <div class="col-auto">{{getFormatDate(raffle?.finish)}}</div>
                                    <div class="col-auto">
                                        <fa-icon [icon]="faCalendarAlt" class="text-orange text-right"></fa-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-sm mt-4">
                                <div>Ubicación del premio</div>
                                <div>
                                    <fa-icon [icon]="faLocation" class="text-success mr-2"></fa-icon>
                                    {{raffle?.city+', '+ raffle?.state.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row justify-content-center p-4 rounded b-EFF3F6 m-0">
                            <div class="col-12 font-weight-bold">
                                <p>
                                    <fa-icon [icon]="faTicketAlt" class="text-success"></fa-icon>
                                    Top de boletos comprados
                                </p>
                            </div>
                            <div class="col-12 my-2" *ngFor="let top of tops;let i=index;">
                                <div class="text-sm">
                                    <div class="inline mr-2"><fa-icon [icon]="faTicketAlt" class="text-orange"></fa-icon></div>
                                    <div class="font-weight-bold inline mr-2">Top {{i+1}}</div>  ({{top.total}} boletos)
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-4" *ngIf="hasTickets()">
                          <div class="col-auto">
                            <button class="btn btn-success rounded-pill">
                              <a href="https://wa.me/4421568386?text=Quiero%20participar%20en%20la%20rifa%3A%0A%2A{{raffle?.name}}%2A"
                                 style="all:unset;" target="_blank" (click)="compraWhatsApp()">
                                <img src="assets/images/home/footer/svg/whats.svg" class="social-icon"/>
                                &nbsp;Comprar via WhatsApp
                              </a>
                            </button>
                          </div>
                        </div>
                        <div class="row justify-content-center mt-4" *ngIf="hasTickets()">
                            <div class="col-auto">
                                <button class="btn btn-sec rounded-pill" (click)="modalTickets(modalTic, loginpopup)">Ver números disponibles</button>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-4" *ngIf="!hasTickets()">
                            <div class="col-auto text-success font-weight-bold">
                                <span> No hay boletos por comprar</span>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-4" *ngIf="purchasedTickets.length > 0">
                            <div class="col font-weight-bold">
                                <hr>
                                <p>
                                    <fa-icon [icon]="faTicketAlt" class="text-success"></fa-icon>
                                    Boletos comprados
                                </p>
                                <div class="row justify-content-center">
                                    <div class="col-auto" *ngFor="let ticket of purchasedTickets">
                                        <div class="ticket mb-1">
                                            <fa-icon [icon]="faTicket" class="icon-ticket centered text-success"></fa-icon>
                                            <span class="centered font-weight-bold">{{ticket.number}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-4">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col">
                                <p class="font-weight-bold">
                                    <fa-icon [icon]="faTicketAlt" class="text-success"></fa-icon>
                                    Reportar Rifa
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p>Nota: Puedes reportar esta rifa si tiene contenido inapropiado y/o información sensible para algunos usuarios.</p>
                            </div>
                            <div class="col">
                                <div class="row justify-content-center">
                                    <div class="col-auto">
                                        <button class="btn btn-outline-sec w250 rounded-pill" (click)="openReportR(modalReportR)">Repotar rifa</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loginpopup #loginpopup></app-loginpopup>

<ng-template #modalTic let-modal>
    <div class="modal-body p-0 b-EFF3F6 rounded">
      <app-raffle-ticket [raffle]="raffle" [modal]="modal" [modalShare]="modalShare" [modalTicket]="modalTicket" (getRaffle)="getRaffle()"></app-raffle-ticket>
    </div>
</ng-template>

<ng-template #modalShare let-modal>
    <div class="modal-body p-0 b-EFF3F6 rounded">
        <div class="row m-2">
            <div class="col">
                <p class="h4 text-orange font-weight-bold">Compartir</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-auto ml-4">
                <share-buttons [theme]="'default'"
                [include]="['copy','whatsapp','facebook','twitter','linkedin','pinterest','reddit','telegram','messenger','line','sms','email']"
                [show]="7"
                [url]="url"
               ></share-buttons>
            </div>
         </div>
    </div>
</ng-template>

<ng-template #modalReportR let-modal>
    <div class="modal-header">
        <span class="font-weight-bold">Motivo del reporte</span>
    </div>
    <div class="modal-body rounded">
        <div class="form-group">
            <label for="comment">Comentarios:</label>
            <textarea class="form-control" [(ngModel)]="reportR" rows="3"></textarea>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto">
                <button class="btn btn-outline-sec rounded" (click)="modal.dismiss('')">Cerrar</button>
            </div>
            <div class="col-auto">
                <button [disabled]="reportR.trim() === ''" class="btn btn-success rounded" (click)="raffleReport(modal)">Reportar rifa</button>
            </div>
        </div>
    </div>
</ng-template>
