import { Component, OnInit } from '@angular/core';
import { faBell, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { NotificationService } from 'src/app/shared/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  notifications: any = [];
  messageError: string;
  idUser: any;
  totalNotifications: any;

  public faBell = faBell;
  public faTrashAlt = faTrashAlt;

  constructor(
    public notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.index();
  }

  index() {
    this.notificationService.index().subscribe((record) => {
      this.notifications = record.data;
      this.totalNotifications = this.notifications.length;
    });
  }

  getFormatDate(date) {
    let day = new Date(date).toLocaleDateString('es', { weekday: 'short' });
    day = day.replace(/^\w/, (c) => c.toUpperCase());
    const month = new Date(date).toLocaleDateString('es', { month: 'short' });
    const numDay = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return day + ', ' + numDay + ' ' + month + ', ' + year;
  }

  readNotification(id) {
    this.notificationService.read(id).subscribe((resp) => {
      this.index();
    })
  }
}
