import { UserFooterComponent } from './user-footer/user-footer.component';
import { UserNavComponent } from './user-nav/user-nav.component';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, Injector, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { JwtInterceptor } from './auth-module/jwt.interceptor';
import { ErrorInterceptor } from './auth-module/error.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxImageCompressService } from 'ngx-image-compress';

import { PublicModule } from './public-module/public-module.module';
import { AuthModule } from './auth-module/auth.module';
import { AlertifyService } from './shared/alertify.service';
import { ListComponent } from './shared/list/list.component';
import { ActionsCellRenderer } from './shared/list/renderer/actions-cell.renderer';
import { FormComponent } from './shared/form/form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPermissionsModule } from 'ngx-permissions';
import { UserModule } from './user-module/user.module';
import { RoleModule } from './role-module/role.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin/admin.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInjector } from './app-injector.service';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { RaffleModule } from './raffle-module/raffle-module.module';

import { CategoryListComponent } from './admin/category/category-list/category-list.component';
import { CategoryFormComponent } from './admin/category/category-form/category-form.component';
import { SubcategoryListComponent } from './admin/subcategory/subcategory-list/subcategory-list.component';
import { SubcategoryFormComponent } from './admin/subcategory/subcategory-form/subcategory-form.component';

import { ApplicationListComponent } from './admin/application/application-list/application-list.component';
import { ApplicationFormComponent } from './admin/application/application-form/application-form.component';

import { RaffleReportListComponent } from './admin/raffle-report/raffle-report-list/raffle-report-list.component';
import { RaffleReportFormComponent } from './admin/raffle-report/raffle-report-form/raffle-report-form.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { AgGridModule } from 'ag-grid-angular';
import { RafflerPaymentListComponent } from './admin/raffler-payment/raffler-payment-list/raffler-payment-list.component';
import { RafflerPaymentFormComponent } from './admin/raffler-payment/raffler-payment-form/raffler-payment-form.component';

import { RefundListComponent } from './admin/refund/refund-list/refund-list.component';
import { TermsComponent } from './terms/terms.component';

import { CookieService } from 'ngx-cookie-service';
import { CancelReportListComponent } from './admin/cancel-report/cancel-report-list/cancel-report-list.component';
import { CancelReportFormComponent } from './admin/cancel-report/cancel-report-form/cancel-report-form.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { RafflesComponent } from './admin/reports/raffles/raffles.component';
import { SalesComponent } from './admin/reports/sales/sales.component';
import { WinnersComponent } from './admin/reports/winners/winners.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { WalletRechargesComponent } from './admin/reports/wallet-recharges/wallet-recharges.component';
import { FaqsComponent } from './faqs/faqs.component';

import { NgCircleProgressModule } from 'ng-circle-progress';

function _window(): any {
    return window;
}

@Injectable()
export class WindowRef {
    get nativeWindow(): any {
        return _window();
    }
}

@NgModule({
    declarations: [
        AppComponent,
        ListComponent,
        FormComponent,
        AdminComponent,
        CategoryListComponent,
        CategoryFormComponent,
        SubcategoryListComponent,
        SubcategoryFormComponent,
        ActionsCellRenderer,
        ApplicationListComponent,
        ApplicationFormComponent,
        RaffleReportListComponent,
        RaffleReportFormComponent,
        RefundListComponent,
        RafflerPaymentListComponent,
        RafflerPaymentFormComponent,
        CancelReportListComponent,
        CancelReportFormComponent,
        ReportsComponent,
        RafflesComponent,
        SalesComponent,
        WinnersComponent,
        WalletRechargesComponent,
        PrivacyComponent,
        TermsComponent,
        FaqsComponent,
        UserFooterComponent,
        UserNavComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        HammerModule,
        AppRoutingModule,
        AuthModule,
        FontAwesomeModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        UserModule,
        RoleModule,
        NgbModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.chasingDots,
            backdropBackgroundColour: 'rgba(180,180,180,.3)',
            backdropBorderRadius: '4px',
            primaryColour: '#a42244',
            secondaryColour: '#a42244',
            tertiaryColour: '#a42244'
        }),
        FormsModule,
        ReactiveFormsModule,
        ImageCropperModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SocialLoginModule,
        AgGridModule.withComponents([]),
        RaffleModule,
        PdfViewerModule,
        PublicModule,
        NgCircleProgressModule.forRoot({})
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        AlertifyService,
        NgxImageCompressService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebookId)
                    }
                ]
            } as SocialAuthServiceConfig,
        },
        CookieService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        AppInjector.setInjector(injector);
    }
}
