<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faFileAlt" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate
				}}</a></li>
		<li class="breadcrumb-item active" aria-current="page">{{ "admin.report" | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center mt-4">
	<div class="row col-md-10">
		<div routerLink="/admin/report/raffles" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faTicketAlt" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp"> {{ "admin.raffles" | translate }}</div>
		</div>
		<div routerLink="/admin/report/sales" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faDollarSign" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp"> {{ "admin.sales" | translate }}</div>
		</div>
		<div routerLink="/admin/report/winners" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faTrophy" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.winners" | translate }}</div>
		</div>
		<div routerLink="/admin/report/wallet-recharges" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faWallet" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.wallet-recharges" | translate }}</div>
		</div>
	</div>
</div>