
<div class="row m-0 bg-success align-items-center">
    <div class="col-auto my-4">
        <button class="btn btn-back" type="button" (click)="modal.dismiss('Finish')">
            <fa-icon [icon]="faAngleLeft" class="h4"></fa-icon>
        </button>
    </div>
    <div class="col-auto">
        <div class="font-weight-bold m-0 text-lg text-white">
            Numeros disponibles
        </div>
    </div>
</div>
<aw-wizard >
    <aw-wizard-step stepTitle="Elegir boletos">
        <ngx-loading [show]="loading"
        [config]="{primaryColour: '#1D4E2B', secondaryColour: '#1D4E2B', tertiaryColour: '#1D4E2B' }"></ngx-loading>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-11">
                    <div class="row justify-content-center">
                        <div class="col-6">
                            <div class="input-group mb-3">
                                <span class="input-group-text border-0 rounded-pill rounded_right">
                                    <fa-icon [icon]="faSearch"></fa-icon>
                                </span>
                                <input id="searchInp" type="text" class="form-control rounded-pill rounded_left" #st (keyup)="searchTickets(st.value)" placeholder="Buscar boletos">
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input mt-1" id="customSwitch" (change)="searchTickets(st.value)" [(ngModel)]="available" >
                                <label class="custom-control-label mt-2" for="customSwitch">Disponibles</label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-auto" *ngFor="let ticket of ticketsToShow| slice: (page-1)*pagesize : page*  pagesize; index as i;">
                            <div class="ticket mb-1" (click)="selectTicket(ticket)">
                                <fa-icon [icon]="faTicket" [class]="'icon-ticket centered '+ ticket.color"></fa-icon>
                                <span class="centered font-weight-bold">{{ticket.number}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" *ngIf="ticketsToShow.length > pagesize">
                        <div class="col-auto">
                            <ngb-pagination
                            [collectionSize]="ticketsToShow.length"
                            [(page)]="page"
                            [(pageSize)]="pagesize">
                            </ngb-pagination>
                        </div>
                    </div>
                    <hr>
                    <div class="row my-2">
                        <div class="col-6">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <p class="font-weight-bold">
                                        <fa-icon [icon]="faTicket" class="h5 text-success"></fa-icon>
                                        Seleccionar rango</p>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="from" class="font-weight-bold text-sm">Desde</label>
                                        <input type="number" id="from" class="form-control" [(ngModel)]="fromNum" #nmi min="1" [max]="tickets.length">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="to" class="font-weight-bold text-sm">Hasta</label>
                                        <input type="number" id="to" class="form-control" [(ngModel)]="toNum" #nma min="1" [max]="tickets.length">
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-outline-sec rounded-pill" (click)="searchRange(st)" type="button">Buscar</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row justify-content-center my-2">
                                <div class="col-12">
                                    <p class="font-weight-bold">
                                        <fa-icon [icon]="faTicket" class="h5 text-success"></fa-icon>
                                        Elegir aleatoriamente</p>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label></label>
                                        <input class="form-control" type="number" #ran min="1" [max]="tickets.length">
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-outline-sec rounded-pill" [disabled]="loadingRandom" (click)="randomTicket(ran.value)" type="button">Elegir</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center my-2">
                        <div class="col-12">
                            <hr>
                            <p class="font-weight-bold">
                                <fa-icon [icon]="faTicket" class="h5 text-success"></fa-icon>
                                Resumen
                            </p>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-12 text-sm">
                                    Cantidad de boletos
                                </div>
                                <div class="col h5 font-weight-bold">
                                    {{ticketsSelected.length + " boletos"}}
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row ala">
                                <div class="col-12 text-sm">
                                    Total
                                </div>
                                <div class="col h5 font-weight-bold">
                                    {{ (price*ticketsSelected.length) | currency}}
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <p class="text-danger text-xsm">Tienes un {{(ticketsSelected.length/tickets.length*100).toFixed()}}% de probabilidad de ganar esta rifa con base a tus boletos comprados.</p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-auto">
                            <button [disabled]="ticketsSelected.length == 0" (click)="startTimer()" class="btn btn-sec my-2 rounded-pill" type="button" awNextStep>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Pago">
        <ngx-loading [show]="loading"
        [config]="{primaryColour: '#1D4E2B', secondaryColour: '#1D4E2B', tertiaryColour: '#1D4E2B' }"></ngx-loading>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-11">
                    <div class="row">
                        <div class="col-6">
                            <div class="row" *ngFor="let ticket of ticketsSelected| slice: (pageSel-1)*pagesizeSel : pageSel*  pagesizeSel; index as i;">
                                <div class="col text-sm">
                                    Boleto número
                                </div>
                                <div class="col font-weight-bold">
                                    {{ticket.number}}
                                </div>
                                <div class="col font-weight-bold">
                                    {{price | currency}}
                                </div>
                                <div class="col">
                                    <fa-icon [icon]="faTrash" (click)="removeTicket(ticket)" class="text-orange pointer"></fa-icon>
                                </div>
                                <div class="col-12 p-0">
                                    <hr class="my-1 mx-3">
                                </div>
                            </div>
                            <div class="row justify-content-center" *ngIf="ticketsSelected.length > pagesizeSel">
                                <div class="col-auto">
                                    <ngb-pagination
                                    [collectionSize]="ticketsSelected.length"
                                    [(page)]="pageSel"
                                    [(pageSize)]="pagesizeSel">
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-12">
                                    <p class="font-weight-bold">
                                        <fa-icon [icon]="faDollarSign" class="text-success"></fa-icon>
                                        Resumen de compra
                                    </p>
                                </div>
                                <div class="col container-summary">
                                    <div class="row justify-content-between">
                                        <div class="col-7">
                                            <div class="row">
                                                <div class="col-12 text-xsm">
                                                    Producto
                                                </div>
                                                <div class="col font-weight-bold">
                                                    {{raffle?.name}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="row text-center">
                                                <div class="col-12 text-xsm">
                                                    Boletos
                                                </div>
                                                <div class="col font-weight-bold text-success">
                                                    {{ticketsSelected.length}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row justify-content-between font-weight-bold text-lg">
                                        <div class="col-auto">
                                            Total
                                        </div>
                                        <div class="col-auto">
                                            {{ (price*ticketsSelected.length) | currency}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row justify-content-center">
                        <div class="col-6">
                            <div class="row justify-content-center">
                                <div class="col-12 text-center font-weight-bold text-sm">
                                    <p>Tiempo restante para realizar la compra</p>
                                </div>
                                <div class="col-auto container-summary">
                                    <p class="h2 align-middle m-0">
                                        <fa-icon [icon]="faTimer" class="text-orange h3"></fa-icon>
                                        {{timeFormat()}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <p class="font-weight-bold">
                                        <fa-icon [icon]="faDollarSign" class="text-success"></fa-icon>
                                        Resumen de compra
                                    </p>
                                </div>
                                <div class="col-auto mx-3 border rounded p-2 pointer" (click)="changeOpPay(1)" [ngClass]="{'border-danger': optionPay == 1}">
                                    <fa-icon [icon]="faBill" class="text-success h1"></fa-icon>
                                </div>
                                <div class="col-auto mx-3 border rounded p-2 pointer" (click)="changeOpPay(2)" [ngClass]="{'border-danger': optionPay == 2}">
                                    <fa-icon [icon]="faCreditCard" class="text-orange h1"></fa-icon>
                                </div>
                            </div>
                            <div class="row my-4 justify-content-center" *ngIf="optionPay == 1">
                                <div class="col">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="row text-center justify-content-center font-weight-bold">
                                                <div class="col-8 border rounded py-1">
                                                    {{wallet?.total | currency}}
                                                </div>
                                                <div class="col-12">
                                                    Saldo
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row text-center justify-content-center font-weight-bold ">
                                                <div class="col-8 border rounded py-1 importe">
                                                    {{ (price*ticketsSelected.length) | currency}}
                                                </div>
                                                <div class="col-12">
                                                    Importe
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row text-orange" *ngIf="wallet?.total < (price*ticketsSelected.length)">
                                        <div class="col text-center font-weight-bold">
                                            Fondos insuficientes
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-4 justify-content-center" *ngIf="optionPay == 2">
                                <div class="col-10">
                                    <select class="form-control" name="tdc" (change)="changeTdc($event, tdc, modalCard)" #tdc [(ngModel)]="tdcSelected">
                                        <option></option>
                                        <option *ngFor="let tdc of tdcList" [ngValue]="tdc.id">{{tdc.number_formatted}}</option>
                                        <option class="pointer">Agregar tarjeta</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-2">
                        <div class="col-auto">
                            <button [disabled]="!optionPay" class="btn btn-sec my-2 rounded-pill" (click)="confirmOrder()" type="button">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>
    <aw-wizard-step stepTitle="Confirmación">
        <div class="container bg-green p-0">
            <div class="row justify-content-center">
                <div class="col-8 ml-4">
                    <div class="row justify-content-center m-5 bg-ticket">
                        <div class="col-12 text-center">
                            <img src="../../../assets/images/home/header/webp/icon@3x.webp" class="logo-ticket mt-2"/>
                        </div>
                        <div class="col-12">
                            <p class="font-weight-bold text-success text-center m-0">¡Compra Exitosa!</p>
                            <p class="font-weight-bold text-center text-dark m-0">Se ha realizado la compra de tus boletos.</p>
                        </div>
                        <div class="col-8">
                            <hr>
                        </div>
                        <div class="col-auto">
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <button class="btn" (click)="openShare(modalShare)">
                                        <fa-icon [icon]="faShare" class="text-orange h4 share"></fa-icon>
                                    </button>
                                </div>
                                <div class="col-12 font-weight-bold text-center ">
                                    Compartir
                                </div>
                            </div>
                        </div>
                        <div class="col-12 text-center my-4">
                            <button class="btn btn-sec" type="button" (click)="modal.dismiss('Finish')">Finalizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aw-wizard-step>
</aw-wizard>

<ng-template #modalCard let-modal>
    <div class="modal-body rounded">
      <app-create-card action="create" (updated)="updatedTdc(modal)" (cancel)="cancelTdc(modal)"></app-create-card>
    </div>
</ng-template>
