import { CrudService } from './../../shared/crud.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RaffleWinnerService extends CrudService {

  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('raffle-winner');
  }

  updateDeliveryInformation(data: any, raffleWinner: any): Observable<any> {
    return this.http.post(this.apiUrl + `/raffle-winner/${raffleWinner}/update-delivery-information`, data);
  }

  updateAddress(data: any, raffleWinner: any): Observable<any> {
    return this.http.post(this.apiUrl + `/raffle-winner/${raffleWinner}/update-address`, data);
  }

  updatePrizeStatus(data: any, raffleWinner: any): Observable<any> {
    return this.http.post(this.apiUrl + `/raffle-winner/${raffleWinner}/update-prize-status`, data);
  }
}
