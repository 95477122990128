<div>
    <nav class="navbar navbar-light bg-blue justify-content-between image-background" >
        <div class="m-3">
            <a routerLink="/"><img src="assets/images/rafiki_logo.png" style="width: 100px"></a>
        </div>   
    </nav>
</div>

<div class="d-flex justify-content-center pt-3">
    <div class="col-md-10">
        <h2 class="text-primary-color-1"> {{ "confirmation-email.email-confirmed" | translate }} </h2>
        <h5 class="text-primary-color-3"> {{ "confirmation-email.thanks" | translate }} </h5>
        <h5 class="text-primary-color-3"> {{ "confirmation-email.login" | translate }}</h5>
    </div>   
</div>
