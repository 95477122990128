<nav aria-label="breadcrumb">
  <ol class="breadcrumb nav-breadcrumb pl-5">
    <li><img src="assets/images/roles.png" class="breadcrum-image"></li>
    <li class="breadcrumb-item"><a routerLink="/home" class="text-primary-color-3">{{ "home.home" | translate }}</a></li>
    <li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.administration" | translate }}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{ "admin.roles" | translate }}</li>
  </ol>
</nav>
<div class="d-flex justify-content-center pt-3">
    <div class="col-md-10">
      <div>
        <form>
          <div class="form-group">
            <div class="form-inline float-left mb-4">
              <div class="icon-addon">
                  <img src="assets/images/icon_buscar2x.png">
                  <input class="form-control ml-2 input-search" type="text" placeholder="{{ 'list.search' | translate }}" name="searchTerm" [(ngModel)]="roleListService.searchTerm" />
              </div>
              <div>
                <span class="ml-3 text-primary-color-3 font-weight-bold" *ngIf="roleListService.loading$ | async">{{ "list.loading" | translate }}</span>
              </div>
            </div>
            <div class="float-right mb-4">
              <button class="btn btn-secondary" routerLink="/role/create"><fa-icon [icon]="faPlus" class="mr-2"></fa-icon>{{ "role.create" | translate }}</button>
            </div>
          </div>
        </form> 
      </div>
      <table class="table table-striped">
        <tr>
          <th scope="col">#</th>
          <th scope="col">{{ "role.form.name" | translate }}</th>
          <th scope="col">{{ "list.actions" | translate }}</th>
        </tr>
        <tr *ngFor="let role of data$ | async">
          <td scope="row"><ngb-highlight [result]="role.id" [term]="roleListService.searchTerm"></ngb-highlight></td>
          <td><ngb-highlight [result]="role.name" [term]="roleListService.searchTerm"></ngb-highlight></td>
          <td>
            <button class="btn btn-default text-primary-color-2" (click)="show(role.id)">
              <img src="assets/images/ver_active2x.png" class="icon-list">
            </button>
            <button class="btn btn-default" (click)="edit(role.id)">
              <img src="assets/images/icon_editar2x.png" class="icon-list">
            </button>
            <button class="btn btn-default" (click)="delete(role.id)">
              <img src="assets/images/icon_borrar2x.png" class="icon-list">
            </button>
          </td>
        </tr>
      </table>
      <div class="d-flex justify-content-between p-2">
        <ngb-pagination [collectionSize]="total$ | async" [(page)]="roleListService.page" [pageSize]="roleListService.pageSize">
        </ngb-pagination>
      
        <select class="custom-select" style="width: auto" [(ngModel)]="roleListService.pageSize">
          <option [ngValue]="20">20</option>
          <option [ngValue]="50">50</option>
          <option [ngValue]="100">100</option>
        </select>
      </div>
    </div>
  </div>
  
