import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ConektaService } from 'src/app/shared/conekta.service';
import { FormComponent } from 'src/app/shared/form/form.component';
import { CardService } from '../card.service';

@Component({
  selector: 'app-create-card',
  templateUrl: './create-card.component.html',
  styleUrls: ['./create-card.component.css']
})
export class CreateCardComponent extends FormComponent implements OnInit {

  public conektaItem;
  public loader = false;
  public months = [
    1,2,3,4,5,6,7,8,9,10,11,12
  ];
  public years = [];

  public validNumber = false;
  public validCvc = false;
  public validExpiration = false;

  constructor( 
    public service: CardService,
    public conekta: ConektaService,
    ) { 
    super(service);
    let year = new Date().getFullYear();

    for (let index = 0; index < 10; index++) {
      this.years.push(year + index);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    
    this.redirect = '/cards';
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      alias: ['', Validators.required],
      number: ['', Validators.required],
      expiration_month: ['', Validators.required],
      expiration_year: ['', Validators.required],
      cvc: ['', Validators.required],
    });

  }

  dataToForm(data) {
    
    return {
    };
  }

  formToData() {
    let type = this.conekta.getBrand(this.f.number.value);
    
    let number = (this.f.number.value).substring((this.f.number.value.length - 4));
    this.data = {
      alias: this.f.alias.value,
      number: number,
      token: this.conektaItem.id,
      type: type
    };

  }

  validation(){
    this.validNumber = this.conekta.validateNumber(this.f.number.value);
    this.validCvc = this.conekta.validateCVC(this.f.cvc.value);
    this.validExpiration = this.conekta.validateExpirationDate( parseInt( this.f.expiration_month.value ) - 1 ,this.f.expiration_year.value);

    return this.validNumber && this.validCvc && this.validExpiration;
  }

  submitToConekta(){
    this.submitted = true;
    if ( !this.validation() || this.form.invalid ) {
      this.alertify.error(this.translate.instant('general.form.complete_fields'));
      return;
    }

    var tokenParams = {
      "card": {
        "number": this.f.number.value,
        "name": this.f.name.value,
        "exp_year": this.f.expiration_year.value,
        "exp_month": this.f.expiration_month.value,
        "cvc": this.f.cvc.value,
      }
    };
    
    this.conekta.createToken(tokenParams,this,this.succesConekta,
      (err)=>this.alertify.error(this.translate.instant('card.messages.error_card_conkecta')));

  }

  succesConekta(response,params,context): any{
    context.conektaItem = response;
    context.formToData();
    context.save();
  }


}
