import { FormComponent } from './../../shared/form/form.component';
import { Component, OnInit } from '@angular/core';
import { AddressService } from '../address.service';
import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-my-address',
  templateUrl: './my-address.component.html',
  styleUrls: ['./my-address.component.css']
})
export class MyAddressComponent extends FormComponent implements OnInit {

  address: any;
  totalAddress: any;

  public faHome = faHome;
  public faAngleRight = faAngleRight;

  constructor(
    public service: AddressService,
    public modalService: NgbModal,
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.index();
  }

  index() {
    this.service.index().subscribe((record) => {
      this.address = record;
      this.totalAddress = record.length;
    });
  }

  createAddress() {
    this.router.navigate(['/my-address/create/']);
  }

  editAddress(id: any) {
    this.router.navigate(['/my-address/edit/' + id]);
  }
}
