<div class="container">
    <div class="row justify-content-center">
        <div class="col-11">
            <form [formGroup]="form" ><!--(ngSubmit)="sendProduct()"-->
                <div class="row justify-content-between py-5">
                    <div class="col-auto">
                        <div class="text-orange font-weight-bold h2">
                            Enviar producto
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="button" (click)="customShow(raffle.id, raffle.slug)"
                            class="btn btn-outline-success rounded-pill font-weight-bold">Cancelar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row justify-content-center align-items-center border photo">
                            <div class="col">
                                <div class="row" *ngIf="raffle?.images.length > 0">
                                    <div class="col">
                                        <ngb-carousel #carousel="ngbCarousel">
                                            <ng-template ngbSlide *ngFor="let image of raffle.images; let i = index"
                                                [id]="i">
                                                <div class="picsum-img-wrapper">
                                                    <img class="img-fluid mx-auto d-block imgCarousel"
                                                        [src]="image.url_image">
                                                </div>
                                            </ng-template>
                                        </ngb-carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="name">Nombre del artículo</label>
                                    <input type="text" readonly name="name" id="name" class="form-control"
                                        formControlName="name">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="contact_name">Ganador</label>
                            <div class="input-group">
                                <input type="text" readonly name="contact_name" id="contact_name"
                                    formControlName="contact_name" class="form-control">
                                <div class="input-group-append">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="address">Dirección</label>
                            <div class="input-group">
                                <input type="text" readonly name="address" id="address" formControlName="address"
                                    class="form-control">
                                <div class="input-group-append">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="country">País</label>
                            <div class="input-group">
                                <input type="text" readonly name="country" id="country" formControlName="country"
                                    class="form-control">
                                <div class="input-group-append">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="city">Ciudad</label>
                            <input type="text" readonly name="city" id="city" formControlName="city"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="cp">Código postal</label>
                            <input type="text" readonly name="cp" id="cp" formControlName="cp" class="form-control">
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="phone">Teléfono</label>
                            <input type="text" readonly name="phone" id="phone" formControlName="phone"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="delivery_company">Paquetería</label>
                            <input type="text" name="delivery_company" id="delivery_company"
                                formControlName="delivery_company" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.delivery_company?.errors }">
                            <div *ngIf="submitted && f.delivery_company?.errors" class="invalid-feedback">
                                <div *ngIf="f.delivery_company?.errors.required">{{ "general.errors.required" |
                                    translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="track_id">Clave de rastreo</label>
                            <input type="text" name="track_id" id="track_id" formControlName="track_id"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.track_id?.errors }">
                            <div *ngIf="submitted && f.track_id?.errors" class="invalid-feedback">
                                <div *ngIf="f.track_id?.errors.required">{{ "general.errors.required" |
                                    translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="estimated_delivery_date">Fecha estimada de entrega</label>
                            <div class="input-group">
                                <input class="form-control bg-white rounded_right" readonly
                                    formControlName="estimated_delivery_date" ngbDatepicker #s="ngbDatepicker"
                                    type="text"
                                    [ngClass]="{ 'is-invalid': submitted && f.estimated_delivery_date?.errors }"
                                    (click)="s.toggle()">
                                <div class="input-group-append">
                                    <button type="button" class="btn iconDate rounded_left" type="button"
                                        (click)="s.toggle()">
                                        <fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon>
                                    </button>
                                </div>
                                <div *ngIf="submitted && f.estimated_delivery_date?.errors" class="invalid-feedback">
                                    <div *ngIf="f.estimated_delivery_date?.errors.required">{{ "general.errors.required"
                                        | translate }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="track_url">Link directo para rastreo</label>
                            <input type="url" name="track_url" id="track_url" formControlName="track_url"
                                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.track_url?.errors }">
                            <div *ngIf="submitted && f.track_url?.errors" class="invalid-feedback">
                                <div *ngIf="f.track_url?.errors.required">{{ "general.errors.required" |
                                    translate }}
                                </div>
                                <div *ngIf="f.track_url?.errors.pattern">Link directo no valido, ejemplo: https://wwww.google.com
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="loading" class="text-center">
                    <div class="spinner-border" style="width: 3rem; height: 3rem; color: #49A446;" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                <hr class="my-4">
                <div class="container" *ngIf="enableDeliveryVoucherFile">
                    <div class="row">
                        <div class="col">
                            <div class="card text-center size-card" style="width: 20rem;">
                                <div class="card-body">
                                    <p class="card-title size-card-title font-weight-bold text-primary-color-3">
                                        Comprobante de entrega</p>
                                    <p class="card-text size-card-subtitle">Tamaño ideal 2542 x 1944 pixeles - 150 ppp.
                                    </p>
                                    <div class="image-upload">
                                        <label for="idDelivery">
                                            <img src="assets/images/icon-add-photo@1x.png"
                                                class="card-img-bottom img-archive">
                                            <p
                                                class="card-text size-card-subtitle font-weight-bold text-primary-color-3">
                                                Adjuntar archivo</p>
                                        </label>
                                        <input id="idDelivery" type="file" accept="image/png, image/jpeg"
                                            (change)="changeDelivery($event)">
                                    </div>
                                </div>
                                <div>
                                    <fa-icon *ngIf="deliveryImage" [icon]="faCheckCircle" class="icon-faCheckCircle">
                                    </fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" *ngIf="urlDerivery">
                    <div class="row">
                        <div class="col-4">
                            <div class="card text-center size-card2" style="width: 20rem;">
                                <img [src]="urlDerivery" [style.height]="sizeImage2" class="card-img-top">
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-4">
                <div class="row my-4">
                    <div class="col-12 mb-5">
                        <button type="submit" class="btn rounded-pill font-weight-bold create"
                            (click)="sendProduct()">Enviar producto</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>