import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AdminGuardGuard } from './admin/admin-guard.guard';
import { CategoryListComponent } from './admin/category/category-list/category-list.component';
import { CategoryFormComponent } from './admin/category/category-form/category-form.component';
import { SubcategoryListComponent } from './admin/subcategory/subcategory-list/subcategory-list.component';
import { SubcategoryFormComponent } from './admin/subcategory/subcategory-form/subcategory-form.component';
import { ApplicationListComponent } from './admin/application/application-list/application-list.component';
import { ApplicationFormComponent } from './admin/application/application-form/application-form.component';
import { RaffleReportListComponent } from './admin/raffle-report/raffle-report-list/raffle-report-list.component';
import { RaffleReportFormComponent } from './admin/raffle-report/raffle-report-form/raffle-report-form.component';
import { RafflerPaymentListComponent } from './admin/raffler-payment/raffler-payment-list/raffler-payment-list.component';
import { RafflerPaymentFormComponent } from './admin/raffler-payment/raffler-payment-form/raffler-payment-form.component';
import { RefundListComponent } from './admin/refund/refund-list/refund-list.component';
import { TermsComponent } from './terms/terms.component';
import { CancelReportListComponent } from './admin/cancel-report/cancel-report-list/cancel-report-list.component';
import { CancelReportFormComponent } from './admin/cancel-report/cancel-report-form/cancel-report-form.component';
import { HomeComponent } from './home/home.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { RafflesComponent } from './admin/reports/raffles/raffles.component';
import { SalesComponent } from './admin/reports/sales/sales.component';
import { WinnersComponent } from './admin/reports/winners/winners.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { WalletRechargesComponent } from './admin/reports/wallet-recharges/wallet-recharges.component';
import { FaqsComponent } from './faqs/faqs.component';
import { RaffleComponent } from './public-module/raffle/raffle.component';


const routes: Routes = [
	{
		path: '',
		component: RaffleComponent,
		pathMatch: 'full'
	},
	{
		path: 'home',
		component: HomeComponent,
	},
	{
		path: 'terms',
		component: TermsComponent
	},{
		path: 'privacy',
		component: PrivacyComponent
	},{
		path: 'faqs',
		component: FaqsComponent
	}, 
	{
		path: 'admin',
		component: AdminComponent,
		canActivate: [AdminGuardGuard],
	},
	{
		path: 'admin',
		canActivate: [AdminGuardGuard],
		children: [
			{
				path: 'category',
				component: CategoryListComponent,
				canActivate: [AdminGuardGuard],
			},
			{
				path: 'category/create',
				component: CategoryFormComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'category/edit/:id',
				component: CategoryFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'category/view/:id',
				component: CategoryFormComponent,
				canActivate: [AdminGuardGuard]
			}, 
			{
				path: 'subcategory',
				component: SubcategoryListComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'subcategory/create',
				component: SubcategoryFormComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'subcategory/edit/:id',
				component: SubcategoryFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'subcategory/view/:id',
				component: SubcategoryFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'application',
				component: ApplicationListComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'application/create',
				component: ApplicationFormComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'application/edit/:id',
				component: ApplicationFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'application/view/:id',
				component: ApplicationFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'raffle-report',
				component: RaffleReportListComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'raffle-report/create',
				component: RaffleReportFormComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'raffle-report/edit/:id',
				component: RaffleReportFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'raffle-report/view/:id',
				component: RaffleReportFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'raffler-payment',
				component: RafflerPaymentListComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'raffler-payment/create',
				component: RafflerPaymentFormComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'raffler-payment/edit/:id',
				component: RafflerPaymentFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'raffler-payment/view/:id',
				component: RafflerPaymentFormComponent,
				canActivate: [AdminGuardGuard]
			}, {
				path: 'refund',
				component: RefundListComponent,
				canActivate: [AdminGuardGuard],
			},
			{
				path: 'cancel-report',
				component: CancelReportListComponent,
				canActivate: [AdminGuardGuard],
			}, {
				path: 'cancel-report/view/:id',
				component: CancelReportFormComponent,
				canActivate: [AdminGuardGuard]
			},{
				path: 'report',
				component: ReportsComponent,
				canActivate: [AdminGuardGuard]
			},{
				path: 'report/raffles',
				component: RafflesComponent,
				canActivate: [AdminGuardGuard]
			},{
				path: 'report/sales',
				component: SalesComponent,
				canActivate: [AdminGuardGuard]
			},{
				path: 'report/winners',
				component: WinnersComponent,
				canActivate: [AdminGuardGuard]
			},{
				path: 'report/wallet-recharges',
				component: WalletRechargesComponent,
				canActivate: [AdminGuardGuard]
			}
		]
	},
	
];


@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
