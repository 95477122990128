import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends CrudService {
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject("notification");
  }

  getNotificationObject(message: string, url: string, sender: any, receiver: any, priority: string, mail: boolean = false) {
    return {
      message: message,
      url: url,
      sender_id: sender,
      receiver_id: receiver,
      priority: priority,
      mail: mail
    }
  }

  getNotifications(all: boolean) {
    return this.http.get(this.apiUrl + '/notification/getNotificationsByUser/' + all);
  }

  getNumberNotifications() {
    return this.http.get(this.apiUrl + '/notification/getNumNotificationsByUser');
  }

  readNotification(id) {
    return this.http.put(this.apiUrl + '/notification/readNotification/' + id, {});
  }

  index(): Observable<any> {
    return this.http.get(this.apiUrl + '/notification');
  }

  read(id: any): Observable<any> {
    return this.http.put(this.apiUrl + '/notification/read/' + id, {});
  }
}
