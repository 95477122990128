<div class="container">
    <div class="row justify-content-center">
        <div class="col-11">
            <form [formGroup]="form">
                <div class="row justify-content-between py-5">
                    <div class="col-auto">
                        <div class="text-orange font-weight-bold h2">
                            Rifa
                        </div>
                    </div>
                    <div class="col-auto">
                        <button type="button" routerLink="/profile/raffles"
                            class="btn btn-outline-success rounded-pill font-weight-bold">Cancelar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row justify-content-center align-items-center border photo">
                            <div class="col">
                                <div class="row" *ngIf="raffle?.images.length > 0">
                                    <div class="col">
                                        <ngb-carousel #carousel="ngbCarousel">
                                            <ng-template ngbSlide *ngFor="let image of raffle.images; let i = index"
                                                [id]="i">
                                                <div class="picsum-img-wrapper">
                                                    <img class="img-fluid mx-auto d-block imgCarousel"
                                                        [src]="image.url_image">
                                                </div>
                                            </ng-template>
                                        </ngb-carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row formInputs">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="name">Nombre del artículo</label>
                                    <input type="text" readonly name="name" id="name" class="form-control"
                                        formControlName="name">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5 formInputs">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="suggested_value">Valor promedio con base a Rafiki</label>
                            <div class="input-group">
                                <input type="text" readonly name="suggested_value" id="suggested_value"
                                    class="form-control" formControlName="suggested_value">
                                <div class="input-group-append">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="prize_value">Valor del premio asignado $</label>
                            <div class="input-group">
                                <input type="text" readonly name="prize_value" id="prize_value" class="form-control"
                                    formControlName="prize_value">
                                <div class="input-group-append">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="total_amount">Venta total esperada</label>
                            <div class="input-group">
                                <input type="text" readonly name="total_amount" id="total_amount" class="form-control"
                                    formControlName="total_amount">
                                <div class="input-group-append">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="num_tickets">Boletos</label>
                            <input type="text" readonly name="num_tickets" id="num_tickets" class="form-control"
                                formControlName="num_tickets">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="ticket_price">Precio por boleto</label>
                            <input type="text" readonly name="ticket_price" id="ticket_price" class="form-control"
                                formControlName="ticket_price">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="brand">Marca</label>
                            <input type="text" readonly name="brand" id="brand" class="form-control"
                                formControlName="brand">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="model">Modelo</label>
                            <input type="text" readonly name="model" id="model" class="form-control"
                                formControlName="model">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="product_type">Tipo de premio</label>
                            <input type="text" readonly name="product_type" id="product_type" class="form-control"
                                formControlName="product_type">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="prize_type">Estado del premio</label>
                            <input type="text" readonly name="prize_type" id="prize_type" class="form-control"
                                formControlName="prize_type">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="description">Descripción</label>
                            <textarea readonly class="form-control bg-EFF3F6" rows="3"
                                formControlName="description"></textarea>
                        </div>
                    </div>
                </div>
                <hr class="my-5">
                <div class="row">
                    <div class="col">
                        <p class="h5 font-weight-bold">
                            <fa-icon [icon]="faDollar" class="text-success"></fa-icon>
                            Resumen
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="row border summary">
                            <div class="col-12 my-2">
                                <div class="row">
                                    <div class="col-8">
                                        Monto máximo
                                    </div>
                                    <div class="col-4 text-success font-weight-bold">
                                        <p> {{detail.total | currency}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 my-2">
                                <div class="row">
                                    <div class="col-8">
                                        Comisión Rafiki 20%
                                    </div>
                                    <div class="col-4 text-danger font-weight-bold">
                                        <p> {{detail.comissionRafiki | currency}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 font-weight-bold my-2">
                                <div class="row">
                                    <div class="col-8">
                                        Total
                                    </div>
                                    <div class="col-4 text-success">
                                        <p>{{detail.totalLessComissions | currency}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <hr>
                                <div class="alert" role="alert">
                                    Nota: considera adicionalmente los gastos de envío que tienes presupuestado, el IVA
                                    de la comisión Rafiki y la retención del impuesto sobre la renta correspondiente.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="start">Fecha inicio</label>
                                    <div class="input-group">
                                        <input type="text" readonly name="start" id="start" formControlName="start"
                                            class="form-control rounded_right bg-EFF3F6">
                                        <div class="input-group-append">
                                            <button type="button" class="btn rounded_left bg-EFF3F6">
                                                <fa-icon [icon]="faCalendarAlt" class="text-orange"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label for="finish">Fecha fin</label>
                                    <div class="input-group">
                                        <input type="text" readonly name="finish" id="finish" formControlName="finish"
                                            class="form-control rounded_right bg-EFF3F6">
                                        <div class="input-group-append">
                                            <button type="button" class="btn rounded_left bg-EFF3F6">
                                                <fa-icon [icon]="faCalendarAlt" class="text-orange"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-5">
                <div class="row">
                    <div class="col">
                        <p class="h5 font-weight-bold">
                            <fa-icon [icon]="faLocation" class="text-success"></fa-icon>
                            Ubicación del premio
                        </p>
                    </div>
                </div>
                <div class="row locations mt-2">
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="cat_state_id">Estado</label>
                            <input class="form-control" readonly name="cat_state_id" id="cat_state_id"
                                formControlName="cat_state_id">
                        </div>
                    </div>
                    <div class="col-6 formInputs">
                        <div class="form-group">
                            <label for="city">Ciudad</label>
                            <input class="form-control" readonly name="city" id="city" formControlName="city">
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-12 text-center mb-5">
                        <button *ngIf="winnerID" type="submit" class="btn rounded-pill font-weight-bold create" (click)="winnerDelivery(raffle.id, raffle.product_type)">Información de envío</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>