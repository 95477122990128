import { Component, OnInit } from '@angular/core';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { ListService } from '../../../shared/list-service.service';
import { ListComponent } from '../../../shared/list/list.component';
import { ActionsCellRenderer } from '../../../shared/list/renderer/actions-cell.renderer';
import { RefundService } from '../refund.service';
import { TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_EN } from '../../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../../shared/list/locale/es.locale';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-refund-list',
	templateUrl: './refund-list.component.html',
	styleUrls: ['./refund-list.component.css'],
	providers: [ListService, DecimalPipe, CurrencyPipe]
})
export class RefundListComponent extends ListComponent implements OnInit {
	//private gridApi;
	//public gridOptions: any;
	//public gridLocale;
	faWallet = faWallet;

	constructor(
		public refundService: RefundService,
		public listService: ListService,
		public translateService: TranslateService,
		public currencyPipe: CurrencyPipe
	) {
		super(refundService, listService);
		this.setObject('admin/refund');
	}

	ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			columnDefs: [
				{ headerName: 'refund.raffle', field: 'raffle.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'refund.user', field: 'user.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'refund.date', field: 'created_at', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'refund.amount',
					field: 'amount',
					sortable: true,
					cellRenderer: params =>
						this.currencyPipe.transform(params.data.amount, 'USD', 'symbol', '1.2-2')
					,
					headerValueGetter: this.localizeHeader.bind(this)
				},
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}
}
