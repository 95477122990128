import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  returnUrl: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';

  sended: boolean;
  sendedError: boolean;
  user: any;

  @Input('modal') public loginModal;
  @Output('forgetEmailSended') public forgetEmailSended = new EventEmitter();

  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public alertify: AlertifyService,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.sendedError = false;
    this.sended = false;

    this.auth.resetPassword({ email: this.f.username.value }).subscribe((response) => {
      console.log(response);
      this.sended = true;
      this.loading = false;
      if (this.forgetEmailSended.observers.length > 0) {
        this.forgetEmailSended.emit(response);
      }

      if (response.status != 200) {
        this.sendedError = true;
      }
    }, (error) => {
      this.sendedError = true;
      this.sended = true;
      this.loading = false;
    });
  }

  public closeModal() {
    this.loginModal.dismiss('Login');
  }
}
