import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from '../../../shared/list-service.service';
import { ListComponent } from '../../../shared/list/list.component';
import { ActionsCellRenderer } from '../../../shared/list/renderer/actions-cell.renderer';
import { RaffleReportService } from '../raffle-report.service';
import { TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_EN } from '../../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../../shared/list/locale/es.locale';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-raffle-report-list',
	templateUrl: './raffle-report-list.component.html',
	styleUrls: ['./raffle-report-list.component.css'],
	providers: [ListService, DecimalPipe]
})
export class RaffleReportListComponent extends ListComponent implements OnInit {
	//private gridApi;
	//public gridOptions: any;
	//public gridLocale;
	faExclamationCircle = faExclamationCircle;

	constructor(
		public raffleReportService: RaffleReportService,
		public listService: ListService,
		public translateService: TranslateService
	) {
		super(raffleReportService, listService);
		this.setObject('admin/raffle-report');
	}

	ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			columnDefs: [
				{ headerName: 'raffle_report.raffle', field: 'raffle.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle_report.user', field: 'user.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle_report.comments', field: 'comments', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle_report.admin', field: 'admin.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle_report.admin_comments', field: 'admin_comments', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'raffle.status',
					field: 'status',
					sortable: true,
					headerValueGetter: this.localizeHeader.bind(this),
					cellRenderer: params =>
						this.translate.instant('raffle_report.status.' + params.value)
					,
				},
				{ headerName: 'raffle_report.reviewed_at', field: 'reviewed_at', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'list.actions',
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'faEye',
								action: (id) => {
									this.show(id);
								}
							},
							{
								icon: 'faPencilAlt',
								action: (id) => {
									this.edit(id);
								}
							}
						]
					},
					headerValueGetter: this.localizeHeader.bind(this)
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}
}
