<div class="height-100">
    <nav class="navbar navbar-light bg-white justify-content-between border-line-bottom nav-shadow" *ngIf="!this.auth.currentUserValue">
        <div class="m-3">
            <a routerLink="/"><img src="assets/images/rafiki_logo.png" style="width: 100px"></a>
        </div>
    </nav>   
   
   <div class="d-flex justify-content-center pt-3">
        <div class="col-md-10">
            
            <form (ngSubmit)="submit()" class="bg-form shadow">
                <div class="d-flex justify-content-center">
                    <div class="col-md-6 text-center">
                        <h5>Recuperar contraseña:</h5>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email">Correo electrónico:</label>
                    <input class="form-control" readonly [(ngModel)]="email" name="email" type="text" >
                </div>
                <div class="form-group">
                    <label for="password">Contraseña:</label>
                    <input class="form-control" [(ngModel)]="password" name="password" type="password" >
                </div>
                <div class="form-group">
                    <label for="confirm_password">Confirmar contraseña:</label>
                    <input class="form-control" [(ngModel)]="confirm" name="confirm_password" type="password" >
                </div>

                <div class="d-flex justify-content-center">
                     <button type="submit" class="btn btn-primary" >Enviar</button>
                </div>
            </form>
        </div>
    </div>
    
</div>

<footer id="sticky-footer" class="text-white" *ngIf="!this.auth.currentUserValue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    <a href="https://rafiki.mx/" class="footer-link">Rafiki</a> 
  </div>
  <!-- Copyright -->

</footer>




