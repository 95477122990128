import { Component, OnInit } from '@angular/core';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';
import { TranslateService } from '@ngx-translate/core';
import { ActionsCellRenderer } from 'src/app/shared/list/renderer/actions-cell.renderer';
import { AG_GRID_LOCALE_ES } from 'src/app/shared/list/locale/es.locale';
import { RafflerPaymentService } from '../raffler-payment.service';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';

const PENDING = 'pending';
const ACCEPTED = 'accepted';
const REJECTED = 'rejected';

@Component({
	selector: 'app-raffler-payment-list',
	templateUrl: './raffler-payment-list.component.html',
	styleUrls: ['./raffler-payment-list.component.css'],
	providers: [ListService, DecimalPipe, CurrencyPipe]
})
export class RafflerPaymentListComponent extends ListComponent implements OnInit {

	faDollarSign = faDollarSign;
	//private gridApi;
	//public gridOptions: any;
	//public gridLocale;

	constructor(
		public service: RafflerPaymentService,
		public listService: ListService,
		public translateService: TranslateService,
		public currencyPipe: CurrencyPipe
	) {
		super(service, listService);
		this.setObject('admin/raffler-payment');
	}

	ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			columnDefs: [
				{ headerName: '#', field: 'raffle.id', sortable: true, headerValueGetter: this.localizeHeader.bind(this), width: 50 },
				{ headerName: 'raffler-payment.name', field: 'raffle.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this), width: 300 },
				{
					headerName: 'raffler-payment.status',
					field: 'status',
					sortable: true,
					cellRenderer: params => {
						if (params.data.status == PENDING) {
							return this.translate.instant('raffler-payment.status_raffler.pending');
						} else if (params.data.status == ACCEPTED) {
							return this.translate.instant('raffler-payment.status_raffler.accepted');
						} else if (params.data.status == REJECTED) {
							return this.translate.instant('raffler-payment.status_raffler.rejected');
						}
					},
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'raffler-payment.ticket_amount',
					field: 'ticket_amount',
					sortable: true,
					cellRenderer: params =>
						this.currencyPipe.transform(params.data.ticket_amount, 'USD', 'symbol', '1.2-2')
					,
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'raffler-payment.commission_amount',
					field: 'commission_amount',
					sortable: true,
					cellRenderer: params =>
						this.currencyPipe.transform(params.data.commission_amount, 'USD', 'symbol', '1.2-2')
					,
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'raffler-payment.raffler_amount',
					field: 'raffler_amount',
					sortable: true,
					cellRenderer: params =>
						this.currencyPipe.transform(params.data.raffler_amount, 'USD', 'symbol', '1.2-2')
					,
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'list.actions',
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'faEye',
								action: (id) => {
									this.show(id);
								},
							},
							{
								icon: 'faPencilAlt',
								action: (id) => {
									this.edit(id);
								},
								disabled: (data) =>
									data.status !== 'pending'
							},
							{
								icon: 'faMoneyBill',
								action: (id) => {
									this.generatePayment(id);
								},
								disabled: (data) =>
									!data.can_generate_payment
							},
						]
					},
					headerValueGetter: this.localizeHeader.bind(this),
					width: 200
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	generatePayment(id) {
		this.service.generatePayment(id).subscribe((response) => {
			this.loadData();
		}, (error) => {
			this.alertify.error(this.translate.instant('general.error'));
		});
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

	loadData() {
		this.service.all().subscribe((data) => {
			this.data = data.data;
			this.listService.setData(data.data);
		}, (error) => {
			this.alertify.error(this.translate.instant('general.list.load_error'));
		});
	}

}
