import { Component, OnInit, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from '../../shared/list-service.service';
import { ListComponent } from '../../shared/list/list.component';
import { ActionsCellRenderer } from '../../shared/list/renderer/actions-cell.renderer';
import { AdminUserService } from '../admin-user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../shared/list/locale/es.locale';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faUsers } from '@fortawesome/free-solid-svg-icons';


@Component({
	selector: 'app-admin-user-list',
	templateUrl: './admin-user-list.component.html',
	styleUrls: ['./admin-user-list.component.css'],
	providers: [ListService, DecimalPipe]
})
export class AdminUserListComponent extends ListComponent implements OnInit {
	@ViewChild('changePasswordModal', {static: false}) changePasswordModal;
	//private gridApi;
	//public gridOptions: any;
	//public gridLocale;
	faUsers = faUsers;
	selectedUser: any;
	error: boolean;
	password: string;
	confirmPassword: string;
	modalPassword: Promise<void>;

	constructor(
		public userService: AdminUserService,
		public listService: ListService,
		public translateService: TranslateService,
		public modalService: NgbModal,
		public auth: AuthService
	) {
		super(userService, listService);
		this.setObject('admin/admin');
	}

	ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'admin-user.form.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'admin-user.form.email', field: 'email', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'category.active',
					field: 'active',
					sortable: true,
					cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.active.yes');
						} else {
							return this.translate.instant('form.active.no');
						}
					},
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'list.actions',
					headerValueGetter: this.localizeHeader.bind(this),
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'faKey',
								action: (id) => {
									this.open(id);
								}
							},
							{
								icon: 'faEye',
								action: (id) => {
									this.show(id);
								}
							},
							{
								icon: 'faPencilAlt',
								action: (id) => {
									this.edit(id);
								}
							}
						]
					}
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

	open(id) {
		this.selectedUser = id;
		this.modalPassword = this.modalService.open(this.changePasswordModal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			this.cleanModal();
		}, (reason) => {
			this.cleanModal();
		});
	}

	validModal() {
		if (this.password && this.confirmPassword && (this.password === this.confirmPassword)) {
			this.error = false;
			return true;
		}
		this.error = true;
		return false;
	}

	changePassword() {
		if (!this.validModal()) {
			return false;
		}
		this.auth.changePasswordByAdmin(this.selectedUser, this.password)
			.subscribe(
				(response) => {
					this.modalService.dismissAll();
					this.alertify.success(this.translate.instant('general.success'));
				},
				(error) => {
					this.alertify.error(this.translate.instant('general.error'));
				}
			);
	}

	cleanModal() {
		this.selectedUser = '';
		this.password = '';
		this.confirmPassword = '';
		this.error = false;
	}
}
