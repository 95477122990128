import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private permissionsSubject: BehaviorSubject<any>;
    public permissions: Observable<any>;
    private apiUrl: string;

    constructor(private http: HttpClient, public permissionsService: NgxPermissionsService) {
        this.apiUrl = environment.apiUrl;
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        let permissionJson = JSON.parse(localStorage.getItem('permissions'));
        this.permissionsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('permissions')));
        this.permissions = this.permissionsSubject.asObservable();
        this.permissionsService.loadPermissions(permissionJson ? permissionJson : []);
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public get permissionsValue(): any {
        return this.permissionsSubject.value;
    }

    public setPermissions(permissions) {
        if (permissions) {
            localStorage.setItem('permissions', JSON.stringify(permissions));
            this.permissionsSubject.next(permissions);
            this.permissionsService.loadPermissions(permissions);
        }
    }

    getPermissions(): Observable<any> {
        return this.http.get(this.apiUrl + '/user/permissions');
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('permissions');
        this.permissionsService.flushPermissions();
        this.currentUserSubject.next(null);
        this.permissionsSubject.next(null);
    }

    login(data, path = '/login'): Observable<any> {
        let body = data;

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.post<any>(this.apiUrl + path, body, httpOptions).pipe(
            map((user) => {
                // login successful if there's a jwt token in the response
                if (user && user.access_token) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            })
        );
    }

    changePasswordByAdmin(id, newPassword) {
        return this.http.put(this.apiUrl + '/user/change-password-by-admin/' + id, { password: newPassword });
    }

    changePasswordByUser(oldPassword, newPassword) {
        return this.http.post(this.apiUrl + '/user/change-password', { old_password: oldPassword, new_password: newPassword });
    }

    resetPassword(data): Observable<any> {
        return this.http.post(this.apiUrl + '/reset-password', data);
    }

    resetConfirm(data) {
        return this.http.post(this.apiUrl + '/reset-confirm', data);
    }
}
