import { NotificationsComponent } from './../public-module/notifications/notifications.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminUserListComponent } from './admin-user-list/admin-user-list.component';
import { AdminUserFormComponent } from './admin-user-form/admin-user-form.component';
import { ClientUserFormComponent } from './client-user-form/client-user-form.component';
import { ClientUserListComponent } from './client-user-list/client-user-list.component';
import { AuthGuard } from '../auth-module/auth.guard';
import { CardListComponent } from './card-list/card-list.component';
import { CreateCardComponent } from './create-card/create-card.component';
import { AdminGuardGuard } from '../admin/admin-guard.guard';
import { MyAddressComponent } from '../public-module/my-address/my-address.component';
import { MyAddressFormComponent } from '../public-module/my-address-form/my-address-form.component';

const routes: Routes = [
	{
		path: 'admin',
		canActivate: [AdminGuardGuard],
		children: [
			{
				path: 'admin',
				component: AdminUserListComponent,
				canActivate: [AuthGuard],
			}, {
				path: 'admin/create',
				component: AdminUserFormComponent,
				canActivate: [AuthGuard]
			}, {
				path: 'admin/edit/:id',
				component: AdminUserFormComponent,
				canActivate: [AuthGuard]
			}, {
				path: 'admin/view/:id',
				component: AdminUserFormComponent,
				canActivate: [AuthGuard]
			},
			{
				path: 'client',
				component: ClientUserListComponent,
				canActivate: [AuthGuard],
			}, {
				path: 'client/edit/:id',
				component: ClientUserFormComponent,
				canActivate: [AuthGuard]
			}, {
				path: 'client/view/:id',
				component: ClientUserFormComponent,
				canActivate: [AuthGuard]
			},
		]
	},
	{
	  path: 'cards',
	  component: CardListComponent,
	  canActivate: [AuthGuard]
	},
	{
	  path: 'create-card',
	  component: CreateCardComponent,
	  canActivate: [AuthGuard]
	},
	{
		path: 'notifications',
		component: NotificationsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'my-address',
		component: MyAddressComponent,
		canActivate: [AuthGuard]
	}, {
		path: 'my-address/create',
		component: MyAddressFormComponent,
		canActivate: [AuthGuard]
	}, {
		path: 'my-address/edit/:id',
		component: MyAddressFormComponent,
		canActivate: [AuthGuard]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UserRoutingModule { }
