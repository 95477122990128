<div class="height-100">
  <nav class="navbar navbar-light bg-white justify-content-between border-line-bottom nav-shadow"
    *ngIf="this.auth.currentUserValue && isAdmin && !isLoginAdmin && pathAdmin">
      <div class="m-3">
        <a routerLink="/admin"><img src="assets/images/rafiki_logo.png" style="width: 150px"></a>
      </div>
      <div class="primary-color-text">
        <span [ngClass]="{'bold': lang == 'es'}" (click)="changeLanguage('es')">{{ "lang.spanish" | translate }}</span>
        |
        <span [ngClass]="{'bold': lang == 'en'}"(click)="changeLanguage('en')">{{ "lang.english" | translate }}</span>
      </div>
      <div class="form-inline primary-color-text mr-4" *ngIf="auth.currentUserValue">
        <div class="btn-group mr-3">
          <div class="btn-group" ngbDropdown role="group" aria-label="Menu">
            <a class="primary-color-text mr-5 underline link-pointer" ngbDropdownToggle>
              {{ this.auth.currentUserValue?.user?.name }}
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <!--button ngbDropdownItem routerLink="/admin" *ngIf="auth.permissionsValue &&  auth.permissionsValue.role == 'Administrador'">{{ "nav.admin" | translate }}</button!-->
              <button ngbDropdownItem routerLink="/profile">{{ "nav.profile" | translate }}</button>
              <!--button ngbDropdownItem routerLink="/cards">{{ "nav.cards" | translate }}</button!-->
              <div class="dropdown-divider"></div>
              <button ngbDropdownItem (click)="logout()">{{ "nav.logout" | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div *ngIf="auth.currentUserValue && !isAdmin && !isLoginAdmin">
      <app-user-nav></app-user-nav>
    </div>

    <router-outlet></router-outlet>

    <div *ngIf="(!isAdmin || !pathAdmin) && !isLoginAdmin">
      <app-user-footer></app-user-footer>
    </div>
</div>

<!--<footer id="sticky-footer" class="text-white" *ngIf="this.auth.currentUserValue && isAdmin && pathAdmin">-->
<!--  &lt;!&ndash; Copyright &ndash;&gt;-->
<!--  <div class="footer-copyright text-center py-3">-->
<!--    <a href="http://strappcorp.com/" class="footer-link"> StrappCorp</a>, we sweat the code.-->
<!--  </div>-->
<!--  &lt;!&ndash; Copyright &ndash;&gt;-->
<!--</footer>-->
