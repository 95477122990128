<div class="row height-100">
    <div class="col-12">
      <div class="row justify-content-center align-items-center">
        <div class="col-12 mb-4 px-5">
          <img src="assets/images/home/header/webp/logo.webp" class="logo"/>
        </div>
        <div class="col-12 px-5">
            <p class="h4 font-weight-bold text-success">{{ "index.recover_password" | translate }}</p>
        </div>
        <div class="col-12 px-5" *ngIf="!sended || sendedError">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <div>
                <label for="">{{ "index.email_register" | translate }}:</label>
                <input
                  type="text"
                  formControlName="username"
                  [placeholder]="'register.email' | translate "
                  class="form-control login-input"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                />
              </div>
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">
                  {{ "register.errors.required" | translate }}
                </div>
              </div>
            </div>
            <div class="form-group mt-5">
                <button
                  class="btn btn-success text-white font-weight-bold btn-block rounded-pill"
                  type="submit"
                  [disabled]="loading"
                >
                {{ "index.recover_password" | translate }}
                </button>
                <img
                  *ngIf="loading"
                  class="pl-2"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              </div>
              <div *ngIf="sendedError">
                <label class="error-color-text text-center"> <strong>
                  {{ "index.try_again_later" | translate }}</strong></label>
              </div>
          </form>
        </div>
        <div class="col-12 pt-4 px-5"  *ngIf="sended">
            <form>
              <div class="form-group">
                <div *ngIf="!sendedError">
                  <label class="text-primary-color-3 text-center"> <strong>
                    {{ "index.send_email" | translate }}</strong> </label>
                </div>
              </div>
            </form>
          </div>
      </div>
    </div>
  </div>

