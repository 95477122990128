import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCalendarAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { FormComponent } from 'src/app/shared/form/form.component';
import { RaffleWinnerService } from './raffle-winner.service';

@Component({
  selector: 'app-raffler-winner-delivery',
  templateUrl: './raffler-winner-delivery.component.html',
  styleUrls: ['./raffler-winner-delivery.component.css']
})
export class RafflerWinnerDeliveryComponent extends FormComponent implements OnInit {

  currentID: string;
  raffle: any;
  form: FormGroup;
  formatDate: any;
  sizeImage2: string = '196px';
  enableDeliveryVoucherFile: boolean;
  submitted: boolean = false;
  deliveryImage: any;
  urlDerivery: any;
  winnerID: any;
  loading = false;
  addressIDWinner: any;

  public faCheckCircle = faCheckCircle;
  public faCalendarAlt = faCalendarAlt;

  constructor(
    public route: ActivatedRoute,
    public service: RaffleService,
    public router: Router,
    public fb: FormBuilder,
    public raffleWinnerService: RaffleWinnerService,
    public alertify: AlertifyService,
  ) {
    super(raffleWinnerService);
  }

  ngOnInit(): void {
    this.createForm();
    this.currentID = this.route.snapshot.paramMap.get('id');
    this.winnerDetail(this.currentID);
  }

  customShow(id, slug) {
    this.router.navigate(['profile/raffles/' + id + '/' + slug]);
  }

  get f() {
    return this.form.controls;
  }

  winnerDetail(id) {
    this.service.winnerDetail(id).subscribe((response) => {
      this.raffle = response;      
      this.f.name.setValue(this.raffle.name);
      this.f.contact_name.setValue(this.raffle.raffle_winner?.address?.contact_name);
      this.f.address.setValue(this.raffle.raffle_winner?.address?.name);
      this.f.country.setValue(this.raffle.raffle_winner?.address?.country);
      this.f.city.setValue(this.raffle.raffle_winner?.address?.city);
      this.f.cp.setValue(this.raffle.raffle_winner?.address?.cp);
      this.f.phone.setValue(this.raffle.raffle_winner?.address?.phone);
      this.f.delivery_company.setValue(this.raffle.raffle_winner?.delivery_company);
      this.f.track_id.setValue(this.raffle.raffle_winner?.track_id);

      this.formatDate = (this.raffle.raffle_winner?.estimated_delivery_date !== null ? this.getFormatDate(this.raffle.raffle_winner?.estimated_delivery_date) : null);     
      this.f.estimated_delivery_date.setValue(this.formatDate !== null ? this.stringToDate(this.formatDate) : '');

      this.f.track_url.setValue(this.raffle.raffle_winner?.track_url);      

      this.winnerID = this.raffle.raffle_winner?.id;
      this.urlDerivery = this.raffle.raffle_winner?.delivery_voucher_url;
      this.enableDeliveryVoucherFile = (this.urlDerivery == null ? true : false);
      this.addressIDWinner = this.raffle?.raffle_winner?.address_id;      
    }, (error) => {
      this.alertify.error(error || "Ocurrió un error");
    });
  }

  createForm() {
    const trackUrlPattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i;
    this.form = this.fb.group({
      name: [''],
      contact_name: [''],
      address: [''],
      country: [''],
      city: [''],
      cp: [''],
      phone: [''],
      delivery_company: ['', Validators.required],
      track_id: ['', Validators.required],
      estimated_delivery_date: ['', Validators.required],
      track_url: ['', [Validators.required, Validators.pattern(trackUrlPattern)]]
    });
  }

  getFormatDate(date) {
    let day = new Date(date).toLocaleDateString('es', { weekday: 'short' });
    day = day.replace(/^\w/, (c) => c.toUpperCase());
    const month = new Date(date).toLocaleDateString('es', { month: 'short' });
    const numDay = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return day + ', ' + numDay + ' ' + month + ', ' + year;
  }

  objDateToStr(obj) {
    if (!obj) {
      return '00-00-00 00:00:00';
    }
    return obj.year + '-' + obj.month + '-' + obj.day;
  }

  stringToDate(dateString) {
    let date = new Date(dateString);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  sendProduct() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      if (this.addressIDWinner == null) {
        this.alertify.error('Por favor, agrega una dirección para recibir tus premios.');
        return;
      }
      if (this.deliveryImage == undefined) {
        this.alertify.error('Por favor, agrega tu comprobante de entrega.');
      } else {
        try {
          const formData: FormData = new FormData();
          const data = this.form.value;
          formData.append('delivery_company', data.delivery_company);
          formData.append('track_id', data.track_id);
          formData.append('estimated_delivery_date', this.objDateToStr(data.estimated_delivery_date));
          formData.append('track_url', data.track_url);
          formData.append('delivery_voucher_file', this.deliveryImage);
          this.loading = true;
          this.raffleWinnerService.updateDeliveryInformation(formData, this.winnerID).subscribe((res) => {
            this.loading = false;
            this.alertify.success(this.translate.instant('general.form.success_save'));
            this.urlDerivery = res.delivery_voucher_url;
            this.enableDeliveryVoucherFile = (this.urlDerivery == null ? true : false);
          }, (error) => {           
            this.loading = false;
            this.alertify.error('La rifa aun no cuenta con un ganador.');
          });
        } catch (error) {
          this.loading = false;
          this.alertify.error('Ocurrió un error al subir la imagen al servidor');
        }
      }
    }
  }

  changeDelivery(event: any): void {
    this.deliveryImage = event.target.files[0];
  }
}
