<div class="header">
  <div class="row justify-content-between align-items-center mb-4 mt-2">
    <div class="col-auto">
      <div>
        <a routerLink="/raffle"><img src="assets/images/rafiki_logo.png" style="width: 150px"></a>
      </div>
    </div>
    <div class="col">
      <div class="row justify-content-center">
        <div class="col-auto">
          <a class="text-white underline link-pointer" routerLink="/profile/raffles">Mis Rifas</a>
        </div>
        <div class="col-auto">
          <a class="text-white underline link-pointer" routerLink="/my-tickets">Boletos</a>
        </div>
        <div class="col-auto">
          <a class="text-white underline link-pointer" routerLink="/results">Resultados</a>
        </div>
      </div>
    </div>
    <div class="col-auto">
      <button type="button" class="btn btn-success rounded-pill" (click)="createRaffle(modalConfirm)">
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        <small class="font-weight-bold">Crear rifa</small>
      </button>
    </div>
    <div class="col-auto">
      <div class="form-inline primary-color-text">
        <div class="btn-group mr-3">
          <div class="btn-group" ngbDropdown role="group" aria-label="Menu">
            <button type="button" class="btn btn-sec rounded-pill" ngbDropdownToggle>
                <div *ngIf="notifications > 0" class="col-auto p-0 notification">
                  <fa-icon [icon]="faBell" class="h5 text-warning centered"></fa-icon>
                  <span class="num-notification centered font-weight-bold">{{notifications > 9 ? '+9' : notifications }}</span>
                </div>
                <small class="font-weight-bold">Más Rafiki</small>
            </button>
            <div class="dropdown-menu py-4" ngbDropdownMenu>
              <div class="dd-menu">
                <button ngbDropdownItem routerLink="/profile">
                  <fa-icon [icon]="faUser" class="h5 mr-2 text-purple"></fa-icon>
                  {{ this.auth.currentUserValue?.user?.name + ' '+ this.auth.currentUserValue?.user?.last_name }}
                </button>
              </div>
              <div class="dd-menu">
                <button ngbDropdownItem routerLink="/wallet">
                  <img src="../../favicon.ico" style="width: 23px" class="mr-2">
                  {{ "nav.wallet" | translate }}
                </button>
              </div>
              <div class="dd-menu">
                <button ngbDropdownItem routerLink="/cards">
                  <fa-icon [icon]="faCreditCard" class="h5 mr-2 text-success"></fa-icon>
                  {{ "nav.cards" | translate }}
                </button>
              </div>
              <div class="dd-menu">
                <button ngbDropdownItem routerLink="/my-address">
                  <fa-icon [icon]="faHome" class="h5 mr-2 text-secundary-back-2"></fa-icon>
                  {{ "nav.myAddress" | translate }}
                </button>
              </div>
              <div class="dd-menu">
                <button ngbDropdownItem routerLink="/notifications">
                  <fa-icon [icon]="faBell" class="h5 mr-2 text-warning"></fa-icon>
                  {{ "nav.notifications" | translate }}
                </button>
              </div>
              <div class="dd-menu">
                <button ngbDropdownItem routerLink="/terms">
                  <fa-icon [icon]="faClipboardCheck" class="h5 mr-2 text-orange"></fa-icon>
                  {{ "home.terms" | translate }}
                </button>
              </div>
              <div class="dd-menu">
                <button ngbDropdownItem routerLink="/privacy">
                  <fa-icon [icon]="faInfo" class="h5 mr-2 text-warning"></fa-icon>
                  {{ "home.privacy" | translate }}
                </button>
              </div>
              <div class="dd-menu">
                <button ngbDropdownItem (click)="logout()">
                  <fa-icon [icon]="faSignOut" class="h5 mr-2 text-danger"></fa-icon>
                  {{ "nav.logout" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-auto">
      <div class="input-group input-group-lg mb-4">
        <div class="input-group-prepend">
          <span class="input-group-text border-0 bg-white-search rounded-pill-left">
            <fa-icon [icon]="faSearch" class="mr-2"></fa-icon>
          </span>
        </div>
        <input type="text" [(ngModel)]="search" (keyup)="searchInput($event)"
        class="border-0 rounded-pill-right searchInp" placeholder="Buscar en Rafiki">
      </div>
    </div>
  </div>
</div>

<ng-template #modalConfirm let-modal>
  <div class="modal-header">
      <span class="font-weight-bold text-center w-100">Información</span>
  </div>
  <div class="modal-body rounded">
      <div class="text-sm text-center">
        <p class="m-0">Para crear rifas necesitas subir los documentos de acreditación</p>
        <p>"Los cuales se encuentran al final del perfil".</p>
      </div>
      <div class="row mt-4 justify-content-center">
        <div class="col-12 mb-3">
          <button class="btn btn-success btn-block rounded font-weight-bold" (click)="goToProfile(modal)">Ir al perfil</button>
        </div>
        <div class="col-12">
            <button class="btn btn-outline-sec btn-block rounded font-weight-bold" (click)="modal.dismiss('')">Cancelar</button>
        </div>
      </div>
  </div>
</ng-template>
