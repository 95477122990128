import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth-module/auth.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { AlertifyService } from './shared/alertify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as amplitude from '@amplitude/analytics-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'Rafiki';
    name: string;
    isAdmin: string;
    isLoginAdmin = false;
    getOne = false;
    pathAdmin = false;
    lang = 'es';

    constructor(
        public auth: AuthService,
        private permissions: NgxPermissionsService,
        private alertify: AlertifyService,
        private router: Router,
        private route: ActivatedRoute,
        public modalService: NgbModal,
        public translate: TranslateService,
    ) { }

    ngOnInit() {
        this.auth.permissions.subscribe((permissions) => {
            this.isAdmin = permissions?.find((r) => r == 'Administrador')
            const hasValidRole = permissions?.find((r) => r == 'Rifante' || r == 'Comprador')
            this.isLoginAdmin = false;
            this.pathAdmin = false;

            if (window.location.href.indexOf('admin') > 0 && this.isAdmin) {
                this.pathAdmin = true;
            }

            if (window.location.href.indexOf('admin/login') > 0 && !this.isAdmin) {
                this.isLoginAdmin = true;
            } else {
                // refresh permissions from backend
                if (this.auth.currentUserValue && !hasValidRole && !this.isAdmin && !this.getOne) {
                    this.auth.getPermissions().subscribe((permissions) => {
                        this.getOne = true;
                        this.auth.setPermissions(permissions?.permission);
                    }, (error) => {
                        this.auth.logout();
                    });
                }
            }
        });

        this.translate.setDefaultLang(this.lang);

        amplitude.init(environment.amplitude_api_key, {
          defaultTracking: true,
        });
    }

    changeLanguage(lang) {
        this.lang = lang;
        this.translate.setDefaultLang(lang);
    }

    logout() {
        this.auth.logout();
        this.router.navigate(['/']);
    }
}
