import { Component, OnInit, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from '../../shared/list-service.service';
import { ListComponent } from '../../shared/list/list.component';
import { ActionsCellRenderer } from '../../shared/list/renderer/actions-cell.renderer';
import { ClientUserService } from '../client-user.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../shared/list/locale/es.locale';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-client-user-list',
	templateUrl: './client-user-list.component.html',
	styleUrls: ['./client-user-list.component.css'],
	providers: [ListService, DecimalPipe]
})
export class ClientUserListComponent extends ListComponent implements OnInit {
	@ViewChild('changePasswordModal', {static: false}) changePasswordModal;
	//private gridApi;
	//public gridOptions: any;
	//public gridLocale;
	faUserCircle = faUserCircle;
	selectedUser: any;
	error: boolean;
	password: string;
	confirmPassword: string;
	modalPassword: Promise<void>;

	constructor(
		public userService: ClientUserService,
		public listService: ListService,
		public translateService: TranslateService,
		public modalService: NgbModal,
		public auth: AuthService
	) {
		super(userService, listService);
		this.setObject('admin/client');
	}

	ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		
		this.gridOptions = {
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'client-user.form.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'client-user.form.email', field: 'email', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'client-user.form.phone', field: 'phone', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'category.active',
					field: 'active',
					sortable: true,
					cellRenderer: params => {
						if (params.value) {
							return this.translate.instant('form.active.yes');
						} else {
							return this.translate.instant('form.active.no');
						}
					},
					headerValueGetter: this.localizeHeader.bind(this)
				},
				{
					headerName: 'list.actions',
					headerValueGetter: this.localizeHeader.bind(this),
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'faEye',
								action: (id) => {
									this.show(id);
								}
							},
							{
								icon: 'faPencilAlt',
								action: (id) => {
									this.edit(id);
								}
							}
						]
					}
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}
}
