import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { Router } from '@angular/router';
import { ApplicationService } from './../../admin/application/application.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from './profile.service';
import { FormComponent } from 'src/app/shared/form/form.component';
import { faCheckCircle, faInfoCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends FormComponent implements OnInit {
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
  oldPasswordError: boolean;
  newPasswordError: boolean;
  form: FormGroup;
  formSR: FormGroup;
  formRFC: FormGroup;
  is_edit: boolean = true;
  status: any;
  textStatus: any;
  dataStatus: any;
  urlFront = '';
  urlBack = '';
  urlBankInformation = '';
  urlRfc: any;
  urlPhoto: any;
  sizeImage: string = '220px';
  sizeImage2: string = '150px';
  loading = false;
  profileImage: any;
  idFrontImage: any;
  idBackImage: any;
  bankInformationImage: any;
  rfcImage = '';
  enableBtnGuardar: boolean;
  enableBtnEnviarDocs: boolean;
  photoUser: boolean;
  idApplication: any;
  idUser: any;
  submitted: boolean = false;
  valueRfc = '';
  enableBtnUpdateRfc: boolean;
  noEdit: boolean;
  enableDocRFC: boolean;
  enableDocRaffle: boolean;
  enableTextRaffle: boolean;
  public faCheckCircle = faCheckCircle;
  public faInfoCircle = faInfoCircle;
  public faExclamationCircle = faExclamationCircle;
  nameAndLastName: any;

  constructor(
    public auth: AuthService,
    public modalService: NgbModal,
    public alertify: AlertifyService,
    public router: Router,
    private fb: FormBuilder,
    public applicationService: ApplicationService,
    public profileService: ProfileService
  ) {
    super(profileService);
  }

  createForm() {
    this.form = this.fb.group({
      name: [{ value: '', disabled: this.is_edit }, Validators.required],
      last_name: [{ value: '', disabled: this.is_edit }, Validators.required],
      nickname: [{ value: '', disabled: this.is_edit }, Validators.required],
      code_area: [{ value: '', disabled: this.is_edit }, Validators.required],
      phone: [{ value: '', disabled: this.is_edit }, Validators.required],
      email: [{ value: '', disabled: this.is_edit }, Validators.required]
    });

    this.formSR = this.fb.group({
      inputStatus: [{ value: '', disabled: this.is_edit }],
      inputMotive: [{ value: '', disabled: this.is_edit }]
    });

    this.formRFC = this.fb.group({
      rfc: [{ value: '', disabled: this.is_edit }]
    });

    this.profileService.getProfile().subscribe(
      (data) => {
        this.form.controls.name.setValue(data.name);
        this.form.controls.last_name.setValue(data.last_name);
        this.form.controls.nickname.setValue(data.nickname);
        this.form.controls.code_area.setValue(data.code_area);
        this.form.controls.phone.setValue(data.phone);
        this.form.controls.email.setValue(data.email);
        this.urlPhoto = (data.url_photo == null ? 'assets/images/icon-add-photo@3x.png' : data.url_photo);
        this.idUser = data.id;
        this.nameAndLastName = data.name + ' ' + data.last_name;
      });

    this.photoUser = true;
  }

  ngOnInit() {
    this.createForm();
    this.enableComponentDocuments();
  }

  get f() {
    return this.form.controls;
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.cleanModal();
    }, (reason) => {
      this.cleanModal();
    });
  }

  infoRFC() {
    this.alertify.message('<b> Importante </b> <br> Al ingresar tu RFC la retención de tus impuestos será solamente del 1%, si no ingresas el RFC se tendrá que retener el 20%');
  }

  validModal() {
    if (!this.oldPassword || this.oldPassword.length <= 0) {
      this.oldPasswordError = true;
    } else {
      this.oldPasswordError = false;
    }

    if (!this.newPassword || !this.confirmPassword || (this.newPassword != this.confirmPassword)) {
      this.newPasswordError = true;
    } else {
      this.newPasswordError = false;
    }

    if (this.newPasswordError || this.oldPasswordError) {
      return false;
    }
    return true;
  }

  changePassword() {
    if (!this.validModal()) {
      return;
    }
    this.auth.changePasswordByUser(this.oldPassword, this.newPassword)
      .subscribe(
        (response) => {
          this.modalService.dismissAll();
          this.alertify.success("Se cambio la contraseña del usuario correctamente");
          this.auth.logout();
          this.router.navigate(['/']);
        },
        (error) => {
          this.alertify.error(error || "Ocurrió un error");
        }
      );
  };

  cleanModal() {
    this.oldPassword = '';
    this.newPassword = '';
    this.confirmPassword = '';
    this.oldPasswordError = false;
    this.newPasswordError = false;
  }

  editProfile() {
    this.form.controls.name.enable();
    this.form.controls.last_name.enable();
    this.form.controls.nickname.enable();
    this.form.controls.code_area.enable();
    this.form.controls.phone.enable();
    this.enableBtnGuardar = true;
    this.photoUser = false;
    this.noEdit = true;
    this.rfc.enable();
    this.enableDocRFC = true;
    this.enableDocRaffle = true;

    if (this.urlFront !== '' && this.urlBack !== '' && this.urlBankInformation !== '') {
      this.enableTextRaffle = false;
      if (this.valueRfc !== '' && this.urlRfc !== '') {
        this.enableBtnUpdateRfc = true;
        this.valueRfc = '';
        this.rfcImage = '';
        this.enableBtnEnviarDocs = false;
      } else {
        this.enableBtnUpdateRfc = false;
        this.valueRfc;
        this.enableBtnEnviarDocs = true;
      }
    } else {
      this.enableTextRaffle = true;
      this.enableBtnEnviarDocs = true;
    }

    if (this.status == 0) {
      this.enableBtnEnviarDocs = true;
      this.enableBtnUpdateRfc = false;
    }
  }

  noEditProfile() {
    this.form.controls.name.disable();
    this.form.controls.last_name.disable();
    this.form.controls.nickname.disable();
    this.form.controls.code_area.disable();
    this.form.controls.phone.disable();
    this.enableBtnGuardar = false;
    this.photoUser = true;
    this.noEdit = false;
    this.rfc.disable();
    this.enableDocRFC = false;
    this.enableDocRaffle = false;
    if (this.urlFront !== undefined && this.urlBack !== undefined && this.urlBankInformation !== undefined) {
      this.enableTextRaffle = false;
      this.enableBtnEnviarDocs = false;
    } else {
      this.enableTextRaffle = true;
      this.enableBtnEnviarDocs = false;
    }

    if (this.valueRfc !== '' && this.urlRfc !== '') {
      this.enableBtnUpdateRfc = true;
      this.valueRfc = '';
    } else {
      this.enableBtnUpdateRfc = false;
      this.valueRfc = this.rfc.value;
    }

    if (this.status == 0) {
      this.enableTextRaffle = true;
    }
  }

  enableComponentDocuments() {
    this.applicationService.getStatus().subscribe(
      (response) => {
        const data = response[0];
        this.dataStatus = data;
        if (this.dataStatus == undefined) {
          this.enableTextRaffle = true;
        }
        if (data != undefined) {
          this.idUser = data.user_id;
          this.idApplication = data.id;
          this.urlFront = data.url_id_front;
          this.urlBack = data.url_id_back;
          this.urlBankInformation = data.url_bank_information;
          this.status = data.approval;
          this.textStatus = (this.status == 1 ? 'Aprobado' : this.status == 0 ? 'Rechazado' : this.status == null ? 'Pendiente' : '');
          this.formSR.controls.inputStatus.setValue(this.textStatus);
          this.formSR.controls.inputMotive.setValue(data.rejection_reason);
          this.formRFC.controls.rfc.setValue(data.rfc);
          this.valueRfc = this.formRFC.controls.rfc.value;
          this.urlRfc = data.url_rfc_documentation;

          if (this.urlFront !== '' && this.urlBack !== '' && this.urlBankInformation !== '') {
            this.enableTextRaffle = false;
            this.enableBtnEnviarDocs = false;
          } else {
            this.enableTextRaffle = true;
            this.enableBtnEnviarDocs = true;
          }

          if (this.status == 0) {
            this.enableTextRaffle = true;
          }
        }
      },
      (error) => {
        this.alertify.error(error || "Ocurrió un error");
      }
    )
  }

  changePhotoUser(event: any): void {
    this.profileImage = event.target.files[0];
  }

  savePhotoUser() {
    try {
      const formData: FormData = new FormData();
      formData.append('file', this.profileImage);
      this.loading = true;
      this.profileService.profile(formData).subscribe((res) => {
        this.loading = false;
        this.urlPhoto = res.url_photo;
        this.noEditProfile();
        this.alertify.success(this.translate.instant('general.form.success_save'));
      });
    } catch (error) {
      this.loading = false;
      this.alertify.error('Ocurrió un error al subir la imagen al servidor');
    }
  }

  saveProfile() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    } else {
      if (this.profileImage != undefined) {
        this.savePhotoUser();
      } else {
        this.loading = true;
        const data = this.form.value;
        this.profileService.profile(data).subscribe(
          (response) => {
            this.loading = false;
            this.noEditProfile();
            this.photoUser = true;
            this.nameAndLastName = response.name + ' ' + response.last_name;
            this.alertify.success(this.translate.instant('general.form.success_save'));
          });
      }
    }
  }

  changePhotoIdFront(event: any): void {
    this.idFrontImage = event.target.files[0];
  }

  changePhotoIdBack(event: any): void {
    this.idBackImage = event.target.files[0];
  }

  changePhotoBank(event: any): void {
    this.bankInformationImage = event.target.files[0];
  }

  changePhotoRfc(event: any): void {
    this.rfcImage = event.target.files[0];
  }

  saveDocuments() {
    const formData: FormData = new FormData();
    const txtRfc: any = this.formRFC.controls.rfc.value;
    let hasRFC: any = txtRfc === '' ? 0 : 1;

    switch (hasRFC) {
      case 0:
        if (this.idFrontImage == undefined || this.idBackImage == undefined) {
          this.alertify.error('Por favor, agrega tu identificación por ambos lados.');
        } else if (this.bankInformationImage == undefined) {
          this.alertify.error('Por favor, agrega tu carátula de Banco');
        } else {
          try {
            formData.append('id_front_file', this.idFrontImage);
            formData.append('id_back_file', this.idBackImage);
            formData.append('bank_information_file', this.bankInformationImage);
            formData.append('id', this.idUser);
            this.loading = true;
            this.applicationService.store(formData).subscribe((res) => {
              this.loading = false;
              this.enableComponentDocuments();
              this.noEditProfile();
              this.enableTextRaffle = false;
              this.alertify.success(this.translate.instant('general.form.success_save'));
            }, (error) => {
              this.loading = false;
            });
          } catch (error) {
            this.loading = false;
            this.alertify.error('Ocurrió un error al subir la imagen al servidor');
          }
        }
        break;
      case 1:
        if (this.idFrontImage == undefined || this.idBackImage == undefined) {
          this.alertify.error('Por favor, agrega tu identificación por ambos lados.');
        } else if (this.bankInformationImage == undefined) {
          this.alertify.error('Por favor, agrega tu carátula de Banco');
        } else if (txtRfc === '') {
          this.alertify.error('Por favor, agrega un RFC válido');
        } else if (this.rfcImage == '') {
          this.alertify.error('Por favor, agrega la documentación RFC');
        } else {
          try {
            formData.append('id_front_file', this.idFrontImage);
            formData.append('id_back_file', this.idBackImage);
            formData.append('bank_information_file', this.bankInformationImage);
            formData.append('url_rfc_documentation', this.rfcImage);
            formData.append('rfc', txtRfc);
            formData.append('id', this.idUser);
            this.loading = true;
            this.applicationService.store(formData).subscribe((res) => {
              this.loading = false;
              this.enableComponentDocuments();
              this.noEditProfile();
              this.enableTextRaffle = false;
              this.alertify.success(this.translate.instant('general.form.success_save'));
            }, (error) => {
              this.loading = false;
            });
          } catch (error) {
            this.loading = false;
            this.alertify.error('Ocurrió un error al subir la imagen al servidor');
          }
        }
        break;
    }
  }

  updateRFC() {
    const formData: FormData = new FormData();
    const txtRfc: any = this.formRFC.controls.rfc.value;

    if (txtRfc == null) {
      this.alertify.error('Por favor, agrega un RFC válido');
    } else if (this.rfcImage == '') {
      this.alertify.error('Por favor, agrega la documentación RFC');
    } else {
      try {
        formData.append('url_rfc_documentation', this.rfcImage);
        formData.append('rfc', txtRfc);
        formData.append('id', this.idApplication);
        this.loading = true;
        this.applicationService.updateRFC(formData, this.idApplication).subscribe((res) => {
          this.loading = false;
          this.enableComponentDocuments();
          this.noEditProfile();
          this.alertify.success(this.translate.instant('general.form.success_save'));
        }, (error) => {
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.alertify.error('Ocurrió un error al subir la imagen al servidor');
      }
    }
  }

  get rfc() {
    return this.formRFC.get('rfc');
  }
}
