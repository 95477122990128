import { AuthService } from './../../auth-module/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loginpopup',
  templateUrl: './loginpopup.component.html',
  styleUrls: ['./loginpopup.component.css']
})
export class LoginpopupComponent implements OnInit {

  @ViewChild('loginModal') loginModal:ElementRef;

  modal;
  resendEmail;
  resendId;
  redirectUrl = '';

  constructor(
    public modalService: NgbModal,
    public auth: AuthService,
    ) { }

  ngOnInit(): void {
  }

  loginOpen(redirectUrl = ''){
    this.redirectUrl = redirectUrl;
    this.modal = this.modalService.open(this.loginModal, 
      {ariaLabelledBy: 'modal-basic-title', windowClass : "modalClass"}).result.then((result) => {
    }, (reason) => {
    });
  }

  locationUrl(id){
    return 'location/' + id + '/categories';
  }

  openRegister(content){    
    this.modal = this.modalService.open(content, 
      {ariaLabelledBy: 'modal-basic-title', windowClass : "modalClassReg"}).result.then((result) => {
    }, (reason) => {
    });
  }

  resendEvent(content, data){
    this.resendEmail = data.email;
    this.resendId = data.id;
    this.modal = this.modalService.open(content, 
      {ariaLabelledBy: 'modal-basic-title', windowClass : "modalClassReg"}).result.then((result) => {
    }, (reason) => {
    });
  }

  forgetPassword(content, $event){
    this.modalService.dismissAll();

    this.modal = this.modalService.open(content, 
      {ariaLabelledBy: 'modal-basic-title', windowClass : "modalClassReg"}).result.then((result) => {
    }, (reason) => {
    });
  }
}
