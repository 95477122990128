<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faTimesCircle" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate
				}}</a>
		</li>
		<li class="breadcrumb-item"><a routerLink="/raffle-report" class="text-primary-color-3">{{ "admin.reports" |
				translate }}</a>
		</li>
		<li class="breadcrumb-item active" aria-current="page">{{ "cancel_report.view" | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-12">
        <div class="bg-form shadow">
            <h2>{{ "cancel_report.label" | translate }}: </h2>
            <div>
                <p>
                    <label for=""> <strong>{{ "cancel_report.raffle" | translate }}:</strong> {{ data?.raffle?.name }}     </label>
                </p>
                <p>
                    <label for=""> <strong>{{ "cancel_report.scheduled_at" | translate }}:</strong> {{ data?.raffle?.finish }}     </label>
                </p>
                <p>
                    <label for=""> <strong>{{ "cancel_report.raffler" | translate }}:</strong> {{ data?.raffle?.raffler?.name }}     </label>
                </p>
                <p>
                    <label for=""> <strong>{{ "cancel_report.total_tickets" | translate }} / {{ "cancel_report.sold_tickets" | translate }}:</strong> {{ data?.raffle?.num_tickets | number }}  / {{ data?.raffle?.sold_tickets }}    </label>
                </p> 
                <p>
                    <label for=""> <strong>{{ "cancel_report.ticket_price" | translate }}:</strong> ${{ data?.raffle?.ticket_price }}     </label>
                </p>
                <p>
                    <label for=""> <strong>{{ "cancel_report.total_orders" | translate }}:</strong> {{ data?.orders?.length }}     </label>
                </p>
                <p>
                    <label for=""> <strong>{{ "cancel_report.commission" | translate }}:</strong> ${{ data?.commission }}     </label>
                </p>
            </div>
            <div *ngIf="data && data.conekta_orders.length > 0">
                <h3>{{ "cancel_report.conekta_orders" | translate }}:</h3>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{{ "cancel_report.num_tickets" | translate}}</th>
                              <th scope="col">{{ "cancel_report.buy_tickets" | translate}}</th>
                              <th scope="col">{{ "cancel_report.amount" | translate}}</th>
                              <th scope="col">{{ "cancel_report.commission" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of data?.conekta_orders; let i = index;">
                              <td >{{ i + 1 }}</td>
                              <td>{{ order.num_tickets }}</td>
                              <td> [ {{ order.tickets_formatted }} ]</td>
                              <td>{{ order.payment_total }}</td>
                              <td>${{ order.commission }}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td> <strong>Total</strong></td>
                                <td><strong>${{ data?.commission }}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="data && data.rafiki_orders.length > 0">
                <h3>{{ "cancel_report.rafiki_orders" | translate }}:</h3>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">{{ "cancel_report.num_tickets" | translate}}</th>
                              <th scope="col">{{ "cancel_report.buy_tickets" | translate}}</th>
                              <th scope="col">{{ "cancel_report.amount" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let order of data?.rafiki_orders; let i = index;">
                              <td >{{ i + 1 }}</td>
                              <td>{{ order.num_tickets }}</td>
                              <td> [ {{ order.tickets_formatted }} ]</td>
                              <td>{{ order.payment_total }}</td>
                            </tr>
                            
                          </tbody>
                    </table>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button class="btn btn-danger mr-3" routerLink="/cancel-report">{{ "cancel_report.back" | translate}}</button>
            </div>
        </div>

	</div>
</div>