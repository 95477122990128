import { Component, OnInit, Injector } from '@angular/core';
import { ListService } from '../list-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertifyService } from '../alertify.service';
import { CrudService } from '../crud.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { faEye, faTrash, faPencilAlt, faKey, faPlus } from '@fortawesome/free-solid-svg-icons';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app-injector.service';
import { ICellRendererParams } from 'ag-grid-community';
import { AG_GRID_LOCALE_EN } from '../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../shared/list/locale/es.locale';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class ListComponent implements OnInit {
  protected gridApi;
  public gridOptions: any;
  public gridLocale;

  data$: Observable<any[]>;
  total$: Observable<number>;
  data: any[];
  object: string;
  confirmationDeleteMessage: string;
  confirmDeleteMessage: string;
  faEye = faEye;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faKey = faKey;
  faPlus = faPlus;
  cookieListName = '';
  cookiePageNumber = '';
  cookiePaginationActive = '';

  protected translate: TranslateService;
  public alertify: AlertifyService;
  public route: ActivatedRoute;
  public router: Router;
  public cookies: CookieService;

  constructor(
    public service: CrudService,
    public listService: ListService
  ) {
    const injector = AppInjector.getInjector();
    this.translate = injector.get(TranslateService);
    this.route = injector.get(ActivatedRoute);
    this.alertify = injector.get(AlertifyService);
    this.router = injector.get(Router);
    this.cookies = injector.get(CookieService);

    this.data$ = listService.data$;
    this.total$ = listService.total$;
    this.translate.get('general.list.confirm_question_delete').subscribe((response) => {
      this.setConfirmDeleteMessage(this.translate.instant('general.list.confirmation_message'));
      this.setConfirmationDeleteMessage(this.translate.instant('general.list.confirm_question_delete'));
    });
  }

  ngOnInit() {
    //this.loadData();
    this.translate.onDefaultLangChange.subscribe((event: LangChangeEvent) => {
      if (this.gridApi) {
        if (event.lang == 'es') {
          this.gridLocale = AG_GRID_LOCALE_ES;
          this.gridApi.gridCore.gridOptions.localeText = AG_GRID_LOCALE_ES;
          this.gridApi.gridOptionsWrapper.gridOptions.localeText = AG_GRID_LOCALE_ES;
          if (this.gridApi.gridApi) {
            this.gridApi.gridApi.gridPanel.gridOptions.localeText = AG_GRID_LOCALE_ES;
          }
        } else if (event.lang == 'en') {
          this.gridLocale = AG_GRID_LOCALE_EN;

          this.gridApi.gridCore.gridOptions.localeText = AG_GRID_LOCALE_EN;
          this.gridApi.gridCore.gridApi.refreshHeader();
          this.gridApi.gridCore.gridApi.redrawRows();
          this.gridApi.gridCore.gridApi.init();

          this.gridApi.gridOptionsWrapper.gridOptions.localeText = AG_GRID_LOCALE_EN;
          this.gridApi.gridOptionsWrapper.gridOptions.api.redrawRows();
          this.gridApi.gridOptionsWrapper.gridOptions.api.init();
          this.gridApi.gridOptionsWrapper.gridOptions.api.refreshHeader();

          if (this.gridApi.gridApi) {
            this.gridApi.gridApi.gridCore.gridOptions.localeText = AG_GRID_LOCALE_EN;
            this.gridApi.gridApi.refreshHeader();
            this.gridApi.gridApi.redrawRows();
            this.gridApi.gridApi.init();
          }
        }

        this.gridApi.refreshHeader();
        this.gridApi.redrawRows();
        this.gridApi.init();
      }
    });
  }

  show(id) {
    this.router.navigate(['/' + this.object + '/view/' + id]);
  }

  edit(id) {
    this.router.navigate(['/' + this.object + '/edit/' + id]);
  }

  delete(id) {
    this.alertify.confirm(this.translate.instant('general.list.delete'), this.confirmationDeleteMessage, () => this.deleteItem(id));
  }

  deleteItem(id) {
    this.service.delete(id).subscribe((user) => {
      this.alertify.success(this.confirmDeleteMessage);
      this.loadData();
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));
    });
  }

  loadData() {
    this.service.all().subscribe((data) => {
      this.data = data;
      this.listService.setData(data);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.list.load_error'));
    });
  }

  public setConfirmDeleteMessage(message: string) {
    this.confirmDeleteMessage = message;
  }

  public setConfirmationDeleteMessage(message: string) {
    this.confirmationDeleteMessage = message;
  }

  public setObject(object: string) {
    this.object = object;
  }

  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }
}
