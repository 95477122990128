import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


declare let Conekta: any;

@Injectable({
  providedIn: 'root'
})
export class ConektaService {

  constructor() { 
    Conekta.setPublicKey(environment.conekta_public_key);
  }

  setPublicKey(key){
    return Conekta.setPublicKey(key);
  }

  getPublicKey(){
    return Conekta.getPublicKey();
  }

  setLanguage(key){
    return Conekta.setLanguage(key);
  }

  getLanguage(){
    return Conekta.getLanguage();
  }

  createToken(params, context, successCallback: (response,params,context) => any, errorCallback: (response,context) => any){
    return Conekta.Token.create(params, function(e) {
      if (e) {
        successCallback(e,params,context);
      } 
  }, function(e){
    errorCallback(e,context)
  });
  }

  validateNumber(number):boolean{
    return Conekta.card.validateNumber(number);
  }

  validateExpirationDate(month,year):boolean{
    return Conekta.card.validateExpirationDate(month,year);
  }

  validateCVC(cvc):boolean{
    return Conekta.card.validateCVC(cvc);
  }

  getBrand(number):string{
    return Conekta.card.getBrand(number);
  }
}