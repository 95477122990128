import { Component, OnInit } from '@angular/core';
import * as amplitude from "@amplitude/analytics-browser";

@Component({
  selector: 'app-user-footer',
  templateUrl: './user-footer.component.html',
  styleUrls: ['./user-footer.component.css']
})
export class UserFooterComponent implements OnInit {
  year = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

  visitFAQ(){
    amplitude.track('home_faq');
  }

  emailSupport(){
    amplitude.track('home_email-support');
  }

  visitFacebook(){
    amplitude.track('home_facebook');
  }

  visitInstagram(){
    amplitude.track('home_instagram');
  }

  visitTwitter(){
    amplitude.track('home_twitter');
  }

  visitLinkedIn(){
    amplitude.track('home_linkedin');
  }

  visitWhatsApp(){
    amplitude.track('home_whatsapp');
  }
}
