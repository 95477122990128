import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { ListService } from '../../../shared/list-service.service';
import { ListComponent } from '../../../shared/list/list.component';
import { ActionsCellRenderer } from '../../../shared/list/renderer/actions-cell.renderer';
import { TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_EN } from '../../../shared/list/locale/en.locale';
import { AG_GRID_LOCALE_ES } from '../../../shared/list/locale/es.locale';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CancelReportService } from '../cancel-report.service';

@Component({
  selector: 'app-cancel-report-list',
  templateUrl: './cancel-report-list.component.html',
  styleUrls: ['./cancel-report-list.component.css'],
	providers: [ListService, DecimalPipe]
})
export class CancelReportListComponent extends ListComponent implements OnInit {


  faTimesCircle = faTimesCircle;
  constructor(
		public service: CancelReportService,
		public listService: ListService,
		public translateService: TranslateService
	) {
		super(service, listService);
		this.setObject('cancel-report');
	}

  ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			columnDefs: [
				{ headerName: 'cancel_report.raffle', field: 'raffle.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'cancel_report.raffler', field: 'user.name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'cancel_report.reason', field: 'cancel_comment', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'cancel_report.charge', field: 'total_formatted', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'cancel_report.scheduled_at', field: 'raffle.finish', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
					headerName: 'list.actions',
					field: 'id',
					cellRenderer: 'actionsCellRenderer',
					cellRendererParams: {
						actions: [
							{
								icon: 'faEye',
								action: (id) => {
									this.show(id);
								}
							}
						]
					},
					headerValueGetter: this.localizeHeader.bind(this)
				}
			],
			frameworkComponents: {
				'actionsCellRenderer': ActionsCellRenderer
			}
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
		this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

}
