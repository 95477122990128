import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleListComponent } from './role-list/role-list.component';
import { AuthGuard } from '../auth-module/auth.guard';
import { RoleFormComponent } from './role-form/role-form.component';

const routes: Routes = [
  {
    path: 'role',
    component: RoleListComponent,
    canActivate: [AuthGuard],
  }, {
    path: 'role/create',
    component: RoleFormComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'role/edit/:id',
    component: RoleFormComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'role/view/:id',
    component: RoleFormComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RoleRoutingModule { }
