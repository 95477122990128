<div class="container" *ngIf="!addressID">
    <div class="row justify-content-center">
        <div class="col-11">
            <form [formGroup]="form">
                <div class="row justify-content-between py-5">
                    <div class="col-auto"></div>
                    <div class="col-auto">
                        <button type="button" routerLink="/my-tickets"
                            class="btn btn-outline-success rounded-pill font-weight-bold">Cancelar</button>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <img src="assets/images/icon-ganador.png" style="height: 70px; width: 70px;">
                    </div>
                </div>
                <div class="row justify-content-center p-2">
                    <div class="col-auto">
                        <p class="text-green text-lg font-weight-bold">¡Felicidades, eres el ganador!</p>
                    </div>
                </div>
                <div class="row justify-content-center p-2">
                    <div class="col-auto">
                        <div class="text-center p-2">
                            <img class="img-fluid img-thumbnail img-raffler" [src]="raffles.image">
                        </div>
                        <div class="text-center">
                            <p> {{ raffles.name }} </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-auto text-center font-weight-bold">
                        <p>Organizado por {{ raffles.raffler?.name }} {{ raffles.raffler?.last_name }}</p>
                        <p>Finalizado el {{ getFormatDate(raffles.finish) }}</p>
                    </div>
                </div>
                <hr class="my-2">
                <div class="row justify-content-center p-3">
                    <div class="col-auto">
                        <p class="text-green text-lg font-weight-bold">Dirección para recibir tus premios</p>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="form-group">
                        <select class="form-control" name="address_id" formControlName="address_id"
                            [ngClass]="{ 'is-invalid': submitted && f.address_id.errors }">
                            <option value="">Seleccione una dirección</option>
                            <option *ngFor="let a of address" value={{a.id}}>{{a.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.address_id.errors" class="invalid-feedback">
                            <div *ngIf="f.address_id.errors.required">{{ "general.errors.required" | translate }}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="loading" class="text-center">
                    <div class="spinner-border" style="width: 3rem; height: 3rem; color: #49A446;" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="row justify-content-center p-5">
                    <div class="col-auto">
                        <button type="submit" class="btn btn-sec rounded-pill font-weight-bold"
                            [disabled]="form.invalid" (click)="updateAddress()">
                            Recibir premio
                        </button>
                    </div>
                </div>
                <hr class="my-4">
                <div class="row justify-content-between">
                    <div class="col-auto p-2">
                        <img src="assets/images/icon-ganador.png" style="height: 40px; width: 40px;">
                    </div>
                    <div class="col p-3">
                        <p class="text-sm font-weight-bold">Ayuda con el premio</p>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <p class="font-weight-bold">Problemas con el organizador</p>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <p class="font-weight-bold">Comunícate con nosotros</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<!---->

<div class="container" *ngIf="addressID">
    <div class="row justify-content-center">
        <div class="col-11">
            <form [formGroup]="form">
                <div class="row justify-content-between py-5">
                    <div class="col-auto"></div>
                    <div class="col-auto">
                        <button type="button" routerLink="/my-tickets"
                            class="btn btn-outline-success rounded-pill font-weight-bold">Cancelar</button>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <img src="assets/images/icon-ganador.png" style="height: 70px; width: 70px;">
                    </div>
                </div>
                <div class="row justify-content-center p-2">
                    <div class="col-auto">
                        <p class="text-green text-lg font-weight-bold">¡Felicidades, eres el ganador!</p>
                    </div>
                </div>
                <div class="row justify-content-center p-2">
                    <div class="col-auto">
                        <div class="text-center p-2">
                            <img class="img-fluid img-thumbnail" [src]="raffles.image">
                        </div>
                        <div class="text-center">
                            <p> {{ raffles.name }} </p>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-auto text-center font-weight-bold">
                        <p>Organizado por {{ raffles.raffler?.name }} {{ raffles.raffler?.last_name }}</p>
                        <p>Finalizado el {{ getFormatDate(raffles.finish) }}</p>
                    </div>
                </div>
                <hr class="my-2">
                <div class="row justify-content-center p-2" *ngIf="enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showInstructions(contentInstructions)">Mostrar instrucciones</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showRedeemCode(contentRedeemCode)">Mostrar código de canjeo</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showExternalLink(contentExternalLink)">Mostrar link externo</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showUrlInstructions(contentUrlInstructions)">Mostrar archivo</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="enableProductType">
                    <div class="col-auto">
                        <a class="btn rounded-pill font-weight-bold create p-2" [href]="downloadUrlInstructions()" target="_blank" style="height: 40px;">
                            Descargar archivo
                        </a>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="!enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showDeliveryCompany(contentDeliveryCompany)">Mostrar paquetería</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="!enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showTrackId(contentTrackId)">Mostrar clave de rastreo</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="!enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showTrackUrl(contentTrackUrl)">Mostrar enlace para rastreo</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="!enableProductType">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                            (click)="showDeliveryVoucherUrl(contentDeliveryVoucherUrl)">Mostrar comprobante</button>
                    </div>
                </div>
                <div class="row justify-content-center p-2" *ngIf="!enableProductType">
                    <div class="col-auto">
                        <a class="btn rounded-pill font-weight-bold create p-2" [href]="downloadVourcher()" target="_blank" style="height: 40px;">
                            Descargar comprobante
                        </a>
                    </div>
                </div>
                <div *ngIf="receivedPrize === 0" class="row justify-content-center p-2">
                    <div class="col-auto">
                        <button class="btn rounded-pill font-weight-bold create"
                        (click)="updatePrizeStatus()">Marcar como recibido</button>
                    </div>
                </div>
                <div *ngIf="receivedPrize !== 0" class="row justify-content-center p-2">
                    <div class="col-auto">
                        <p class="text-green text-lg font-weight-bold">Premio recibido</p>
                    </div>
                </div>
                <hr>
                <div class="row justify-content-between p-3">
                    <div class="col-auto p-2">
                        <img src="assets/images/icon-ganador.png" style="height: 40px; width: 40px;">
                    </div>
                    <div class="col p-3">
                        <p class="text-sm font-weight-bold">Ayuda con el premio</p>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <p class="font-weight-bold">Problemas con el organizador</p>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <p class="font-weight-bold">Comunícate con nosotros</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<ng-template #contentDeliveryCompany let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showDeliveryCompany">Paquetería</h4>
    </div>
    <div class="modal-body">
        <p>{{ raffles?.raffle_winner?.delivery_company }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" |
            translate}}</button>
    </div>
</ng-template>

<ng-template #contentTrackId let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showTrackId">Clave de rastreo</h4>
    </div>
    <div class="modal-body">
        <p>{{ raffles?.raffle_winner?.track_id }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" |
            translate}}</button>
    </div>
</ng-template>

<ng-template #contentTrackUrl let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showTrackUrl">Link directo para rastreo</h4>
    </div>
    <div class="modal-body">
        <p>{{ raffles?.raffle_winner?.track_url }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" |
            translate}}</button>
    </div>
</ng-template>

<ng-template #contentDeliveryVoucherUrl let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showDeliveryVoucherUrl">Comprobante</h4>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-4">
                    <div class="card text-center size-card2" style="width: 27rem;">
                        <img src="{{ raffles?.raffle_winner?.delivery_voucher_url }}" [style.height]="sizeImage2"
                            class="card-img-top">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" |
            translate}}</button>
    </div>
</ng-template>

<!--Premio digital-->
<ng-template #contentInstructions let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showInstructions">Instrucciones</h4>
    </div>
    <div class="modal-body">
        <p>{{ raffles?.digital_prize?.instructions }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate}}</button>
    </div>
</ng-template>

<ng-template #contentRedeemCode let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showRedeemCode">Código de canjeo</h4>
    </div>
    <div class="modal-body">
        <p>{{ raffles?.digital_prize?.redeem_code }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate}}</button>
    </div>
</ng-template>

<ng-template #contentExternalLink let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showExternalLink">Link externo</h4>
    </div>
    <div class="modal-body">
        <p>{{ raffles?.digital_prize?.external_link }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate}}</button>
    </div>
</ng-template>

<ng-template #contentUrlInstructions let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="showUrlInstructions">Archivo</h4>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-4">
                    <div class="card text-center size-card2" style="width: 27rem;">
                        <img src="{{ raffles?.digital_prize?.url_instructions }}" [style.height]="sizeImage2"
                            class="card-img-top">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn create-outline rounded-pill font-weight-bold" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate}}</button>
    </div>
</ng-template>