import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

import { UserRoutingModule } from './user-routing.module';
import { AdminUserFormComponent } from './admin-user-form/admin-user-form.component';
import { ClientUserFormComponent } from './client-user-form/client-user-form.component';
import { AdminUserListComponent } from './admin-user-list/admin-user-list.component';
import { ClientUserListComponent } from './client-user-list/client-user-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { CreateCardComponent } from './create-card/create-card.component';
import { CardListComponent } from './card-list/card-list.component';

@NgModule({
	declarations: [
		ClientUserListComponent,
		AdminUserFormComponent,
		AdminUserListComponent,
		ClientUserFormComponent,
		CreateCardComponent,
		CardListComponent,
	],
	imports: [
		CommonModule,
		UserRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		NgbModule,
		FontAwesomeModule,
		TranslateModule,
		AgGridModule.withComponents([]),
		NgxLoadingModule
	],
	exports:[CreateCardComponent]
})
export class UserModule { }
