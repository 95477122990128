import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../shared/crud.service';

@Injectable({
	providedIn: 'root'
})
export class AdminUserService extends CrudService {
	constructor(protected http: HttpClient) {
		super(http);
		this.setObject('admin-user');
	}
}
