<div class="row height-100">
    <div class="col bg-white">
        <div class="row justify-content-center align-items-center">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="mt-5">
                  <div>
                      <!-- <img src="assets/images/usuarios.png" width="250px"> -->
                  </div>
                  <div>
                  <h2 class="text-primary-color-3">
                      <!-- <img class="pr-2" src="assets/images/icon_titulo.png" width="60px"> -->
                      Iniciar Sesión</h2>
                  </div>
                <div class="form-group">
                    <div>
                      <label for=""><strong>Correo electrónico:</strong></label>
                      <input type="text" formControlName="username" placeholder="Correo Electrónico" class="form-control login-input" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    </div>
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">El campo correo electrónico es requerido.</div>
                    </div>
                </div>
                <div class="form-group">
                    <div>
                      <label for=""><strong>Contraseña:</strong></label>
                      <input type="password" formControlName="password" placeholder="Contraseña" class="form-control login-input" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                    </div>
                    
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">El campo contraseña es requerido.</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-left">
                      <div class="form-group">
                        <button class="btn btn-primary" type="submit" [disabled]="loading">
                          <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                          Iniciar Sesión
                        </button>
                        <!--a class="ml-2" routerLink="/register">Registrarse</a-->  
                      </div>
                    </div>
                </div>
                <!--div class="row">
                  <div class="col text-left">
                    <div class="form-group">
                      <button class="btn btn-primary" type="button" (click)="signInWithFB(nicknameModal)" [disabled]="loading">
                        <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        Iniciar Sesión con facebook
                      </button>
                    </div>
                  </div>
              </div-->
            </form>
        </div>
    </div>
  </div>
  
  <ng-template #nicknameModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"> Correo electrónico repetido</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="name">El correo electrónico ligado a esta cuenta de facebook esta ligado a otro metodo de acceso.</label>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate }}</button>
      </div>
    </ng-template>