import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminUserService } from '../admin-user.service';
import { RoleService } from '../../role-module/role.service';
import { CatalogService } from 'src/app/shared/catalog.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { FormComponent } from 'src/app/shared/form/form.component';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';


@Component({
	selector: 'app-client-user-form',
	templateUrl: './client-user-form.component.html',
	styleUrls: ['./client-user-form.component.css']
})
export class ClientUserFormComponent extends FormComponent implements OnInit {
	roles: any;
	areas: any;
	typeTests: any;
	events: any;
	selectedRole: any;
	permissionCapture = false;
	selectedEvents = [];
	selectedTests = [];
	faUserCircle = faUserCircle;

	currentUser = this.authenticationService.currentUserValue;
	constructor(
		public userService: AdminUserService,
		public roleService: RoleService,
		public catalogService: CatalogService,
		private authenticationService: AuthService) {
		super(userService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.redirect = '/admin/client';
		if (this.create()) {
			this.form = this.formBuilder.group({
				name: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				password: ['', Validators.required],
				confirmPassword: ['', Validators.required],
				active: ['1']
			});
		} else {
			this.form = this.formBuilder.group({
				name: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				active: []
			});
		}
	}

	dataToForm(data) {
		return {
			name: data.name,
			email: data.email,
			active: '' + data.active
		};
	}

	formToData() {
		this.data = {
			name: this.f.name.value,
			email: this.f.email.value,
			active: this.f.active.value,
			password: this.f.password ? this.f.password.value : null
		};
	}
}