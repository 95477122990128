<div class="bodycards">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-auto">
                <h2 class="text-capitalize text-orange">{{ "nav.notifications" | translate }}</h2>
            </div>
        </div>
        <div *ngIf="totalNotifications == 0" class="text-center font-weight-bold green-color text-lg">
            {{ "general.result_not_found" | translate }}
        </div>
        <div class="container" *ngIf="notifications && notifications.length > 0">
            <div class="card mb-3" *ngFor="let noti of notifications">
                <div class="row no-gutters">
                    <div class="col-md-2">
                        <div class="text-center p-5">
                            <fa-icon [icon]="faBell" class="h1 text-warning"></fa-icon>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="card-body">
                            <h5 class="card-title">{{ noti.title }}</h5>
                            <p class="card-text">{{ noti.message }}</p>
                            <p class="card-text">{{ getFormatDate(noti.updated_at) }}</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="text-center p-5">
                            <button type="button" class="btn" (click)="readNotification(noti.id)">
                                <fa-icon [icon]="faTrashAlt" class="h2 text-danger"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>