import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from '../register.service';
import { AppInjector } from '../../app-injector.service';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  public router: Router;
  protected injector;

  constructor(private route: ActivatedRoute,
    public regService: RegisterService) {
    const injector = AppInjector.getInjector();
    this.router = injector.get(Router);
  }

  ngOnInit() {
    let redirect = '/';
    let redirectSucces = '/confirmation-email';
    let qParams = this.route.snapshot.queryParamMap;
    let params = 'expires=' + qParams.get('expires') + '&signature=' + qParams.get('signature');
    this.regService.verify(qParams.get('id'), qParams.get('hash'), params).subscribe((response) => {
      if (response) {
        this.router.navigate([redirectSucces]);
      } else {
        this.router.navigate([redirect], { queryParams: { verify: 'false', id: qParams.get('id') } });
      }
    }, (error) => {
      this.router.navigate([redirect], { queryParams: { verify: 'false', id: qParams.get('id') } });
    });
  }
}
