import { RegisterService } from './../register.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { TranslateService } from '@ngx-translate/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SocialAuthService, FacebookLoginProvider } from "angularx-social-login";
import { environment } from 'src/environments/environment';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.css']
})
export class CustomerLoginComponent implements OnInit {
  returnUrl: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  clientLogin = false;

  loggedIn: boolean;
  user: any;

  faArrow = faLongArrowAltRight;
  @Input('modal') public loginModal;
  @Input('redirect') public redirect = '';
  @Output('forgetPassword') public forgetPassword = new EventEmitter();

  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public alertify: AlertifyService,
    public translate: TranslateService,
    private socialService: SocialAuthService,
    private registerService: RegisterService
  ) {
    //this.clientLogin = environment.client_login;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    if (this.redirect == '') {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/raffle';
    } else {
      this.returnUrl = this.redirect;
    }

    if (this.route.snapshot.queryParams.hasOwnProperty('verify')) {
      if (this.route.snapshot.queryParams['verify'] == 'true') {
        this.alertify.success(this.translate.instant('register.success_verify'));
      } else {
        let t = this;
        this.alertify.confirm(this.translate.instant('register.invalid'),
          this.translate.instant('register.invalid_url'),
          function () {
            t.registerService.resend(t.route.snapshot.queryParams['id']).subscribe((response) => {
              t.alertify.success(t.translate.instant('register.email_resend'));
            }, (error) => {
              t.alertify.error(t.translate.instant('general.error'));
            });
          });
      }
    }
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    var data = {
      email: this.f.username.value,
      password: this.f.password.value
    }

    this.auth.login(data, '/login').subscribe((response) => {
      if (response.urlResend) {
        let t = this;
        this.alertify.confirm(t.translate.instant('register.user_not_verified'),
          t.translate.instant('register.user_not_verified_detail'),
          function () {
            t.registerService.resend(null, response.urlResend).subscribe((response) => {
              t.alertify.success(t.translate.instant('register.email_resend'));
            }, (error) => {
              t.alertify.error(t.translate.instant('general.error'));
              t.auth.logout();
            });
          });
      } else {
        this.closeModal();
        this.auth.getPermissions().subscribe((permissions) => {
          if (permissions[0] == 'Cliente' && this.clientLogin == false) {
            this.alertify.error('El sistema no esta habilitado para clientes');
            this.auth.logout();
            return;
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('permissions', JSON.stringify(permissions?.permission));
          this.auth.setPermissions(permissions?.permission);

          if (permissions[0] == 'Cliente' && this.returnUrl == '/raffle') {
            this.router.navigate(['/raffle']);
          } else {
            this.router.navigate([this.returnUrl]);
          }

          this.alertify.success(this.translate.instant('auth.success_login'));
        }, (error) => {
          this.alertify.error(this.translate.instant('auth.error_login'));
          this.auth.logout();
        });
      }
    }, (error) => {
      this.alertify.error(this.translate.instant('auth.error_login'));
    });
  }

  signInWithFB(): void {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      var data = {
        name: user.firstName,
        last_name: user.lastName,
        url_photo: user.photoUrl,
        email: user.email,
        facebook_id: user.id,
      };

      this.user = user;
      this.loggedIn = (user != null);
      this.auth.login(data).subscribe((response) => {
        this.auth.getPermissions().subscribe((permissions) => {
          this.closeModal();
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('permissions', JSON.stringify(permissions?.permission));
          this.auth.setPermissions(permissions?.permission);

          if (permissions[0] == 'Cliente' && this.returnUrl == '/raffle') {
            this.router.navigate(['/home']);
          } else {
            this.router.navigate([this.returnUrl]);
          }
          this.alertify.success(this.translate.instant('auth.success_login'));
        }, (error) => {
          this.closeModal();
          this.alertify.error(this.translate.instant('auth.error_login'));
          this.auth.logout();
        });
      }, (error) => {
        this.closeModal();
        this.alertify.error(this.translate.instant('auth.error_login'));
      });
    });
  }

  signOut(): void {
    this.socialService.signOut();
  }

  resetPass() {
    if (this.forgetPassword.observers.length > 0) {
      this.forgetPassword.emit({});
    }
  }

  closeModal() {
    this.loginModal.dismiss('Login');
  }
}
