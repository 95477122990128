import { WalletService } from './../wallet.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from './../../shared/alertify.service';
import { RaffleService } from './../../raffle-module/raffle.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  total=0;
  tdcList =[];
  tdcSelected='';
  amountSelected='';
  optionPay;
  amountList =[50,100,150,200,300,500,1000];
  active = 1;

  loading;

  pageAll = 1;
  pagesizeAll = 20;
  listAll = [];

  pageRefill = 1;
  pagesizeRefill = 20;
  listRefill = [];

  pageShoping = 1;
  pagesizeShoping = 20;
  listShoping = [];

  constructor(
    public modalService: NgbModal,
    public raffleService: RaffleService,
    public alertify: AlertifyService,
    public translate: TranslateService,
    public walletService: WalletService
    ) { }

  ngOnInit(): void {
    this.getTdc();
    this.loadData();
  }

  loadData(){
    this.walletService.wallet().subscribe( (response) => {
      this.total = response.total;
      this.listRefill = response.deposits;
      this.listShoping = response.payments;
      let all =  response.deposits.concat(response.payments);
      this.listAll = all.sort(function(a, b){
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      });
    }, (error) => {
      console.log(error);
    });
  }

  changeTdc(event,tdc, modal){
    if(tdc.selectedIndex == (this.tdcList.length + 1)){
      tdc.value = 0;
      event.srcElement.blur();
      this.modalService.open(modal, { size: 'lg'});
    }
  }
  
  getTdc(){
    this.raffleService.cards().subscribe( (response) => {
      this.tdcList = response;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  updatedTdc(modal){
    this.alertify.success(this.translate.instant('general.form.success_save'));
    this.getTdc();
    modal.dismiss('');
  }
  
  cancelTdc(modal){
    modal.dismiss('');
  }

  chargeBalance(){
    const data = {
      'amount': this.amountSelected, 
      'card_id': this.tdcSelected
    }
    this.loading = true;
    this.walletService.deposit(data).subscribe( (response) => {
      this.loadData();
      this.alertify.success("Se agrego el deposito a tu monedero");
    }, ({error}) => {
      console.log(error);
      this.loading = false;
      this.alertify.error(error);
    });
  }

}
