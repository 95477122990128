import { Component, OnInit } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CancelReportService } from '../cancel-report.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'src/app/shared/alertify.service';

@Component({
  selector: 'app-cancel-report-form',
  templateUrl: './cancel-report-form.component.html',
  styleUrls: ['./cancel-report-form.component.css']
})
export class CancelReportFormComponent implements OnInit {

  faTimesCircle = faTimesCircle;
  redirect = '';

  
  id;
  data;
  loading = false;
	
	constructor(
		public service: CancelReportService,
    public translate: TranslateService,
    public route: ActivatedRoute,
    public alertify: AlertifyService,
	) {
		
	}

	ngOnInit() {
		
		this.redirect = '/cancel-report';
    this.id = this.route.snapshot.paramMap.get('id');

    this.service.get(this.id).subscribe((response) => {
      this.data = response;
    }, (error) => {
      this.alertify.error('No fue posible obtener la información del reporte');
    });


		
	}

}
