<div class="row height-100 bg-login">
  <div class="col-12">
    <div class="row justify-content-center align-items-center">
      <div class="col-12 mb-4 px-5">
        <img src="assets/images/home/header/webp/logo.webp" class="logo"/>
      </div>
      <div class="col-12 px-5" >
          <p class="h4 font-weight-bold text-success">{{ "index.login_sm" | translate }}</p>
      </div>
      <div class="col-12 px-5">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="text-primary-color-3">
          <div class="form-group">
            <div>
              <label class="font-weight-bold text-sm">{{ "register.email" | translate }}:</label>
              <input
                type="text"
                formControlName="username"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
              />
            </div>
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">
                {{ "register.errors.required" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <div>
              <label class="font-weight-bold text-sm">{{ "register.password" | translate }}:</label>
              <input
                type="password"
                formControlName="password"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">
                {{ "register.errors.required" | translate }}
              </div>
            </div>
          </div>
          <div class="text-left">
            <div class="form-group">
              <a href="javascript:void(0)" class="text-primary-color-3 text-sm" (click)="resetPass()">
                <small><fa-icon [icon]="faArrow" class="mr-2"></fa-icon></small>
                 ¿Olvidaste tu contraseña?
              </a>
            </div>
            <div class="form-group">
              <button
                class="btn btn-sec font-weight-bold btn-block rounded-pill btn-lg"
                type="submit"
                [disabled]="loading">
                <span class="text-m">{{ "index.login_sm" | translate }}</span>
              </button>
              <!-- <button
                class="btn btn-dark font-weight-bold btn-block rounded-pill btn-lg my-3"
                type="button"
                [disabled]="loading">
                <span class="text-sm">{{ "index.login_aple" | translate }}</span>
              </button> -->
              <button
                class="btn btn-fb font-weight-bold btn-block rounded-pill btn-lg"
                type="button"
                (click)="signInWithFB()"
                [disabled]="loading">
                <span class="text-sm">{{ "index.login_facebook" | translate }}</span>
              </button>
              <img
                  *ngIf="loading"
                  class="pl-2"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
