import { Validators } from '@angular/forms';
import { ImageCroppedEvent, Dimensions } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CatalogService } from './../../shared/catalog.service';
import { RaffleService } from './../../raffle-module/raffle.service';
import { CategoryService } from './../../admin/category/category.service';
import { FormComponent } from './../../shared/form/form.component';
import { Component, OnInit } from '@angular/core';
import { faCamera, faDollarSign, faCalendarAlt, faSearchLocation, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const MIN_PERCENTAGE_AMOUNT = 150;
const MAX_PERCENTAGE_AMOUNT = 300;
const RAFIKI_PERCENTAGE_COMISSION = 20;
const CONEKTA_FIXED_COMISSION_MXN = 2.5;
const CONEKTA_PERCENTAGE_COMISSION = 2.9;
const IVA_PERCENTAGE = 116;

@Component({
    selector: 'app-raffle-form',
    templateUrl: './raffle-form.component.html',
    styleUrls: ['./raffle-form.component.css']
})
export class RaffleFormComponent extends FormComponent implements OnInit {
    faCamera = faCamera;
    faDollar = faDollarSign;
    faCalendarAlt = faCalendarAlt;
    faLocation = faSearchLocation;
    faInfo = faInfoCircle;
    categories = [];
    subcategories = [];
    statusList = [];
    types = [];
    states = [];
    cities = [];

    //Check price
    minAmount = 0;
    maxAmount = 0;

    validAmount = true;
    showDetailInformation = false;
    suggestedAvailable = true;

    raffleOriginal;

    detail = {
        total: 0,
        comissionTicket: 0,
        comissionTotal: 0,
        comissionRafiki: 0,
        totalLessComissions: 0,
        profit: 0,
    };

    //search
    products = [];

    //Images
    images = [];
    currentSlideIndex = 0;
    urlImage: any;
    loading = false;
    loaderSearch = false;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    showCropper = false;
    isProvider = false;
    fileToUpload: File = null;
    imgResultBeforeCompress: string;
    imgResultAfterCompress: string;

    constructor(public service: RaffleService,
        public categoryService: CategoryService,
        public catalogsService: CatalogService,
        public imageCompress: NgxImageCompressService,
        public modalService: NgbModal) {
        super(service);
    }

    ngOnInit(): void {
        this.getExtras();
        this.checkMode();
        this.redirect = '/raffle';
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            prize_value: ['', Validators.required],
            total_amount: ['', Validators.required],
            suggested_value: [''],
            num_tickets: ['', Validators.required],
            ticket_price: ['', Validators.min(5000)],
            brand: ['', Validators.required],
            model: ['', Validators.required],
            description: [''],
            category_id: [{ value: '' }, Validators.required],
            subcategory_id: [{ value: '' }, Validators.required],
            product_type: [{ value: '' }, Validators.required],
            prize_type: [{ value: '' }, Validators.required],
            cat_state_id: [{ value: '' }, Validators.required],
            city: ['', Validators.required],
            start: ['', Validators.required],
            finish: ['', Validators.required],
        });
    }

    getExtras() {
        this.categoryService.all().subscribe((response) => {
            this.categories = response;
        }, (error) => {
            console.log(error);
        });
        this.catalogsService.states().subscribe((response) => {
            this.states = response;
        }, (error) => {
            this.alertify.error('No fue posible obtener las categorias');
        });
    }

    dataToForm(data) {
    }

    formToData() {
        this.data = {
            name: this.f.name.value,
            description: this.f.description.value,
            active: 1,
            brand: this.f.brand.value,
            model: this.f.model.value,
            prize_value: this.formatCurrency(this.f.prize_value.value),
            suggested_value: this.formatCurrency(this.f.suggested_value.value),
            num_tickets: this.f.num_tickets.value,
            ticket_price: this.formatCurrency(this.f.ticket_price.value),
            category_id: this.f.category_id.value,
            subcategory_id: this.f.subcategory_id.value,
            product_type: this.f.product_type.value,
            prize_type: this.f.prize_type.value,
            cat_state_id: this.f.cat_state_id.value,
            city: this.f.city.value,
            start: this.objDateToStr(this.f.start.value, '00:00:00'),
            finish: this.objDateToStr(this.f.finish.value, '23:59:59'),
            images: this.images
        };
    }

    formatCurrency(c) {
        return c / 100;
    }

    information(modal) {
        console.log('information function');
        if (this.products.length == 0) {
            this.alertify.error('No se tienen productos para mostrar');
            return;
        }
        this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: 'xl' });
    }

    objDateToStr(obj, time) {
        if (!obj) {
            return '00-00-00 00:00:00';
        }
        return obj.year + '-' + obj.month + '-' + obj.day + ' ' + time;
    }

    changeCategory(id) {
        this.categoryService.subcategories(id).subscribe((response) => {
            this.subcategories = response;
        }, (error) => {
            this.alertify.error('No fue posible cargar las subcategorias');
        });
    }

    searchPrices(content) {
        if (this.f.name.value == '' || this.f.name.value == null) {
            this.alertify.error('Agregue el nombre del producto');
            return;
        }
        this.loaderSearch = true;
        this.service.searchMercadoLibre(this.f.name.value).subscribe((response) => {
            try {

                if (!response.results) {
                    this.f.suggested_value.setValue(0);
                    this.suggestedAvailable = false;
                    return;
                }
                this.products = response.results;
                let count = this.products.length;

                if (count == 0) {
                    this.f.suggested_value.setValue(0);
                    this.suggestedAvailable = false;
                    return;
                }

                let sumPrices = 0;
                this.products.forEach((product) => {
                    sumPrices += product.price;
                });


                let avg = sumPrices / count;

                this.f.suggested_value.setValue(avg * 100);


                this.suggestedAvailable = true;
                this.loaderSearch = false;

                this.information(content);
            } catch (Error) {
                console.log('catch');
                console.log(Error);
                this.f.suggested_value.setValue(0);
                this.suggestedAvailable = false;
                return;
            }
        }, (error) => {
            console.log(error);
            this.loaderSearch = false;
            this.alertify.error('No se obtuvieron resultados');
        });
    }

    checkUpload() {
        if (this.images.length == 0) {
            this.alertify.error('Agregar las imágenes del artículo.');
            return;
        }
        this.images[0].default = true;

        const tp = this.f.ticket_price.value;
        if (tp.substring(tp.length - 2, tp.length) != '00') {
            this.alertify.error('El precio del boleto debe ser cantidad cerrada.');
            return;
        }

        if (this.fileToUpload != null) {
            const formData: FormData = new FormData();
            formData.append('file', this.fileToUpload);
            this.loading = true;
            this.service.upload(formData).subscribe((data) => {
                this.loading = false;
                this.urlImage = data.url;
                this.onSubmit();
            }, (error) => {
                this.loading = false;
                this.alertify.error('Ocurrió un error al subir la imagen al servidor');
            });
        } else {
            this.onSubmit();
        }
    }

    //Check price
    checkLimitAmounts() {
        if (this.f.prize_value.value == '000') {
            this.minAmount = 0;
            this.maxAmount = 0;
            return;
        }

        this.minAmount = (parseInt(this.f.prize_value.value) * MIN_PERCENTAGE_AMOUNT) / 100;
        this.maxAmount = (parseInt(this.f.prize_value.value) * MAX_PERCENTAGE_AMOUNT) / 100;
    }

    checkPercentagePrizeValue() {
        if (this.f.prize_value.value == '000' || this.f.total_amount.value == '000') {
            this.validAmount = true;
            return true;
        }

        let percentage = (parseInt(this.f.total_amount.value) / parseInt(this.f.prize_value.value)) * 100;

        if (percentage >= MIN_PERCENTAGE_AMOUNT && percentage <= MAX_PERCENTAGE_AMOUNT) {
            this.validAmount = true;
            return true;
        }

        this.validAmount = false;
        return false;
    }

    checkTicketPrice(changed) {
        let price = 0;
        let number = 0;
        this.paymentDetailInformation();

        if (this.f.total_amount.value == '000') {
            return;
        }


        if (changed == 'number') {
            if (this.f.num_tickets.value == '' || this.f.num_tickets.value == null) {
                return;
            }

            price = parseInt((this.f.total_amount.value / this.f.num_tickets.value) + '');

            this.f.ticket_price.setValue(price);
            this.paymentDetailInformation();

        }

        if (changed == 'price') {
            if (this.f.ticket_price.value == '000' || this.f.ticket_price.value == null) {
                return;
            }

            number = parseInt((this.f.total_amount.value / this.f.ticket_price.value) + '');

            this.f.num_tickets.setValue(number);
            this.paymentDetailInformation();
        }
    }

    suggestTicketPrice() {
        //TODO: modificar en base a las reglas que nos de sergio guzman
        let number = 100;
        let price = 0;
        if ((this.f.num_tickets.value == '' || this.f.num_tickets.value == null)
            && (this.f.ticket_price.value == '000' || this.f.ticket_price.value == null)
        ) {
            price = parseInt((this.f.total_amount.value / number) + '');

            this.f.num_tickets.setValue(number);
            this.f.ticket_price.setValue(price);
        } else if (this.f.num_tickets.value != '' && this.f.num_tickets.value != null) {
            price = parseInt((this.f.total_amount.value / this.f.num_tickets.value) + '');

            this.f.ticket_price.setValue(price);
        } else if (this.f.ticket_price.value != '000' && this.f.ticket_price.value != null) {
            number = parseInt((this.f.total_amount.value / this.f.ticket_price.value) + '');

            this.f.num_tickets.setValue(number);
        }

        this.paymentDetailInformation();
    }

    paymentDetailInformation() {
        if ((this.f.ticket_price.value != '000' && this.f.ticket_price.value != null) &&
            (this.f.num_tickets.value != '' && this.f.num_tickets.value != null) &&
            (this.f.total_amount.value != '000' && this.f.total_amount.value != null) &&
            (this.f.prize_value.value != '000' && this.f.prize_value.value != null)
        ) {

            const tpriceFormat = this.f.ticket_price.value / 100;
            const pvalueFormat = this.f.prize_value.value / 100;
            this.detail.total = tpriceFormat * this.f.num_tickets.value;
            this.detail.comissionRafiki = (this.detail.total * RAFIKI_PERCENTAGE_COMISSION) / 100;
            this.detail.comissionTicket =
                (CONEKTA_FIXED_COMISSION_MXN +
                    (tpriceFormat * (CONEKTA_PERCENTAGE_COMISSION / 100))) *
                (IVA_PERCENTAGE / 100);
            this.detail.comissionTotal = this.detail.comissionTicket * this.f.num_tickets.value;
            this.detail.totalLessComissions = this.detail.total - this.detail.comissionRafiki - this.detail.comissionTotal;
            this.detail.profit = this.detail.totalLessComissions - pvalueFormat;
            this.showDetailInformation = true;
        } else {
            this.detail.total = 0;
            this.detail.comissionRafiki = 0;
            this.detail.comissionTicket = 0;
            this.detail.comissionTotal = 0;
            this.detail.totalLessComissions = 0;
            this.detail.profit = 0;
            this.showDetailInformation = false;
        }
    }

    //Images
    fileChangeEvent(event: any, modal): void {
        const FileSize = event.target.files.item(0).size / 1024 / 1024; // in MB
        if (FileSize > 10) {
            event.value = '';
            this.alertify.error('El tamaño del archivo excede los 10 MB');
            return;
        }
        this.imageChangedEvent = event;
        this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then(function () {
            event.target.value = ""
        }, function () {
            event.target.value = ""
        });
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    endCrop(modal) {
        this.showCropper = false;
        var size = this.imageCompress.byteCount(this.croppedImage) / 1024 / 1024;
        var ratio = 50;
        if (size > 1) {
            if (size > 5) {
                ratio = 30;
            }

            if (size > 10) {
                ratio = 20;
            }

            this.imageCompress.compressFile(this.croppedImage, -1, ratio, 50).then(
                result => {
                    const formData: FormData = new FormData();
                    let fileToUp = this.dataURLtoFile(result, 'image');
                    formData.append('file', fileToUp);
                    this.loading = true;
                    this.service.upload(formData).subscribe((data) => {
                        this.loading = false;
                        let d = {
                            url_image: data.url,
                            default: false
                        };
                        this.images.push(d);
                        modal.dismiss('');
                    }, (error) => {
                        this.loading = false;
                        this.alertify.error('Ocurrió un error al subir la imagen al servidor');
                    });
                }
            );
        } else {
            const formData: FormData = new FormData();
            let fileToUp = this.dataURLtoFile(this.croppedImage, 'image');
            formData.append('file', fileToUp);
            this.loading = true;
            this.service.upload(formData).subscribe((data) => {
                this.loading = false;
                let d = { url_image: data.url, default: false };
                this.images.push(d);
                modal.dismiss('');
            }, (error) => {
                this.loading = false;
                this.alertify.error('Ocurrió un error al subir la imagen al servidor');
            });
        }
    }

    cropperReady(sourceImageDimensions: Dimensions) {
        //
    }

    loadImageFailed() {
        // show message
    }

    slideChange(slide) {
        this.currentSlideIndex = slide.current;
    }

    //Delete Image
    confirmDeleteImage(c) {
        let i = parseInt(c.activeId);
        this.alertify.confirm('Eliminar', '¿Esta seguro de eliminar esta imagen?', () => this.deleteImage(i));
    }

    deleteImage(index) {
        this.images.splice(index, 1);
    }


    save() {
        this.service.save(this.data).subscribe((response) => {
            if (this.updated.observers.length > 0) {
                this.updated.emit(response);
            } else {
                this.alertify.success(this.translate.instant('general.form.success_save'));
                this.router.navigate([this.redirect], { queryParams: this.getRedirectParams() });
            }
        }, (error) => {
            const message = error.error.message;
            if (message === 'Invalid User') {
                this.alertify.error(this.translate.instant('raffle.errors.invalid-user'));
            } else if (message === 'Invalid ticket price') {
                this.alertify.error(this.translate.instant('raffle.errors.invalid-ticket-price'));
            } else if (message === 'Invalid prize value') {
                this.alertify.error(this.translate.instant('raffle.errors.invalid-prize-value'));
            } else if (message === 'Card active is required to create a raffle') {
                this.alertify.error(this.translate.instant('raffle.errors.card-active'));
            }
        });
    }

    minTotalAmount() {
        if (this.f.prize_value.value)
            return Number(this.formatCurrency(this.f.prize_value.value)) * 2.5;
    }
}
