<ng-template #loginModal let-modal>
    <div class="modal-body">
        <app-customer-login [modal]="modal" [redirect]="redirectUrl" (forgetPassword)="forgetPassword(forgetModal,$event)"></app-customer-login>
        <hr>
		<div class="row justify-content-center text-primary-color-3 align-items-center mb-3">
			<div class="col-12 text-center">
				{{ "index.still_not_registered" | translate }}
			</div>
			<div class="col text-center text-sm font-weight-bold">
				{{ "index.create_new" | translate }}
				<a
				href="javascript:void(0)"
				class="ml-2 text-orange"
				(click)="[modal.dismiss('Close'), openRegister(registerModal)]"
				>{{ "index.sign_up" | translate }}</a
			  >
			</div>
        </div>
    </div>
</ng-template>

<ng-template #registerModal let-modal>
  <div class="modal-body">
      <app-register [modal]="modal" (resendEvent)="resendEvent(resendModal, $event)"></app-register>
	  <hr>
      <div class="row justify-content-center text-primary-color-3 align-items-center mt-2">
        {{ "index.already_account" | translate }}
        <a
          href="javascript:void(0)"
          class="font-weight-bold ml-2 text-orange"
          (click)="[modal.dismiss('Close'), loginOpen()]">
		  {{ "index.login_sm" | translate }}</a>
      </div>
  </div>
</ng-template>

<ng-template #resendModal let-modal>
  <div class="modal-body">
    <app-resend [id]="resendId" [email]="resendEmail" ></app-resend>
    <hr>
    <div class="row justify-content-center text-primary-color-3 align-items-center mt-2">
		{{ "index.already_account" | translate }}
      <a
        href="javascript:void(0)"
        class="font-weight-bold ml-2 text-orange"
        (click)="[modal.dismiss('Close'), loginOpen()]"
        >{{ "index.login_sm" | translate }}</a>
    </div>
  </div>
</ng-template>

<ng-template #forgetModal let-modal>
  <div class="modal-body">
    <app-forget-password [modal]="modal" (forgetEmailSended)="forgetEmailSended($event)" ></app-forget-password>
    <hr>
    <div class="row justify-content-center align-items-center mt-2 mr-2">
		{{ "index.already_account" | translate }}
      <a
        href="javascript:void(0)"
        class="font-weight-bold ml-2 text-orange"
        (click)="[modal.dismiss('Close'), loginOpen()]"
        >{{ "index.login_sm" | translate }}</a>
    </div>
  </div>
</ng-template>