<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faTicketAlt" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate
				}}</a></li>
		<li class="breadcrumb-item active" aria-current="page">{{ "admin.raffles" | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-10">
		<div class="row my-lg-4 my-md-4">
			<div class="col">
				<input class="form-control mr-sm-4" type="search" placeholder='{{ "list.search" | translate }}' aria-label="Search" (keyup)="onFilterTextBoxChanged($event)">
			</div>
		</div>
		<ag-grid-angular 
			style="width: 100%; height: 535px;"
			class="ag-theme-alpine"
			[rowData]="data"
			[pagination]="true"
			[paginationPageSize]="10"
			[gridOptions]="gridOptions"
			(gridReady)="onGridReady($event)"
			[localeText]="gridLocale"
			>
		</ag-grid-angular>
	</div>
</div>

<ng-template #cancelModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ "raffle.cancel.title" | translate }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="form-group">
			<label for="comments">Motivo de cancelación:</label>
			<textarea 
                class="form-control"
				[ngClass]="{ 'is-invalid': errorModal }"
                [(ngModel)]="comments"
                cols="30"
                rows="10">
            </textarea>
			<div *ngIf="errorModal" style="color:#dc3545;margin-top:.25rem; font-size: 80%;">
				<div> El motivo de cancelación es requerido</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.cancel" | translate
			}}</button>
		<button type="button" class="btn btn-primary" (click)="cancelRaffle(currentId,comments)">{{ "form.save" | translate }}</button>
	</div>
</ng-template>
