import { AuthService } from './../../auth-module/auth.service';
import { Event, NavigationEnd } from '@angular/router';
import { CategoryService } from './../../admin/category/category.service';
import { Component, OnInit } from '@angular/core';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { ListService } from '../../shared/list-service.service';
import { ListComponent } from '../../shared/list/list.component';
import { TranslateService } from '@ngx-translate/core';
import { AG_GRID_LOCALE_ES } from '../../shared/list/locale/es.locale';
import { faFilter, faSortAmountDown, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import * as amplitude from '@amplitude/analytics-browser';

@Component({
	selector: 'app-raffle',
	templateUrl: './raffle.component.html',
	styleUrls: ['./raffle.component.css'],
	providers: [ListService, DecimalPipe, CurrencyPipe]
})
export class RaffleComponent extends ListComponent implements OnInit {
	faTicketAlt = faTicketAlt;
	faFilter = faFilter;
	faSort = faSortAmountDown;

	sizeDefault = 20;
	currentId;
	modal;
	size = 0;
	total = 0;

	categories = [];
	subcategories = [];
	orderBy = "any";
	pminor = '';
	pmajor = '';
	cat = '';
	subcat = '';
	isAdmin = '';

	constructor(
		public service: RaffleService,
		public listService: ListService,
		public categoryService: CategoryService,
		public modalService: NgbModal,
		public translateService: TranslateService,
		public currencyPipe: CurrencyPipe,
		public auth: AuthService,
	) {
		super(service, listService);
		this.setObject('raffle');

		const per = this.auth.permissionsValue;
		this.isAdmin = per?.find((r) => r == 'Administrador')

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.loadData();
			}
		});
	}

	ngOnInit() {
		super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;

		this.categoryService.all().subscribe((data) => {
			this.categories = data.sort((a, b) => {
				let fa = a.name.toLowerCase(),
					fb = b.name.toLowerCase();
				return fa < fb ? -1 : fa > fb ? 1 : 0;
			});
		}, (error) => {
			this.alertify.error(this.translate.instant('general.list.load_error'));
		});
		const params = this.route.snapshot.queryParams;
		const cat = params.category;
		if (cat) {
			this.getSubService(cat);
		}
	}

	loadData() {
		let data = this.getUrlParams() + '&size=' + this.size;
		this.service.allWithFilter(data).subscribe((data) => {
			this.data = data.data;
			this.total = data.total;
		}, (error) => {
			this.alertify.error(this.translate.instant('general.list.load_error'));
		});
	}

	getUrlParams() {
		let data = '';
		const params = this.route.snapshot.queryParams;
		this.size = params.size ? params.size : this.sizeDefault;

		const search = params.search;
		data = search ? '?search=' + search : '?';

		const order = params.order;
		this.orderBy = order ? order : 'any';
		if (this.orderBy !== 'any' && this.orderBy !== 'finish') {
			const orders = this.orderBy.split('-');
			data += '&order=' + orders[0];
			data += '&order-way=' + orders[1];
		} else {
			data += '&order-way=asc';
		}

		const pminor = params.startprice;
		this.pminor = pminor ? pminor : '';
		if (pminor) {
			data += '&start-price=' + pminor;
		}
		const pmajor = params.endprice;
		this.pmajor = pmajor ? pmajor : '';
		if (pmajor) {
			data += '&end-price=' + pmajor;
		}
		const cat = params.category;
		this.cat = cat ? cat : '';
		if (cat) {
			data += '&category=' + cat;
		}
		const subcat = params.subcategory;
		this.subcat = subcat ? subcat : '';
		if (subcat) {
			data += '&subcategory=' + subcat;
		}

		return data;
	}

	getSubcategory(cat) {
		if (cat.selectedIndex === 0) {
			this.subcategories = []
			return;
		}
		this.getSubService(cat.selectedOptions[0].value);
	}

	getSubService(id) {
		this.categoryService.subcategories(id).subscribe((data) => {
			this.subcategories = data.sort((a, b) => {
				let fa = a.name.toLowerCase(),
					fb = b.name.toLowerCase();
				return fa < fb ? -1 : fa > fb ? 1 : 0;
			});;
		}, (error) => {
			this.alertify.error(this.translate.instant('general.list.load_error'));
		});
	}

	customShow(id, slug) {
		this.router.navigate(['/raffle/' + id + '/' + slug]);
	}

	openFilter(content) {
		this.modal = this.modalService.open(content, { size: 'sm' });
	}

	openOrder(content) {
		this.modal = this.modalService.open(content, { size: 'sm' });
	}

	filterData(modal, pminor, pmajor, cat, subcat) {
    amplitude.track('raffle_filtrar');

		modal.dismiss('');
		let filters = {
			'startprice': pminor !== '' ? pminor : null,
			'endprice': pmajor !== '' ? pmajor : null,
			'category': cat !== '' ? cat : null,
			'subcategory': subcat !== '' ? subcat : null
		};
		this.router.navigate(['/raffle'], { queryParams: filters, queryParamsHandling: 'merge' });
	}

	clearData() {
		let filters = {
			'startprice': null,
			'endprice': null,
			'category': null,
			'subcategory': null
		};
		this.router.navigate(['/raffle'], { queryParams: filters, queryParamsHandling: 'merge' });
	}

	changeOrder(value) {
    amplitude.track('raffle_ordenar');

		this.orderBy = value.target.value;
		this.router.navigate(['/raffle'], { queryParams: { order: this.orderBy }, queryParamsHandling: 'merge' });
	}

	moreSize() {
		this.size += 20;
		this.router.navigate(['/raffle'], { queryParams: { size: this.size }, queryParamsHandling: 'merge' });
	}

  moreInfo() {
    amplitude.track('raffle_mas-informacion');

    this.router.navigate(['/home/']);
  }
}
