<div class="d-flex justify-content-center pt-3" >
    <div class="col-12 text-center">
        <div class="col font-weight-bold">
            <h4> {{ "home.faqs" | translate }} </h4>
        </div>
        <pdf-viewer 
        src="assets/docs/faqs.pdf"
        [render-text]="true"
        [original-size]="false"
		[zoom]="screenSizeClass === 'screen-xs' ? 2 : 1"
        ></pdf-viewer>
    </div>
  </div> 
