import { faTicketAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { RaffleService } from '../../raffle-module/raffle.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../auth-module/auth.service";
import * as amplitude from "@amplitude/analytics-browser";

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  user;
  raffles: any = [];

  public faCalendar = faCalendarAlt;

  constructor(
    public auth: AuthService,
    private raffleService: RaffleService
  ) { }

  ngOnInit(): void {
    this.user = this.auth.currentUserValue ? this.auth.currentUserValue.user : null;

    amplitude.track('raffle-results_view', {
      userId: this.user?.id,
    });

    this.raffleService.results().subscribe((record) => {
      this.raffles = record.data;
    });
  }

  getDate(date){
    return date.substring(0,10);
  }
}
