import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegisterService } from '../register.service';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as amplitude from "@amplitude/analytics-browser";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  modal;

  loggedIn: boolean;
  user: any;

  constructor(
    public auth: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public formBuilder: FormBuilder,
    public alertify: AlertifyService,
    public translate: TranslateService,
    public registerService: RegisterService,
    private socialService: SocialAuthService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/admin';

    const per = this.auth.permissionsValue;
    const isAdmin = per?.find((r) => r == 'Administrador')
    if (this.auth.currentUserValue && isAdmin) {
      this.router.navigate(['/admin']);
    }
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    var data = {
      email: this.f.username.value,
      password: this.f.password.value
    }

    this.auth.login(data, '/admin/login').subscribe((response) => {
      if (response.urlResend) {
        this.loading = false;
        let t = this;
        this.alertify.confirm(t.translate.instant('register.user_not_verified'),
          t.translate.instant('register.user_not_verified_detail'),
          function () {
            t.registerService.resend(null, response.urlResend).subscribe((response) => {
              t.alertify.success(t.translate.instant('register.email_resend'));
            }, (error) => {
              t.alertify.error(t.translate.instant('general.error'));
              t.auth.logout();
            });
          });

        return;
      }

      this.auth.getPermissions().subscribe((permissions) => {
        this.loading = false;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('permissions', JSON.stringify(permissions?.permission));
        this.auth.setPermissions(permissions?.permission);
        this.router.navigate([this.returnUrl]);
        this.alertify.success(this.translate.instant('auth.success_login'));
      }, (error) => {
        this.loading = false;
        this.alertify.error(this.translate.instant('auth.error_login'));
        this.auth.logout();
      });
    }, (error) => {
      this.loading = false;
      this.alertify.error(this.translate.instant('auth.error_login'));
    });
  }

  signInWithFB(content): void {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      var data = {
        name: user.firstName,
        last_name: user.lastName,
        url_photo: user.photoUrl,
        email: user.email,
        facebook_id: user.id,
      };

      this.user = user;
      this.loggedIn = (user != null);
      this.auth.login(data).subscribe((response) => {
        this.auth.getPermissions().subscribe((permissions) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('permissions', JSON.stringify(permissions?.permission));
          this.auth.setPermissions(permissions?.permission);

          this.router.navigate([this.returnUrl]);

          this.alertify.success(this.translate.instant('auth.success_login'));

          amplitude.track('login_facebook-success', {
            userId: user?.id
          });
        }, (error) => {
          amplitude.track('login_facebook-failed', {
            userId: user?.id
          });
          this.alertify.error(this.translate.instant('auth.error_login'));
          this.auth.logout();
        });
      }, (error) => {
        amplitude.track('login_facebook-failed', {
          userId: user?.id
        });
        // falta el nickname
        if (error.status == 422 && (error.error.errors.nickname || error.error.errors.email)) {
          this.modal = this.modalService.open(content,
            { ariaLabelledBy: 'modal-basic-title', windowClass: "modalClassReg" }).result.then((result) => { }, (reason) => { });
        } else {
          this.alertify.error(this.translate.instant('auth.error_login'));
        }
      });
    });
  }

  signOut(): void {
    this.socialService.signOut();
  }
}
