import { Component, NgZone, OnInit, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';


import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faDownload, faHome, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { AG_GRID_LOCALE_EN } from 'src/app/shared/list/locale/en.locale';
import { ReportService } from '../report.service';
import { AG_GRID_LOCALE_ES } from 'src/app/shared/list/locale/es.locale';

@Component({
  selector: 'app-winners',
  templateUrl: './winners.component.html',
  styleUrls: ['./winners.component.css'],
  providers: [ListService, DecimalPipe, CurrencyPipe]
})
export class WinnersComponent extends ListComponent implements OnInit {

  faHome = faHome;
  faDownload = faDownload;
  faFileExcel = faFileExcel;
  faTrophy = faTrophy;


	constructor(
		public service: RaffleService,
		public reportService: ReportService,
		public listService: ListService,
		public translateService: TranslateService,
		public auth: AuthService,
		private zone: NgZone,
		public currencyPipe: CurrencyPipe,
		public decimalPipe: DecimalPipe,
	) {
		super(service, listService);
		this.setObject('report');
	}

	ngOnInit() {
    super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			defaultColDef: {
				resizable: true,
			},
			columnDefs: [
				{ headerName: '#', field: 'winner_id', sortable: true, width: 60 },
				{ headerName: 'winner.name', field: 'winner', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'winner.raffle_id', field: 'raffle_id', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'winner.raffle', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'winner.total_tickets', field: 'total_tickets', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        { 
          headerName: 'winner.total_amount',
          field: 'total_amount',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
            this.currencyPipe.transform(params.data.total_amount, 'USD', 'symbol', '1.2-2')
					,
        }
			]
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
    	this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

  loadData(){
    this.reportService.winners().subscribe((data) => {
      this.data = data;
      this.listService.setData(data);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.list.load_error'));
    });
  }

  public getExportUrlGeneral() {
    return environment.baseUrl + 'export/winners';
  }

}
