import { Component, OnInit, PipeTransform, Injector } from '@angular/core';
import { RoleService } from '../role.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { ListComponent } from 'src/app/shared/list/list.component';
import { Router } from '@angular/router';
import { ListService } from 'src/app/shared/list-service.service';
import { DecimalPipe } from '@angular/common';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class RoleListComponent extends ListComponent implements OnInit {
  faUserCircle = faUserCircle;
  constructor(
     public roleService: RoleService,
     public roleListService: ListService,
     ) {
       super(roleService, roleListService);
       this.setObject('role');
       this.setConfirmDeleteMessage('Se elimino correctamente el rol');
       this.setConfirmationDeleteMessage('¿Esta seguro de eliminar este rol?');

       this.roleListService.matches = function(data: any, term: string, pipe: PipeTransform) {
        return data.name.toLowerCase().includes(term)
              || pipe.transform(data.id).includes(term);
       };
     }
}
