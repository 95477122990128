import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth-module/auth.service';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { TranslateService } from '@ngx-translate/core';
import { faTicketAlt, faSearch, faTrash, faDollarSign, faMoneyBillWaveAlt, faCreditCard, faStopwatch, faShareAlt, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import * as amplitude from '@amplitude/analytics-browser';

@Component({
  selector: 'app-raffle-ticket',
  templateUrl: './raffle-ticket.component.html',
  styleUrls: ['./raffle-ticket.component.css']
})
export class RaffleTicketComponent implements OnInit {
  faTicket = faTicketAlt;
  faSearch = faSearch;
  faTrash = faTrash;
  faDollarSign = faDollarSign;
  faBill = faMoneyBillWaveAlt;
  faCreditCard = faCreditCard;
  faTimer = faStopwatch;
  faShare = faShareAlt;
  faAngleLeft = faAngleLeft;

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;

  @Input() raffle;
  @Input() modal;
  @Input() modalShare;
  @Input() modalTicket;

  @Output() getRaffle = new EventEmitter<string>();

  tickets = [];
  price;
  wallet;
  user;

  loading = true;
  loadingRandom;
  loadingTicket;
  finished = false;

  ticketsToShow = [];
  ticketsSelected = [];

  timeLeft: number = 600;
  interval;

  page = 1;
  pagesize = 28;
  fromNum;
  toNum
  available = false;

  pageSel = 1;
  pagesizeSel = 4;
  optionPay;
  tdcList = [];
  tdcSelected;

  constructor(
    public translate: TranslateService,
    public alertify: AlertifyService,
    public raffleService: RaffleService,
    public modalService: NgbModal,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.user = this.auth.currentUserValue.user;
    this.getExtras();
    this.modalTicket.result.then((data) => { }, (reason) => {
      if (!this.finished) {
        this.ticketsSelected.forEach((t) => {
          this.raffleService.unlockTicket(t.id).subscribe((response) => {
          }, (error) => {
            console.log(error);
            this.alertify.error('Ocurrio un problema al desbloquear los tickets.');
          });
        });
      }
    });
  }

  getExtras() {
    this.raffleService.get(this.raffle.id).subscribe((response) => {
      this.price = response.ticket_price;
      this.tickets = response.tickets;
      this.loading = false;

      this.tickets.map((t) => {
        let color = "text-success";
        color = t.status != 'Free' ? "text-secondary" : color;
        if (t.buyer_id === this.user.id && t.status != 'Sold') {
          color = "text-orange";
          this.ticketsSelected.push(t);
        }
        t['color'] = color;
      });
      this.ticketsToShow = [...this.tickets];
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

    this.raffleService.wallet().subscribe((response) => {
      this.wallet = response;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

    this.getTdc();
  }

  selectTicket(ticket) {
    if ((ticket.status === 'Free' || (ticket.buyer_id === this.user.id && ticket.status != 'Sold')) && !this.loadingTicket) {
      this.loadingTicket = true;
      const ind = this.ticketsSelected.findIndex((t) => t.id === ticket.id);
      if (ind > -1) {
        this.raffleService.unlockTicket(ticket.id).subscribe((response) => {
          const ind = this.ticketsSelected.findIndex((t) => t.id === ticket.id);
          this.ticketsSelected.splice(ind, 1);
          ticket.color = 'text-success';
          this.loadingTicket = false;
        }, (error) => {
          console.log(error);
          this.loadingTicket = false;
        });
      } else {
        this.raffleService.lockTicket(ticket.id).subscribe((response) => {
          ticket.color = 'text-orange';
          this.ticketsSelected.push(ticket);
          this.loadingTicket = false;
        }, (error) => {
          console.log(error);
          this.loadingTicket = false;
        });
      }
    }
    this.orderSelected();
  }

  searchTickets(st) {
    this.page = 1;
    if (this.available) {
      this.ticketsToShow = [...this.tickets.filter((t) => (t.status === 'Free' || (t.buyer_id === this.user.id && t.status != 'Sold')))]
      amplitude.track('raffle-detail_view-available-tickets', {
        raffleId: this.raffle.id,
        userId: this.user?.id,
      });
    } else {
      this.ticketsToShow = [...this.tickets];
    }

    if (st !== '') {
      this.ticketsToShow = this.ticketsToShow.filter((t) => (t.number + '').includes(st));
      amplitude.track('raffle-detail_search-tickets', {
        raffleId: this.raffle.id,
        userId: this.user?.id,
      });
    }
  }

  searchRange(st) {
    if ((this.fromNum && this.toNum) && (this.fromNum <= this.toNum)) {
      st.value = '';
      this.searchTickets('');
      this.ticketsToShow = this.ticketsToShow.filter((t) => (t.number >= this.fromNum && t.number <= this.toNum))

      amplitude.track('raffle-detail_search-tickets-range', {
        raffleId: this.raffle.id,
        userId: this.user?.id,
      });
    }
  }

  randomTicket(num) {
    this.loadingRandom = true;
    this.ticketsToShow.forEach((tts, i) => {
      const t = this.ticketsSelected.find((ts) => tts.id === ts.id);
      if (t) {
        this.ticketsToShow[i].color = 'text-success';
        this.raffleService.unlockTicket(t.id).subscribe((response) => {
        }, (error) => {
          console.log(error);
          this.alertify.error('Ocurrio un problema al seleccionar tus tickets.');
        });
      }
    });

    this.ticketsSelected = [];
    const data = {
      raffle_id: this.raffle.id,
      number: num
    };

    this.raffleService.randomTicket(data).subscribe((response) => {
      this.ticketsSelected = response;
      this.ticketsToShow = this.ticketsToShow.map((tts) => {
        const t = this.ticketsSelected.find((ts) => tts.id === ts.id);
        if (t) {
          tts['color'] = 'text-orange';
        }
        return tts;
      });
      this.loadingRandom = false;
    }, (error) => {
      console.log(error);
      this.loadingRandom = false;
    });

    this.orderSelected();

    amplitude.track('raffle-detail_random-tickets', {
      raffleId: this.raffle.id,
      userId: this.user?.id,
    });
  }

  removeTicket(ticket) {
    this.raffleService.unlockTicket(ticket.id).subscribe((response) => {
      const ind = this.ticketsSelected.findIndex((t) => t.id === ticket.id);
      this.ticketsSelected.splice(ind, 1);
      ticket.color = 'text-success';
    }, (error) => {
      console.log(error);
    });
  }

  orderSelected() {
    this.ticketsSelected = this.ticketsSelected.sort(function (a, b) { return a.number - b.number });
  }

  changeOpPay(op) {
    this.optionPay = op;
  }

  changeTdc(event, tdc, modal) {
    if (tdc.selectedIndex == (this.tdcList.length + 1)) {
      tdc.value = 0;
      event.srcElement.blur();
      this.modalService.open(modal, { size: 'lg' });
    }
  }

  getTdc() {
    this.raffleService.cards().subscribe((response) => {
      this.tdcList = response;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });
  }

  updatedTdc(modal) {
    this.alertify.success(this.translate.instant('general.form.success_save'));
    this.getTdc();
    modal.dismiss('');
  }

  cancelTdc(modal) {
    modal.dismiss('');
  }

  startTimer() {
    if (!this.interval) {
      this.interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.modal.dismiss('End')
        }
      }, 1000);

      amplitude.track('raffle-detail_choose-tickets-continue', {
        raffleId: this.raffle.id,
        userId: this.user?.id,
      });
    }
  }

  timeFormat() {
    const zeroPad = (num, places) => String(num).padStart(places, '0')
    const dateObj = new Date(this.timeLeft * 1000);
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();
    return zeroPad(minutes, 2) + ':' + zeroPad(seconds, 2);
  }

  confirmOrder() {
    const data = {
      method: this.optionPay === 1 ? 'Rafiki' : 'Conekta'
    }
    if (this.optionPay === 2) {
      if (!this.tdcSelected || this.tdcSelected === '' || this.tdcSelected === 'Agregar tarjeta') {
        this.alertify.error('Selecciona una tarjeta de credito');
        return;
      }
      data['card_id'] = this.tdcSelected
    }
    if (this.optionPay === 1 && (this.wallet.total < ((this.price * this.ticketsSelected.length)))) {
      this.alertify.error('El saldo no es suficiente para la compra');
      return;
    }
    this.loading = true;
    this.raffleService.order(this.raffle.id, data).subscribe((response) => {
      this.wizard.goToNextStep();
      this.loading = false;
      this.finished = true;
      this.getRaffle.emit('complete');

      amplitude.track('raffle-detail_payment-success', {
        raffleId: this.raffle.id,
        userId: this.user?.id,
      });
    }, (error) => {
      amplitude.track('raffle-detail_payment-failed', {
        raffleId: this.raffle.id,
        userId: this.user?.id,
      });

      this.loading = false;
      this.alertify.error('No se pudo generar tu compra, intenta mas tarde.');
    });
  }

  openShare(modalShare) {
    amplitude.track('raffle-choose-ticket-share', {
      raffleId: this.raffle.id,
      userId: this.user?.id,
    });

    this.modalService.open(modalShare, { ariaLabelledBy: 'modal-basic-title', size: 'sm' });
  }
}
