<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faTicketAlt" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate }}</a>
		</li>
		<li class="breadcrumb-item"><a routerLink="/raffle" class="text-primary-color-3">{{ "admin.raffles" | translate }}</a>
		</li>
		<li class="breadcrumb-item active" aria-current="page">{{ (view() ? "raffle.view" : (create() ? "raffle.create" : "raffle.edit" )) | translate }}</li>
	</ol>
  </nav>
  <div class="d-flex justify-content-center pt-3">
      <div class="col-md-10">
        <form [formGroup]="form" (ngSubmit)="checkActive()" class="bg-form shadow">
          <div class="d-flex justify-content-center">
            <div class="col-md-6 text-center">
              <h5>{{ "nav.raffles" | translate }}</h5>
            </div>
          </div>
          <div class="form-group">
            <label for="name">{{ "raffle.name" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="name" type="text" [ngClass]="{ 'is-invalid': submitted && f.name?.errors }">
            <div *ngIf="submitted && f.name?.errors" class="invalid-feedback">
                <div *ngIf="f.name?.errors.required">{{ "general.errors.required" | translate }}:</div>
            </div>
          </div>
          <div class="form-group">
            <label for="brand">{{ "raffle.brand" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="brand" type="text" [ngClass]="{ 'is-invalid': submitted && f.brand?.errors }">
          </div>
          <div class="form-group">
            <label for="model">{{ "raffle.model" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="model" type="text" [ngClass]="{ 'is-invalid': submitted && f.model?.errors }">
          </div>
          <div class="form-group">
            <label for="description">{{ "raffle.description" | translate }}:</label>
            <textarea
                class="form-control"
                [readonly]="view()"
                formControlName="description"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && f.description?.errors }"
                cols="30"
                rows="10">
            </textarea>
            <div *ngIf="submitted && f.description?.errors" class="invalid-feedback">
                <div *ngIf="f.description?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="category_id">{{ "raffle.category" | translate }}:</label>
            <select class="form-control" name="category_id" formControlName="category_id" (change)="changeCategory()" [ngClass]="{ 'is-invalid': submitted && f.category_id?.errors }">
              <option></option>
                <option *ngFor="let category of categories"  [ngValue]="category.id">{{category.name}}</option>
            </select>
            <div *ngIf="submitted && f.category_id?.errors" class="invalid-feedback">
                <div *ngIf="f.category_id?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="subcategory_id">{{ "raffle.subcategory" | translate }}:</label>
            <select class="form-control" name="subcategory_id" formControlName="subcategory_id" [ngClass]="{ 'is-invalid': submitted && f.subcategory_id?.errors }">
              <option></option>
                <option *ngFor="let subcategory of subcategories"  [ngValue]="subcategory.id">{{subcategory.name}}</option>
            </select>
            <div *ngIf="submitted && f.subcategory_id?.errors" class="invalid-feedback">
                <div *ngIf="f.subcategory_id?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="product_type">{{ "raffle.product_type" | translate }}:</label>
            <br>
            <label class="pr-2">
                <input type="radio" formControlName="product_type" name="product_type" value="Physical"> {{ "raffle.product_types.physical" | translate }}
            </label>
            <label class="pr-2">
                <input type="radio" formControlName="product_type" name="product_type" value="Digital"> {{ "raffle.product_types.digital" | translate }}
            </label>
            <label class="pr-2">
                <input type="radio" formControlName="product_type" name="product_type" value="Service"> {{ "raffle.product_types.service" | translate }}
            </label>
          </div>
          <div class="form-group">
            <label for="prize_type">{{ "raffle.prize_type" | translate }}:</label>
            <br>
            <label class="pr-2">
                <input type="radio" formControlName="prize_type" name="prize_type" value="New"> {{ "raffle.prize_types.new" | translate }}
            </label>
            <label class="pr-2">
                <input type="radio" formControlName="prize_type" name="prize_type" value="Used"> {{ "raffle.prize_types.used" | translate }}
            </label>
          </div>
          <div class="form-group">
            <label for="suggested_value"> <fa-icon *ngIf="create()" [icon]="faInfoCircle" (click)="information(content)"></fa-icon> {{ "raffle.suggested_value" | translate }} :</label>
            <input class="form-control" [readonly]="true" formControlName="suggested_value" type="text" [ngClass]="{ 'is-invalid': !suggestedAvailable }">
            <div *ngIf="!suggestedAvailable" class="invalid-feedback">
              <div>{{ "general.result_not_found" | translate}}</div>
            </div>
          </div>
          <div class="form-group" *ngIf="create()">
            <button type="button" class="btn btn-primary" (click)="searchPrices()">{{ "raffle.search_prices" | translate }}</button>
          </div>
          <div class="form-group">
            <label for="prize_value">{{ "raffle.prize_value" | translate }}:</label>
            <input class="form-control" [readonly]="view()" (change)="[checkLimitAmounts(),checkPercentagePrizeValue()]" formControlName="prize_value" type="number" [ngClass]="{ 'is-invalid': submitted && f.prize_value?.errors }">
            <div *ngIf="submitted && f.prize_value?.errors" class="invalid-feedback">
                <div *ngIf="f.prize_value?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="total_amount">{{ "raffle.total_amount" | translate }} ({{ "raffle.min" | translate }}: $ {{ minAmount | number:'1.2-2'}} - {{ "raffle.max" | translate }}: $ {{ maxAmount | number:'1.2-2' }}):</label>
            <input class="form-control" [readonly]="view()" (change)="[checkPercentagePrizeValue(),suggestTicketPrice()]" formControlName="total_amount" type="number" [ngClass]="{ 'is-invalid': (submitted && f.total_amount?.errors) ||  !validAmount }">
            <div *ngIf="(submitted && f.total_amount?.errors) || !validAmount" class="invalid-feedback">
              <div *ngIf="f.total_amount?.errors?.required">{{ "general.errors.required" | translate }}</div>
              <div *ngIf="!validAmount">{{ "raffle.range_values" | translate }} {{ minAmount }} - {{ maxAmount }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="num_tickets"># {{ "raffle.tickets" | translate }}:</label>
            <input class="form-control" [readonly]="view()" (change)="checkTicketPrice('number')" formControlName="num_tickets" type="number" [ngClass]="{ 'is-invalid': submitted && f.num_tickets?.errors }">
            <div *ngIf="submitted && f.num_tickets?.errors" class="invalid-feedback">
                <div *ngIf="f.num_tickets?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ticket_price">{{ "raffle.ticket_price" | translate }}:</label>
            <input class="form-control" [readonly]="view()" (change)="checkTicketPrice('price')" formControlName="ticket_price" type="number" [ngClass]="{ 'is-invalid': submitted && f.ticket_price?.errors }">
            <div *ngIf="submitted && f.ticket_price?.errors" class="invalid-feedback">
                <div *ngIf="f.ticket_price?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="raffle-payment" *ngIf="showDetailInformation">
            <h5>{{ "raffle.detail_payment_raffle" | translate }}:</h5>
            <label>{{ "raffle.max_amount_raffle" | translate }}: <strong> $ {{ detail.total | number:'1.2-2' }} </strong> </label>
            <br>
            <label>{{ "raffle.comission_rafiki" | translate }}: <strong>$ {{ detail.comissionRafiki | number:'1.2-2' }}</strong>  </label>
            <br>
            <label>{{ "raffle.comission_bank_ticket" | translate }}: <strong>$ {{ detail.comissionTicket  | number:'1.2-2'}}</strong> </label>
            <br>
            <label>{{ "raffle.comission_bank_total" | translate }}: <strong> $ {{ detail.comissionTotal | number:'1.2-2' }}</strong> </label>
            <br>
            <label>{{ "raffle.max_amount_client" | translate }}: <strong style="color:green"> $ {{ detail.totalLessComissions | number:'1.2-2' }} </strong> </label>
            <label class="invalid-radio">* {{ "raffle.shipping_reminder" | translate }}</label>
          </div>
          <div class="form-group">
            <label for="cat_state_id">{{ "raffle.state" | translate }}:</label>
            <select class="form-control" name="cat_state_id" formControlName="cat_state_id" [ngClass]="{ 'is-invalid': submitted && f.cat_state_id?.errors }">
              <option></option>
                <option *ngFor="let state of states"  [ngValue]="state.id">{{state.name}}</option>
            </select>
            <div *ngIf="submitted && f.cat_state_id?.errors" class="invalid-feedback">
                <div *ngIf="f.cat_state_id?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="city">{{ "raffle.city" | translate }}:</label>
            <input class="form-control" [readonly]="view()" formControlName="city" type="text" [ngClass]="{ 'is-invalid': submitted && f.city?.errors }">
            <div *ngIf="submitted && f.city?.errors" class="invalid-feedback">
                <div *ngIf="f.city?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="start">{{ "raffle.start" | translate }}:</label>
            <div class="input-group">
                <input class="form-control" readonly disabled formControlName="start" ngbDatepicker #s="ngbDatepicker" type="text" [ngClass]="{ 'is-invalid': submitted && f.start?.errors }">
                <div class="input-group-append">
                    <button type="button" disabled (click)="openCalendar(s)"><fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon></button>
                </div>
                <div *ngIf="submitted && f.start?.errors" class="invalid-feedback">
                    <div *ngIf="f.start?.errors.required">{{ "general.errors.required" | translate }}</div>
                </div>
            </div>
          </div>
          <div class="form-group">
            <ngb-timepicker [spinners]="!this.view()" formControlName="start_time" [readonlyInputs]="this.view()" [meridian]="false"></ngb-timepicker>
            <div *ngIf="submitted && f.start_time?.errors" class="invalid-feedback">
              <div *ngIf="f.start_time?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="finish">{{ "raffle.end" | translate }}:</label>
            <div class="input-group">
              <input class="form-control" readonly disabled formControlName="finish" ngbDatepicker #f="ngbDatepicker" type="text" [ngClass]="{ 'is-invalid': submitted && f.finish?.errors }">
              <div class="input-group-append">
                  <button type="button" disabled (click)="openCalendar(f)"><fa-icon [icon]="faCalendarAlt" class="mr-2"></fa-icon></button>
              </div>
              <div *ngIf="submitted && f.finish?.errors" class="invalid-feedback">
                  <div *ngIf="f.finish?.errors.required">{{ "general.errors.required" | translate }}</div>
              </div>
            </div>

          </div>
          <div class="form-group">
            <ngb-timepicker [spinners]="!this.view()" formControlName="finish_time" [readonlyInputs]="this.view()" [meridian]="false"></ngb-timepicker>
            <div *ngIf="submitted && f.finish_time?.errors" class="invalid-feedback">
              <div *ngIf="f.finish_time?.errors.required">{{ "general.errors.required" | translate }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="active">{{ "raffle.active" | translate }}:</label>
            <br>
            <label class="pr-2">
                <input type="radio" formControlName="active" name="active" value="1"> {{ "general.yes" | translate }}
            </label>
            <label class="pr-2">
                <input type="radio" formControlName="active" name="active" value="0"> {{ "general.no" | translate }}
            </label>
          </div>

          <!-- IMAGE -->
          <div class="form-group" *ngIf="!view()">
            <label for="zone">{{ "crop.image" | translate }}:</label><br>
            <div class="input-group mb-3">
              <div class="custom-file">
                <input type="file" class="custom-file-input" (change)="fileChangeEvent($event)" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                <label class="custom-file-label" for="inputGroupFile01">{{ "crop.image_chose" | translate }}</label>
              </div>
            </div>
            <label class="text-danger">*{{ "crop.max_image_size" | translate }}</label>
            <div class="row">
                <div class="col-md-6">
                    <label *ngIf="showCropper">{{ "crop.pick_face" | translate }}</label>
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="false"
                        [containWithinAspectRatio]="false"
                        [onlyScaleDown]="true"
                        format="png"
                        [cropperMinWidth]="128"
                        [style.display]="showCropper ? null : 'none'"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                    <button type="button" class="btn btn-success mr-3" *ngIf="showCropper" (click)="endCrop()">{{ "crop.accept" | translate }}</button>
                </div>
                <div class="col-md-6 cropper-image">
                    <label *ngIf="showCropper" for="">{{ "crop.preview" | translate }}</label>
                    <br>
                    <img *ngIf="showCropper" [src]="croppedImage" width="400px" />
                    <br>
                </div>
            </div>
          </div>
          <ngx-loading [show]="loading"
          [config]="{primaryColour: '#1D4E2B', secondaryColour: '#1D4E2B', tertiaryColour: '#1D4E2B' }"></ngx-loading>

          <div *ngIf="images.length > 0" >
            <ngb-carousel #c="ngbCarousel" (slide)="slideChange($event)" class="mb-4">
              <ng-template ngbSlide *ngFor="let image of images; let i = index" [id]="i">
                <div class="picsum-img-wrapper">
                  <img class="img-fluid mx-auto d-block" [src]="image.url_image">
                </div>
              </ng-template>
            </ngb-carousel>
            <div class="mt-3">
              <label *ngIf="images[currentSlideIndex].default == true">
                 {{"raffle.default_image_label" | translate}}
              </label>
              <!--button type="button" class="btn btn-primary" *ngIf="images[currentSlideIndex].default == false" (click)="checkChangeDefaultValue(c)">
                {{"raffle.default_image_btn" | translate}}
              </button>
              <button type="button" class="btn btn-default" (click)="confirmDeleteImage(c)">
                <img src="assets/images/icon_borrar2x.png" class="icon-list">
              </button-->
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-danger mr-3" routerLink="/admin/raffle">{{ "form.cancel" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="create()" >{{ "form.save" | translate }}</button>
            <button type="submit" class="btn btn-primary" *ngIf="edit() && raffleOriginal?.active == 0" >{{ "form.update" | translate }}</button>
          </div>
        </form>
      </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ "raffle.products" | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-inline">
        <div *ngFor="let product of products" class="col-2 col-md-2">
          <img [src]="product.thumbnail" width="100px" >
          <br>
          <span> {{ product.title }}</span>
          <br>
          <span> <strong>${{ product.price | number:'1.2-2'}}</strong> </span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.close" | translate }}</button>
    </div>
  </ng-template>
