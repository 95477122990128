import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormComponent } from 'src/app/shared/form/form.component';
import { RegisterService } from '../register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent extends FormComponent implements OnInit {
  @Input('modal') public loginModal;
  @Output() public resendEvent = new EventEmitter();

  timeout: any = null;
  validNickname = true;
  validEmail = true;
  loading = false;
  acceptTerms = false;

  constructor(
    public service: RegisterService) {
    super(service);
    this.action = 'create';
  }

  ngOnInit() {
    super.ngOnInit();

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      last_name: ['', Validators.required],
      nickname: ['', Validators.required],
      phone: ['', [Validators.required, Validators.pattern]],
      code_area: [''],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  dataToForm(data) {
    return {};
  }

  formToData() {
    this.data = {
      name: this.f.name.value,
      last_name: this.f.last_name.value,
      nickname: this.f.nickname.value,
      code_area: this.f.code_area.value + '',
      phone: this.f.phone.value + '',
      email: this.f.email.value,
      password: this.f.password ? this.f.password.value : null
    };
  }

  public keyupNickname(value) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (value.keyCode != 13 && value.target.value != '') {
        $this.executeListingNickname(value.target.value);
      }
    }, 200);
  }

  executeListingNickname(value: string) {
    var data = {
      nickname: value
    };
    this.service.checkNickname(data).subscribe((response) => {
      this.validNickname = response.valid;
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));
    });
  }

  public keyupEmail(value) {
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (value.keyCode != 13 && value.target.value != '') {
        $this.executeListingEmail(value.target.value);
      }
    }, 200);
  }

  executeListingEmail(value: string) {
    var data = {
      email: value
    };
    this.service.checkEmail(data).subscribe((response) => {
      this.validEmail = response.valid;
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));
    });
  }

  validation() {
    if (!this.validEmail || !this.validNickname) {
      return false;
    }
    return true;
  }

  save() {
    this.loading = true;
    this.service.save(this.data).subscribe((response) => {
      this.alertify.success(this.translate.instant('general.form.success_save'));

      let data = {
        id: response.id,
        email: this.f.email.value
      };
      this.resendEvent.emit(data);
      this.closeModal();
    }, (error) => {
      this.loading = false;
      console.log(error);
      if (error.status == 422) {
        console.log(error.error.errors);
        if (error.error.errors.nickname) {
          this.validNickname = false;
        }

        if (error.error.errors.email) {
          this.validEmail = false;
        }
      } else {
        this.alertify.error(this.translate.instant('general.error'));
      }
    });
  }

  public closeModal() {
    this.loginModal.dismiss('Close');
  }

  changeTerms(terms) {
    this.acceptTerms = terms.checked;
  }
}
