import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FormComponent } from 'src/app/shared/form/form.component';

@Component({
  selector: 'app-digital-prize',
  templateUrl: './digital-prize.component.html',
  styleUrls: ['./digital-prize.component.css']
})
export class DigitalPrizeComponent extends FormComponent implements OnInit {

  raffle: any;
  loading = false;
  instructionsImage: any;
  urlInstructions: any;
  form: FormGroup;
  sizeImage2: string = '196px';
  enableFile: boolean;
  submitted: boolean = false;

  public faCheckCircle = faCheckCircle;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public service: RaffleService,
    public fb: FormBuilder,
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.currentID = this.route.snapshot.paramMap.get('id');
    this.createForm();
    this.getRaffle(this.currentID);
  }

  get f() {
    return this.form.controls;
  }
  
  createForm() {
    const linkPattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/i;
    this.form = this.fb.group({
      instructions: ['', Validators.required],
      redeem_code: [''],
      external_link: ['', Validators.pattern(linkPattern)],
    });
  }

  getRaffle(id: any) {
    this.service.get(id).subscribe((resp) => {
      this.raffle = resp;
      this.f.instructions.setValue(this.raffle.digital_prize?.instructions);
      this.f.redeem_code.setValue(this.raffle.digital_prize?.redeem_code);
      this.f.external_link.setValue(this.raffle.digital_prize?.external_link);
      this.urlInstructions = this.raffle.digital_prize?.url_instructions;
      this.enableFile = this.urlInstructions == null ? true : false;
    }, (error) => {
      this.alertify.error(error || "Ocurrió un error");
    });
  }

  customShow(id: any, slug: any) {
    this.router.navigate(['profile/raffles/' + id + '/' + slug]);
  }

  changeUrlInstructions(event: any): void {
    this.instructionsImage = event.target.files[0];
  }

  sendDigitalPrize(idRaffle: any) {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    } else {
      try {
        const formData: FormData = new FormData();
        const data = this.form.value;
        formData.append('instructions', data.instructions);
        if (data.redeem_code != undefined) {
          formData.append('redeem_code', data.redeem_code);
        }
        if (data.external_link != undefined) {
          formData.append('external_link', data.external_link);
        }
        if (this.instructionsImage != undefined) {
          formData.append('instructions_file', this.instructionsImage);
        }
        this.loading = true;
        this.service.digitalPrize(idRaffle, formData).subscribe((res) => {
          this.loading = false;
          this.alertify.success(this.translate.instant('general.form.success_save'));
          this.getRaffle(idRaffle);
        }, (error) => {
          this.loading = false;
          this.alertify.error(error || "Ocurrió un error");
        });
      } catch (error) {
        this.loading = false;
        this.alertify.error('Ocurrió un error al subir la imagen al servidor');
      }
    }
  }
}
