import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertifyService } from '../alertify.service';
import { CrudService } from '../crud.service';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app-injector.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  currentID: string;
  path: string;
  mode: number; // 1-Create 2-Edit 3-View
  submitted = false;
  form: FormGroup;
  data: any;
  redirect: string;
  redirectPage: string;
	cookiePaginationActive = '';

  // for forms with categories
  categories: any;
  currentCategory: any;

  @Input() action: string;
  @Input() actionID: string;
  @Output() entity = new EventEmitter<any>();
  @Output() updated = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  get f() { return this.form.controls; }

  public alertify: AlertifyService;
  public route: ActivatedRoute;
  public formBuilder: FormBuilder;
  public router: Router;
  protected translate: TranslateService;
  protected injector;
  public cookies: CookieService;


  constructor(
    public service: CrudService) {
      const injector = AppInjector.getInjector();
      this.translate = injector.get(TranslateService);
      this.route = injector.get(ActivatedRoute);
      this.formBuilder = injector.get(FormBuilder);
      this.alertify = injector.get(AlertifyService);
      this.router = injector.get(Router);
      this.cookies = injector.get(CookieService);
    }

  ngOnInit() {
      this.checkMode();
      if (this.create()) {
        this.data = [];
        this.getExtras();
      } else {
        if (this.actionID) {
          this.currentID = this.actionID;
        } else {
          this.currentID = this.route.firstChild.snapshot.paramMap.get('id');
          if(this.currentID == null){
            this.currentID = this.route.children[0].children[0].snapshot.paramMap.get('id');
          }
        }
        this.service.get(this.currentID).subscribe((data) => {
          this.data = data;
          if (this.entity.observers.length > 0) {
            this.entity.emit(this.data);
          }
          this.form.setValue(this.dataToForm(data));
          this.getExtras();
        }, (error) => {
          this.alertify.error(this.translate.instant('general.form.load_error'));
        });
      }

      this.redirectPage = this.route.snapshot.queryParamMap.get('p');

      this.redirect = '/';
  }

  getRedirectParams(){
    if(this.redirectPage){
      return { p : this.redirectPage};
    } else {
      return {};
    }
  }

  getRedirect(){
    if(this.redirectPage){
      return this.redirect + '?p=' + this.redirectPage;
    } else {
      return this.redirect;
    }
  }

  checkMode() {
    if (this.action) {
      this.path = this.action;
    } else {
      this.path = this.router.url;
    }

    if (this.path.indexOf('create') >= 0) {
      this.mode = 1;
    } else if (this.path.indexOf('edit') >= 0 ) {
      this.mode = 2;
    } else if (this.path.indexOf('view') >= 0 ) {
      this.mode = 3;
    }
  }

  formToData() {
    this.data = this.f.value;
  }

  dataToForm(data: any) {
    return data;
  }

  create() {
    return this.mode === 1;
  }

  edit() {
    return this.mode === 2;
  }

  view() {
    return this.mode === 3;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid || !this.validation()) {
      this.alertify.error(this.translate.instant('general.form.complete_fields'));
      return;
    }

    this.formToData();

    if (this.create()) {
      this.save();
    }

    if (this.edit()) {
      this.update();
    }
  }

  save() {
    this.service.save(this.data).subscribe((response) => {

      if (this.updated.observers.length > 0) {
        this.updated.emit(response);
      }else {
        this.alertify.success(this.translate.instant('general.form.success_save'));
        this.router.navigate([this.redirect],{ queryParams : this.getRedirectParams()});

      }
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));
    });
  }

  update() {
    this.data.id = this.currentID;
    this.service.update(this.data).subscribe((response) => {

      if (this.updated.observers.length > 0) {
        this.updated.emit(response);
      }else {
        this.alertify.success(this.translate.instant('general.form.success_update'));
        this.router.navigate([this.redirect],{ queryParams : this.getRedirectParams()});
      }
    }, (error) => {
      this.alertify.error(this.translate.instant('general.error'));

    });
  }

  cancelActionExternal(){
    if (this.cancel.observers.length > 0) {
      this.cancel.emit([]);
    }
  }

  dateToString(date) {
    return date.year + '-' + date.month + '-' + date.day;
  }

  stringToDate(dateString) {
    let date = new Date(dateString);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() + 1};
  }


  // for forms with categories
  getSelectedCategory(categoryId) {
    let selectedCategory;
    this.categories.forEach(category => {
      if (category.id === categoryId) {
        selectedCategory = category;
      }
    });

    return selectedCategory;

  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    var extension = mime.split('/')[1];

    return new File([u8arr], filename + '.'  + extension, {type: mime});
  }

  getExtras() {}
  validation() { return true; }
}
