<div class="d-flex justify-content-center b-EFF3F6">
    <div class="container">
        <div class="row mt-5">
            <div class="col-6 text-center">
                <p class="h5 font-weight-bold">Saldo disponible</p>
                <p class="h2 text-success font-weight-bold">{{total | currency}}</p>
            </div>
            <div class="col-6">
                <div class="row justify-content-center">
                    <div class="h5 col-12 font-weight-bold text-center"> Cargar </div>
                    <div class="col-8">
                        <select class="form-control" name="tdc" (change)="changeTdc($event, tdc, modalCard)" #tdc [(ngModel)]="tdcSelected">
                            <option></option>
                            <option *ngFor="let tdc of tdcList" [ngValue]="tdc.id">{{tdc.number_formatted}}</option>
                            <option class="pointer">Agregar tarjeta</option>
                        </select>
                    </div>
                    <div class="col-3">
                        <select class="form-control" name="amount" [(ngModel)]="amountSelected">
                            <option></option>
                            <option *ngFor="let amo of amountList" [ngValue]="amo">{{amo}}</option>
                        </select>
                    </div>
                    <div class="col-auto mt-4">
                        <button [disabled]="amountSelected === '' || tdcSelected === '' || loading" (click)="chargeBalance()" class="btn btn-sec rounded-pill font-weight-bold ">Cargar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col">
                <hr>
                <h5 class="text-capitalize text-center">Mis movimientos</h5>
                <div class="d-flex justify-content-center mb-4">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink>Todo</a>
                          <ng-template ngbNavContent>
                              <div class="row justify-content-center">
                                  <div class="col-10">
                                      <div class="row text-center" *ngFor="let w of listAll| slice: (pageAll-1)*pagesizeAll : pageAll*  pagesizeAll; index as i;">
                                          <div class="col text-sm">
                                              {{w.created_at}}
                                          </div>
                                          <div class="col text-sm font-weight-bold">
                                              {{ w.amount ? 'Ingreso - Tarjeta' : 'Compra de boleto'}}
                                          </div>
                                          <div class="col font-weight-bold" [ngClass]="{'text-success': w.amount}">
                                              {{(w.amount ? '+ '+(w.amount| currency) : '- '+(w.payment_total | currency)) }}
                                          </div>
                                          <div class="col-12 p-0">
                                              <hr class="my-1 mx-3">
                                          </div>
                                      </div>
                                      <div class="row justify-content-center" *ngIf="listAll.length > pagesizeAll">
                                          <div class="col-auto">
                                              <ngb-pagination 
                                              [collectionSize]="listAll.length" 
                                              [(page)]="pageAll" 
                                              [(pageSize)]="pagesizeAll">
                                              </ngb-pagination>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                          <a ngbNavLink>Recargas</a>
                          <ng-template ngbNavContent>
                            <div class="row justify-content-center">
                                <div class="col-10">
                                    <div class="row text-center" *ngFor="let w of listRefill| slice: (pageRefill-1)*pagesizeRefill : pageRefill*  pagesizeRefill; index as i;">
                                        <div class="col text-sm">
                                            {{w.created_at}}
                                        </div>
                                        <div class="col text-sm font-weight-bold">
                                            {{ w.amount ? 'Ingreso - Tarjeta' : 'Compra de boleto'}}
                                        </div>
                                        <div class="col font-weight-bold" [ngClass]="{'text-success': w.amount}">
                                            {{(w.amount ? '+ '+(w.amount| currency) : '- '+(w.payment_total | currency)) }}
                                        </div>
                                        <div class="col-12 p-0">
                                            <hr class="my-1 mx-3">
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" *ngIf="listRefill.length > pagesizeRefill">
                                        <div class="col-auto">
                                            <ngb-pagination 
                                            [collectionSize]="listRefill.length" 
                                            [(page)]="pageRefill" 
                                            [(pageSize)]="pagesizeRefill">
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                          <a ngbNavLink>Compras</a>
                          <ng-template ngbNavContent>
                            <div class="row justify-content-center">
                                <div class="col-10">
                                    <div class="row text-center" *ngFor="let w of listShoping| slice: (pageShoping-1)*pagesizeShoping : pageShoping*  pagesizeShoping; index as i;">
                                        <div class="col text-sm">
                                            {{w.created_at}}
                                        </div>
                                        <div class="col text-sm font-weight-bold">
                                            {{ w.amount ? 'Ingreso - Tarjeta' : 'Compra de boleto'}}
                                        </div>
                                        <div class="col font-weight-bold" [ngClass]="{'text-success': w.amount}">
                                            {{(w.amount ? '+ '+(w.amount| currency) : '- '+(w.payment_total | currency)) }}
                                        </div>
                                        <div class="col-12 p-0">
                                            <hr class="my-1 mx-3">
                                        </div>
                                    </div>
                                    <div class="row justify-content-center" *ngIf="listShoping.length > pagesizeShoping">
                                        <div class="col-auto">
                                            <ngb-pagination 
                                            [collectionSize]="listShoping.length" 
                                            [(page)]="pageShoping" 
                                            [(pageSize)]="pagesizeShoping">
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </ng-template>
                        </li>
                      </ul>
                </div>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalCard let-modal>
    <div class="modal-body rounded">
      <app-create-card action="create" (updated)="updatedTdc(modal)" (cancel)="cancelTdc(modal)"></app-create-card>
    </div>
</ng-template>