import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FormComponent } from './../../shared/form/form.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogService } from 'src/app/shared/catalog.service';
import { AddressService } from '../address.service';

@Component({
  selector: 'app-my-address-form',
  templateUrl: './my-address-form.component.html',
  styleUrls: ['./my-address-form.component.css']
})
export class MyAddressFormComponent extends FormComponent implements OnInit {

  states = [];
  titleAddress: any;
  form: FormGroup;

  public faAngleLeft = faAngleLeft;

  constructor(
    public service: AddressService,
    public catalogsService: CatalogService,
    public fb: FormBuilder,
    public modalService: NgbModal,
  ) {
    super(service);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.redirect = '/my-address';
    this.getStates();

    if (this.create()) {
      this.titleAddress = 'Agregar dirección';
    } else {
      this.titleAddress = 'Editar dirección';
      this.currentID = this.route.firstChild.snapshot.paramMap.get('id');
    }

    this.createForm();
  }

  get f() {
    return this.form.controls;
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      country: ['México'],
      state: ['', Validators.required],
      city: ['', Validators.required],
      cp: ['', Validators.required],
      code_area: ['', Validators.required],
      phone: ['', Validators.required],
      contact_name: ['', Validators.required],
    });
  }

  dataToForm(data) {
    return {
      name: data.name,
      country: data.country,
      state: data.state,
      city: data.city,
      cp: data.cp,
      code_area: data.code_area,
      phone: data.phone,
      contact_name: data.contact_name
    }
  }

  formToData() {
    this.data = {
      name: this.f.name.value,
      country: this.f.country.value,
      state: this.f.state.value,
      city: this.f.city.value,
      cp: this.f.cp.value,
      code_area: this.f.code_area.value,
      phone: this.f.phone.value,
      contact_name: this.f.contact_name.value
    }
  }

  getStates() {
    this.catalogsService.states().subscribe((response) => {
      this.states = response;
    }, (error) => {
      this.alertify.error('No fue posible obtener los estados');
    });
  }

  submitAddress() {
    this.submitted = true;

    if (!this.validation() || this.form.invalid) {
      this.alertify.error(this.translate.instant('general.form.complete_fields'));
      return;
    }

    this.formToData();

    if (this.create()) {
      this.save();
    }

    if (this.edit()) {
      this.update();
    }
  }

  delete(modal) {
    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title', size: 'sm'});
  }

  deleteAddress(modal) {
    modal.dismiss('')
    this.service.delete(parseInt(this.currentID)).subscribe((resp) => {
      this.alertify.success('Se eliminó con éxito la dirección');
      this.router.navigate(['/my-address']);
    }, (error) => {
      this.alertify.error('Ocurrio un error');
    });
  }
}
