import { Component, OnInit, PipeTransform } from '@angular/core';
import { ListComponent } from '../../shared/list/list.component';

import { ListService } from '../../shared/list-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';
import { CardService } from '../card.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.css'],
  providers: [ListService, DecimalPipe]
})
export class CardListComponent extends ListComponent implements OnInit {

  public currentCard;
  public currentCardIndex;
  public currentAlias;
  public modal;

  constructor(
    public service: CardService,
    public listService: ListService,
    public modalService: NgbModal) {
      super(service, listService);
      this.setObject('card');

      this.listService.matches = function(data: any, term: string, pipe: PipeTransform) {
        return data.alias.toLowerCase().includes(term);
      };
    }

    ngOnInit() {
      this.loadData();
    }

    editModal(content,card,index){
      this.currentCard = card;
      this.currentCardIndex = index;
      this.currentAlias = card.alias + '';
      this.modal = this.modalService.open(content, 
        {ariaLabelledBy: 'modal-basic-title', windowClass : "modalClass"}).result.then((result) => {
      }, (reason) => {
      });
    }

    updateCard(){

      if(this.currentCard.alias == this.currentAlias){
        this.modalService.dismissAll();
        this.alertify.success(this.translate.instant('card.messages.updated_card'));
        this.cleanVariables();
        return;
      }

      let data = {
        id: this.currentCard.id,
        alias: this.currentAlias
      }
      
      this.service.update(data).subscribe( (response) => {
        this.currentCard.alias = this.currentAlias;
        this.modalService.dismissAll();
        this.alertify.success(this.translate.instant('card.messages.updated_card'));
        this.cleanVariables();
      }, (error) => {
        if(error.status == 422){
          this.alertify.error(this.translate.instant('card.messages.repeated_alias'));
        } else {
          this.alertify.error(this.translate.instant('card.messages.error_card_conkecta'));
        }
        
      });
    }

    deleteModal(content,card,index){
      this.currentCard = card;
      this.currentCardIndex = index;
      this.modal = this.modalService.open(content, 
        {ariaLabelledBy: 'modal-basic-title', windowClass : "modalClass"}).result.then((result) => {
      }, (reason) => {
      });
    }

    deleteCard(){
      this.service.delete(this.currentCard.id).subscribe( (response) => {
        this.modalService.dismissAll();
        this.alertify.success(this.translate.instant('card.messages.deleted_card'));
        this.data.splice(this.currentCardIndex,1);
        this.cleanVariables();
      }, (error) => {
          this.alertify.error(this.translate.instant('card.messages.error_delete_card'));    
      });
    }

    cleanVariables(){
      this.currentCard = null;
      this.currentCardIndex = null;
      this.currentAlias =  '';
    }

    createCardModal(content){
      this.modal = this.modalService.open(content, 
        {ariaLabelledBy: 'modal-basic-title', windowClass : "modalClass", size: 'lg'}).result.then((result) => {
      }, (reason) => {
      });
    }

    createResponse($event){
      this.data.push($event);
      this.alertify.success(this.translate.instant('card.messages.saved_card'));
      this.modalService.dismissAll();
    }

    cancelCreateCardAction($event){
      this.modalService.dismissAll();
    }

}
