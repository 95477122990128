import { Component, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faAngleLeft, faTicketAlt, faCalendarAlt, faSearchLocation, faInfo, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { AlertifyService } from 'src/app/shared/alertify.service';

const RAFIKI_PERCENTAGE_COMISSION = 20;
const CONEKTA_FIXED_COMISSION_MXN = 2.5;
const CONEKTA_PERCENTAGE_COMISSION = 2.9;
const IVA_PERCENTAGE = 116;

@Component({
  selector: 'app-profile-raffle-detail',
  templateUrl: './profile-raffle-detail.component.html',
  styleUrls: ['./profile-raffle-detail.component.css']
})
export class ProfileRaffleDetailComponent implements OnInit {

  currentID: string;
  raffle: any;
  tops = [];
  form: FormGroup;
  txtProductType: any;
  txtPrizeType: any;
  winnerID: any;

  detail = {
    total: 0,
    comissionTicket: 0,
    comissionRafiki: 0,
    totalLessComissions: 0,
    profit: 0,
  };

  public faAngleLeft = faAngleLeft;
  public faTicketAlt = faTicketAlt;
  public faCalendarAlt = faCalendarAlt;
  public faLocation = faSearchLocation;
  public faInfo = faInfo;
  public faDollar = faDollarSign;

  constructor(
    private route: ActivatedRoute,
    private service: RaffleService,
    private meta: Meta,
    private fb: FormBuilder,
    private router: Router,
    public alertify: AlertifyService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.currentID = this.route.snapshot.paramMap.get('id');
    this.service.get(this.currentID).subscribe((response) => {

      this.meta.addTag({ name: 'og:title', content: response.name });
      this.meta.addTag({ name: 'og:image', content: response.image });
      this.meta.addTag({ name: 'og:description', content: response.description });
      this.raffle = response;

      this.form.controls.name.setValue(this.raffle.name);
      this.form.controls.suggested_value.setValue('$ ' + this.raffle.suggested_value);
      this.form.controls.prize_value.setValue('$ ' + this.raffle.prize_value);
      this.form.controls.total_amount.setValue('$ ' + (this.raffle.num_tickets * this.raffle.ticket_price));
      this.form.controls.num_tickets.setValue(this.raffle.num_tickets);
      this.form.controls.ticket_price.setValue(this.raffle.ticket_price);
      this.form.controls.brand.setValue(this.raffle.brand);
      this.form.controls.model.setValue(this.raffle.model);
      this.txtProductType = (this.raffle.product_type === 'Physical' ? 'Fisico' : this.raffle.product_type === 'Digital' ? 'Digital' : this.raffle.product_type === 'Service' ? 'Servicio' : '');
      this.form.controls.product_type.setValue(this.txtProductType);
      this.txtPrizeType = (this.raffle.prize_type === 'New' ? 'Nuevo' : 'Usado');
      this.form.controls.prize_type.setValue(this.txtPrizeType);
      this.form.controls.description.setValue(this.raffle.description);
      this.form.controls.start.setValue(this.getFormatDate(this.raffle.start));
      this.form.controls.finish.setValue(this.getFormatDate(this.raffle.finish));
      this.form.controls.city.setValue(this.raffle.city);
      this.form.controls.cat_state_id.setValue(this.raffle.state.name);

      this.tops = response.tops.sort(function (a, b) { return b.total - a.total });
      this.paymentDetailInformation();
      this.getWinner(this.currentID);
    }, (error) => {
      this.alertify.error(error || "Ocurrió un error");
    });
  }

  createForm() {
    this.form = this.fb.group({
      name: [''],
      suggested_value: [''],
      prize_value: [''],
      total_amount: [''],
      num_tickets: [''],
      ticket_price: [''],
      brand: [''],
      model: [''],
      product_type: [''],
      prize_type: [''],
      description: [''],
      start: [''],
      finish: [''],
      cat_state_id: [''],
      city: ['']
    });
  }

  getFormatDate(date) {
    let day = new Date(date).toLocaleDateString('es', { weekday: 'short' });
    day = day.replace(/^\w/, (c) => c.toUpperCase());
    const month = new Date(date).toLocaleDateString('es', { month: 'short' });
    const numDay = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return day + ', ' + numDay + ' ' + month + ', ' + year;
  }

  paymentDetailInformation() {
    if ((this.form.controls.ticket_price.value != '' && this.form.controls.ticket_price.value != null) &&
      (this.form.controls.num_tickets.value != '' && this.form.controls.num_tickets.value != null) &&
      (this.form.controls.total_amount.value != '' && this.form.controls.total_amount.value != null) &&
      (this.form.controls.prize_value.value != '' && this.form.controls.prize_value.value != null)
    ) {
      this.detail.total = this.form.controls.ticket_price.value * this.form.controls.num_tickets.value;
      this.detail.comissionRafiki = (this.detail.total * RAFIKI_PERCENTAGE_COMISSION) / 100;
      this.detail.comissionTicket =
        (CONEKTA_FIXED_COMISSION_MXN +
          (this.form.controls.ticket_price.value * (CONEKTA_PERCENTAGE_COMISSION / 100))) *
        (IVA_PERCENTAGE / 100);
      this.detail.totalLessComissions = this.detail.total - this.detail.comissionRafiki;
      this.detail.profit = this.detail.totalLessComissions - this.form.controls.prize_value.value;
    } else {
      this.detail.total = 0;
      this.detail.comissionRafiki = 0;
      this.detail.comissionTicket = 0;
      this.detail.totalLessComissions = 0;
      this.detail.profit = 0;
    }
  }

  getWinner(id){
    this.service.winnerDetail(id).subscribe((response) => {
      this.winnerID = response.raffle_winner?.id;
    });
  }

  winnerDelivery(id, product_type) {
    if (product_type == 'Digital') {
      this.router.navigate(['raffle/' + id + '/digital-prize']);
    } else {
      this.router.navigate(['raffle/' + id + '/raffler-winner-delivery']);
    }
  }
}
