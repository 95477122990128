import { AlertifyService } from 'src/app/shared/alertify.service';
import { AuthService } from '../../auth-module/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faAngleLeft, faTicketAlt, faCalendarAlt, faSearchLocation, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import * as amplitude from '@amplitude/analytics-browser';

@Component({
  selector: 'app-raffle-detail',
  templateUrl: './raffle-detail.component.html',
  styleUrls: ['./raffle-detail.component.css']
})
export class RaffleDetailComponent implements OnInit {
  url;
  currentID: string;
  raffle: any;
  tops = [];
  user;
  slug;
  faAngleLeft = faAngleLeft;
  faTicketAlt = faTicketAlt;
  faCalendarAlt = faCalendarAlt;
  faLocation = faSearchLocation;
  faShare = faShareAlt;
  faTicket = faTicketAlt;
  modalTicket;
  reportR='';

  purchasedTickets = [];
  ticketsAvailable = [];

  constructor(
    private route: ActivatedRoute,
    private service: RaffleService,
    private meta: Meta,
    public modalService: NgbModal,
    private router: Router,
    public auth: AuthService,
    public alertify: AlertifyService,
    ) {
      this.url = this.router.url;
   }

  ngOnInit(): void {
    this.currentID = this.route.snapshot.paramMap.get('id');
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.user = this.auth.currentUserValue ? this.auth.currentUserValue.user : null;
    this.getRaffle();

    amplitude.track('raffle_view', {
      raffleId: this.currentID,
      slug: this.slug,
      userId: this.user?.id,
    });
  }

  getRaffle(){
    this.service.get(this.currentID).subscribe( (response) => {
      this.meta.addTag({ name: 'og:title', content: response.name });
      this.meta.addTag({ name: 'og:image', content: response.image });
      this.meta.addTag({ name: 'og:description', content: response.description });
      this.raffle = response;
      this.tops = response.tops.sort(function(a, b){return b.total-a.total});
      if(this.user)
        this.purchasedTickets = response.tickets.filter((t)=>t.buyer_id === this.user.id && t.status === 'Sold');
      this.ticketsAvailable = response.tickets.filter((t)=>t.status !== 'Sold');
    }, (error) => {
      console.log(error);
    });
  }

  getFormatDate(date){
    let day = new Date(date).toLocaleDateString('es', { weekday: 'short' });
    day = day.replace(/^\w/, (c) => c.toUpperCase());
    const month = new Date(date).toLocaleDateString('es', { month: 'short' });
    const numDay = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return day+', '+numDay+' '+month+', '+year;
  }

  modalTickets(modal, loginpopup){
    if(this.auth.currentUserValue){
      amplitude.track('raffle-detail_view-tickets', {
        raffleId: this.currentID,
        slug: this.slug,
        userId: this.user?.id,
      });

      this.modalTicket = this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
		}else{
			loginpopup.loginOpen('/raffle/' + this.currentID + '/' + this.slug);
		}
  }

  openShare(modal){
    amplitude.track('raffle-detail_share', {
      raffleId: this.currentID,
      slug: this.slug,
      userId: this.user?.id,
    });

    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title', size: 'sm'});
  }

  isFinish(){
    return new Date(this.raffle?.finish).getTime() < new Date().getTime()
  }

  hasTickets(){
    return !this.isFinish() && this.ticketsAvailable.length > 0;
  }

  openReportR(modal){
    this.reportR='';
    this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title', size: 'sm'});
  }

  raffleReport(modal){
    const data ={
      raffle_id:this.currentID,
      comments:this.reportR
    }
    this.service.raffleReport(data).subscribe( (response) => {
      this.alertify.success("¡Listo! Gracias por ayudarnos a mantener segura la plataforma");
      modal.dismiss('');
    }, (error) => {
      console.log(error);
      this.alertify.error("Ocurrio un error al reportar la rifa.");
    });
  }

  compraWhatsApp(){
    amplitude.track('compra_whatsapp');
  }
}
