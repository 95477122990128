<div class="bodycards">
	<div class="container">
		<div class="row justify-content-between">
			<div class="col-auto">
				<h2 class="text-capitalize text-orange">Rifas</h2>
			</div>
			<div class="col-auto align-self-center" *ngIf="!auth.currentUserValue || (isAdmin && auth.currentUserValue)">
				<button (click)="moreInfo()" class="btn btn-outline-success font-weight-bold">Más información sobre Rafiki</button>
			</div>
			<div class="col-auto align-self-center mr-4">
				<div class="row">
					<div class="col-auto">
						<button class="btn btn-outline-success font-weight-bold border-0" (click)="openOrder(order)">
							<fa-icon [icon]="faSort" class="mr-2"></fa-icon> Ordenar por
						</button>
					</div>
					<div class="col-auto">
						<button class="btn btn-outline-success font-weight-bold border-0" (click)="openFilter(filter)">
							<fa-icon [icon]="faFilter" class="mr-2"></fa-icon> Filtrar
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-auto mb-4" *ngFor="let prod of data">
				<div class="card" (click)="customShow(prod.id, prod.slug)">
					<div class="text-center">
						<img class="img-fluid" [src]="prod.image" [alt]="prod.name">
					</div>
					<hr>
					<div class="card-body">
						<div class="row justify-content-between">
							<div class="col-auto font-weight-bold">${{prod.ticket_price}}</div>
							<div class="col-auto font-weight-bold text-orange">
								<div class="text-xsm">
									<fa-icon [icon]="faTicketAlt" class="mr-2"></fa-icon>
									{{prod.sold_tickets+'/'+prod.num_tickets}}
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col text-sm">
								{{prod.name}}
							</div>
						</div>
					</div>
				  </div>
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-auto m-5 pb-5">
				<button type="button" class="btn btn-sec btn-lg rounded-pill font-weight-bold text-sm" (click)="moreSize()" *ngIf="total > size">
					Ver más
				</button>
			</div>
		</div>
	</div>
</div>

<ng-template #filter let-modal>
    <div class="modal-body">
		<div class="container">
			<div class="row justify-content-between">
				<div class="col-10 font-weight-bold text-secondary">Precio</div>
				<div class="col-1">
					<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label for="from" class="font-weight-bold text-secondary text-xsm">Desde</label>
						<input type="number" id="from" class="form-control" placeholder="0" #pmi [value]="pminor">
					</div>
				</div>
				<div class="col-6">
					<div class="form-group">
						<label for="to" class="font-weight-bold text-secondary text-xsm">Hasta</label>
						<input type="number" id="to" class="form-control" placeholder="1000" #pma [value]="pmajor">
					</div>
				</div>

			</div>
			<hr>
			<div class="row">
				<div class="col-12 font-weight-bold text-secondary">Categoría</div>
				<div class="col">
					<div class="form-group">
						<select class="form-control" name="category" #ct (change)="getSubcategory(ct)">
						<option></option>
						<option *ngFor="let category of categories" [value]="category.id"
							[selected]="category.id+'' === cat">{{category.name}}</option>
					</select>
					</div>
				</div>
			</div>
			<hr>
			<div class="row">
				<div class="col-12 font-weight-bold text-secondary">Subcategoría</div>
				<div class="col">
					<div class="form-group">
						<select class="form-control" id="subcategories" #st>
							<option></option>
							<option *ngFor="let sbcategory of subcategories" [value]="sbcategory.id"
								[selected]="sbcategory.id+'' === subcat">{{sbcategory.name}}</option>
						</select>
					</div>
				</div>
			</div>
			<hr>
		</div>
		<div class="row">
			<div class="col text-center">
				<button class="btn btn-sec rounded-pill my-2" (click)="filterData(modal, pmi.value, pma.value, ct.value, st.value)">Aplicar filtros</button>
				<button class="btn btn-outline-sec rounded-pill" (click)="clearData(modal)">Limpiar filtros</button>
			</div>
		</div>
    </div>
</ng-template>

<ng-template #order let-modal>
    <div class="modal-body">
		<div class="container">
			<div class="row justify-content-between">
				<div class="col-10 text-success font-weight-bold text-center">
					<p>Ordenar por</p>
				</div>
				<div class="col-1">
					<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
			<div class="row my-2">
				<div class="col">
					<div class="custom-control custom-radio custom-control-inline">
						<input class="form-check-input" type="radio" name="order" id="order1" value="any" (change)="changeOrder($event)"  [checked]="orderBy === 'any'">
						<label class="form-check-label" for="order1">Cualquiera</label>
					</div>
				</div>
			</div>
			<hr>
			<div class="row my-2">
				<div class="col">
					<div class="custom-control custom-radio custom-control-inline">
						<input class="form-check-input " type="radio" name="order" id="order2" value="finish" (change)="changeOrder($event)"  [checked]="orderBy === 'finish'">
						<label class="form-check-label" for="order2">Rifa próxima a finalizar</label>
					</div>
				</div>
			</div>
			<hr>
			<div class="row my-2">
				<div class="col">
					<div class="custom-control custom-radio custom-control-inline">
						<input class="form-check-input" type="radio" name="order" id="order3" value="ticket_price-asc" (change)="changeOrder($event)"  [checked]="orderBy === 'ticket_price-asc'">
						<label class="form-check-label" for="order3">Menor precio</label>
					</div>
				</div>
			</div>
			<hr>
			<div class="row my-2">
				<div class="col">
					<div class="custom-control custom-radio custom-control-inline">
						<input class="form-check-input" type="radio" name="order" id="order4" value="ticket_price-desc" (change)="changeOrder($event)"  [checked]="orderBy === 'ticket_price-desc'">
						<label class="form-check-label" for="order4">Mayor precio</label>
					</div>
				</div>
			</div>
			<hr>
			<div class="row my-2">
				<div class="col">
					<div class="custom-control custom-radio custom-control-inline">
						<input class="form-check-input" type="radio" name="order" id="order5" value="num_tickets-asc" (change)="changeOrder($event)"  [checked]="orderBy === 'num_tickets-asc'">
						<label class="form-check-label" for="order5">Menor número de boletos disponibles</label>
					</div>
				</div>
			</div>
			<hr>
			<div class="row my-2">
				<div class="col">
					<div class="custom-control custom-radio custom-control-inline">
						<input class="form-check-input" type="radio" name="order" id="order6" value="num_tickets-desc" (change)="changeOrder($event)"  [checked]="orderBy === 'num_tickets-desc'">
						<label class="form-check-label" for="order6">Mayor número de boletos disponibles</label>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col text-center">
				<button class="btn btn-outline-sec rounded-pill" (click)="modal.dismiss('')">Cancelar</button>
			</div>
		</div>
    </div>
</ng-template>
