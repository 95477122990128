import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RaffleRoutingModule } from './raffle-module-routing.module';
import { RaffleFormComponent } from './raffle-form/raffle-form.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RaffleListComponent } from './raffle-list/raffle-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
import { ImageCropperModule } from 'ngx-image-cropper';

import { HammerModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    RaffleFormComponent,
    RaffleListComponent
  ],
  imports: [
    HammerModule,
    CommonModule,
    RaffleRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AgGridModule,
    NgbModule,
    NgxLoadingModule,
    ImageCropperModule
  ]
})
export class RaffleModule { }
