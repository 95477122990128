import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from 'src/app/shared/crud.service';


@Injectable({
  providedIn: 'root'
})
export class CancelReportService extends CrudService {

  constructor(protected http: HttpClient) {
		super(http);
		this.setObject('cancel-report');
	}
}
