<div class="d-flex justify-content-center mt-4">
	<div class="row col-md-10">
		<div routerLink="/admin/admin" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faUsers" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp"> {{ "admin.admin-users" | translate }}</div>
		</div>
		<div routerLink="/admin/client" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faUserCircle" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp"> {{ "admin.client-users" | translate }}</div>
		</div>
		<div routerLink="/admin/category" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faClipboard" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.categories" | translate }}</div>
		</div>
		<div routerLink="/admin/subcategory" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faClipboardList" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.subcategories" | translate }}</div>
		</div>
		<div routerLink="/admin/application" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faIdCard" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.applications" | translate }}</div>
		</div>
	</div>
</div>
<div class="d-flex justify-content-center mt-4">
	<div class="row col-md-10">
		<div routerLink="/admin/raffle" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faTicketAlt" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp"> {{ "nav.raffles" | translate }}</div>
		</div>
		<div routerLink="/admin/raffle-report" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faExclamationCircle" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.reports" | translate }}</div>
		</div>
		<div routerLink="/admin/raffler-payment" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faDollarSign" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.raffler-payments" | translate }}</div>
		</div>
		<div routerLink="/admin/refund" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faWallet" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.refunds" | translate }}</div>
		</div>
		<!--div routerLink="/cancel-report" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faTimesCircle" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.cancel-report" | translate }}</div>
		</div-->
		<div routerLink="/admin/report" class="col-md-2 btn-home shadow">
			<div class="btn-home-icon">
				<fa-icon [icon]="faFileAlt" class="mr-2 h1"></fa-icon>
			</div>
			<div class="btn-admin-label bg-strapp">{{ "admin.report" | translate }}</div>
		</div>
	</div>
</div>