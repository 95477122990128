<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faUserCircle" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate }}</a>
		</li>
		<li class="breadcrumb-item"><a routerLink="/admin/client" class="text-primary-color-3">{{ "admin.client-users" | translate }}</a>
		</li>
		<li class="breadcrumb-item active" aria-current="page">{{ (view() ? "client-user.view" : (create() ? "client-user.create" : "client-user.edit" )) | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-10">
		<form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
			<div class="d-flex justify-content-center">
				<div class="col-md-6 text-center">
					<h5>{{ "admin.client-users" | translate }}</h5>
				</div>
			</div>
			<div class="form-group">
				<label for="name">{{ "client-user.form.name" | translate }}:</label>
				<input class="form-control" [readonly]="view()" formControlName="name" type="text"
					[ngClass]="{ 'is-invalid': submitted && f.name.errors }">
				<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
					<div *ngIf="f.name.errors.required">{{ "client-user.errors.name" | translate }}</div>
				</div>
			</div>
			<div class="form-group">
				<label for="user.email">{{ "client-user.form.email" | translate }}:</label>
				<input class="form-control" [readonly]="view() || edit()" name="email" type="email"
					formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
				<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
					<div *ngIf="f.email.errors.required">{{ "client-user.errors.email" | translate }}</div>
					<div *ngIf="f.email.errors.email">{{ "client-user.errors.email_valid" | translate }}</div>
				</div>
			</div>
			<div *ngIf="create()">
				<div class="form-group">
					<label for="user.password">{{ "client-user.form.password" | translate }}:</label>
					<input class="form-control" name="password" type="password" formControlName="password"
						[ngClass]="{ 'is-invalid': submitted && f.password.errors }">
					<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
						<div *ngIf="f.password.errors.required">{{ "client-user.errors.password" | translate }}</div>
					</div>
				</div>
				<div class="form-group">
					<label for="repeat-password">{{ "client-user.form.confirm_password" | translate }}:</label>
					<input class="form-control" name="repeat-password" type="password" formControlName="confirmPassword"
						[ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
						pattern="{{ f.password.value }}">
					<div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
						<div *ngIf="f.confirmPassword.errors.required">{{ "client-user.errors.confirm_password" | translate }}
						</div>
						<div *ngIf="f.confirmPassword.errors.pattern">{{
							"admin-user.change_password.confirmation_password_failed" | translate }}</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="active"> {{ "form.active.label" | translate }}:</label><br>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null"
						formControlName="active" type="radio" name="active" id="active1"
						value="1">
					<label class="form-check-label" for="price_by_person1">{{ "form.active.yes" | translate
						}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null"
						formControlName="active" type="radio" name="active" id="active2"
						value="0">
					<label class="form-check-label" for="active2">{{  "form.active.no" | translate
						}}</label>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button type="button" class="btn btn-danger mr-3" routerLink="/admin/client">{{ "form.cancel" | translate}}</button>
				<button type="submit" class="btn btn-primary" *ngIf="create()">{{ "form.save" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="edit()">{{ "form.update" | translate }}</button>
			</div>
		</form>
	</div>
</div>