<div class="flex-center">
  <div id="fondo" class="flex-center">
    <div class="container d-flex justify-content-center">
      <div class="row row-cols-2">
        <div class="col-auto">
          <div class="image-upload">
            <label for="file-input">
              <img [src]="urlPhoto" style="height: 160px; width: 170px; padding: 5px 5px 15px 5px;">
            </label>
            <input id="file-input" type="file" accept="image/png, image/jpeg" (change)="changePhotoUser($event)"
              [disabled]="photoUser">
          </div>
        </div>
        <div class="col-auto text-color-fff">
          <div class="row font-weight-bold">
            <p class="size-title">Mi perfil</p>
          </div>
          <div class="row font-weight-bold">
            <p class="size-subtitle">{{ nameAndLastName }}</p>
          </div>
          <div class="row">
            <p class="size-subtitle">{{ this.auth.currentUserValue?.user?.email }}</p>
          </div>
          <div class="row" *ngIf="!noEdit" (click)="editProfile()">
            <img src="assets/images/icon-edit@1x.png" style="height: 20px; width: 20px; cursor: pointer;">
            <p class="size-subtitle" style="cursor: pointer;">Editar</p>
          </div>
          <div class="row" *ngIf="noEdit" (click)="noEditProfile()">
            <img src="assets/images/icon-edit@1x.png" style="height: 20px; width: 20px; cursor: pointer;">
            <p class="size-subtitle" style="cursor: pointer;">Editar</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container d-flex justify-content-center">
  <div class="row row-cols-1 formInputs">
    <div class="col font-weight-bold text-primary-color-3 p-3">
      <form [formGroup]="form" (ngSubmit)="saveProfile()">
        <div class="form-row col-md-13">
          <div class="form-group col-md-6">
            <label for="name">{{ "admin-user.form.name" | translate }}</label>
            <input type="text" class="form-control" formControlName="name"
              maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.name?.errors }">
            <div *ngIf="submitted && f.name?.errors" class="invalid-feedback">
              <div *ngIf="f.name?.errors.required">{{ "general.errors.required" | translate }}
              </div>
            </div>
          </div>
          <div class="form-group col-md-5">
            <label for="last_name">{{ "admin-user.form.last_name" | translate }}</label>
            <input type="text" class="form-control" formControlName="last_name"
              maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.last_name?.errors }">
            <div *ngIf="submitted && f.last_name?.errors" class="invalid-feedback">
              <div *ngIf="f.last_name?.errors.required">{{ "general.errors.required" | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-row col-md-13">
          <div class="col">
            <div class="form-group col-md-13">
              <label for="nickname">{{ "raffle_report.user" | translate }}</label>
              <input type="text" class="form-control" formControlName="nickname"
                maxlength="255" [ngClass]="{ 'is-invalid': submitted && f.last_name?.errors }">
              <div *ngIf="submitted && f.nickname?.errors" class="invalid-feedback">
                <div *ngIf="f.nickname?.errors.required">{{ "general.errors.required" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-row col-md-13">
              <div class="form-group col-md-4">
                <label for="code_area">{{ "register.code_area" | translate }}</label>
                <input type="text" class="form-control"
                  formControlName="code_area" maxlength="4"
                  [ngClass]="{ 'is-invalid': submitted && f.code_area?.errors }">
                <div *ngIf="submitted && f.code_area?.errors" class="invalid-feedback">
                  <div *ngIf="f.code_area?.errors.required">{{ "general.errors.required" | translate }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="phone">Número telefónico</label>
                <input type="text" class="form-control" formControlName="phone"
                  maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.phone?.errors }">
                <div *ngIf="submitted && f.phone?.errors" class="invalid-feedback">
                  <div *ngIf="f.phone?.errors.required">{{ "general.errors.required" | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="email">{{ "admin-user.form.email" | translate }}</label>
            <input type="text" class="form-control" formControlName="email">
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="loading" class="text-center">
      <div class="spinner-border" style="width: 3rem; height: 3rem; color: #49A446;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="enableBtnGuardar" class="col">
      <button class="btn btn-sec rounded-pill" (click)="saveProfile()">Guardar</button>
    </div>

    <div class="col p-3">
      <button class="btn btn-sec rounded-pill" (click)="open(content)">{{ "profile.button" | translate}}</button>
    </div>
    <hr>
    <div class="col font-weight-bold size-title text-color-49A446 p-3">
      <p>Datos para poder crear rifas</p>
    </div>
    <div *ngIf="enableTextRaffle" class="col size-subtitle">
      <p>Habilita la edición para ver completa esta sección</p>
    </div>
    <div *ngIf="enableDocRaffle && (dataStatus == undefined || status == 0)" class="col">
      <div class="container">
        <div class="row">
          <div class="card-deck text-center">
            <div class="card size-card">
              <div class="card-body">
                <p class="card-title size-card-title font-weight-bold text-primary-color-3">Identificación INE o IFE
                  (Frontal)</p>
                <p class="card-text size-card-subtitle">Tamaño ideal 2542 x 1944 pixeles - 150 ppp.</p>
                <div class="image-upload">
                  <label for="idFront">
                    <img src="assets/images/icon-add-photo@1x.png" class="card-img-bottom img-archive">
                    <p class="card-text size-card-subtitle font-weight-bold text-primary-color-3">Adjuntar archivo</p>
                  </label>
                  <input id="idFront" type="file" accept="image/png, image/jpeg" (change)="changePhotoIdFront($event)">
                </div>
              </div>
              <div>
                <fa-icon *ngIf="idFrontImage" [icon]="faCheckCircle" class="mr-2 icon-faCheckCircle"></fa-icon>
              </div>
            </div>
            <div class="card size-card">
              <div class="card-body">
                <p class="card-title size-card-title font-weight-bold text-primary-color-3">Identificación INE o IFE
                  (Trasera)</p>
                <p class="card-text size-card-subtitle">Tamaño ideal 2542 x 1944 pixeles - 150 ppp.</p>
                <div class="image-upload">
                  <label for="idBack">
                    <img src="assets/images/icon-add-photo@1x.png" class="card-img-bottom img-archive">
                    <p class="card-text size-card-subtitle font-weight-bold text-primary-color-3">Adjuntar archivo</p>
                  </label>
                  <input id="idBack" type="file" accept="image/png, image/jpeg" (change)="changePhotoIdBack($event)">
                </div>
              </div>
              <div>
                <fa-icon *ngIf="idBackImage" [icon]="faCheckCircle" class="mr-2 icon-faCheckCircle"></fa-icon>
              </div>
            </div>
            <div class="card size-card">
              <div class="card-body">
                <p class="card-title size-card-title font-weight-bold text-primary-color-3">Carátula de Banco</p>
                <p class="card-text size-card-subtitle">Ingresar información de carátula de banco (nombre y clabe) para
                  poder obtener las ganancias generadas con la rifa realizada.</p>
                <div class="image-upload">
                  <label for="idBank">
                    <img src="assets/images/icon-add-photo@1x.png" class="card-img-bottom img-archive">
                    <p class="card-text size-card-subtitle font-weight-bold text-primary-color-3">Adjuntar archivo</p>
                  </label>
                  <input id="idBank" type="file" accept="image/png, image/jpeg" (change)="changePhotoBank($event)">
                </div>
              </div>
              <div>
                <fa-icon *ngIf="bankInformationImage" [icon]="faCheckCircle" class="icon-faCheckCircle"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="dataStatus != undefined && (status == 1 || status == null)" class="col">
      <div class="row row-cols-1 row-cols-md-3 g-3">
        <div class="col">
          <div class="card size-card">
            <img [src]="urlFront" [style.height]="sizeImage" class="card-img-top">
          </div>
        </div>
        <div class="col">
          <div class="card size-card">
            <img [src]="urlBack" [style.height]="sizeImage" class="card-img-top">
          </div>
        </div>
        <div class="col">
          <div class="card size-card">
            <img [src]="urlBankInformation" [style.height]="sizeImage" class="card-img-top">
          </div>
        </div>
      </div>
    </div>
    <div class="col font-weight-bold text-primary-color-3 p-3">
      <form [formGroup]="formSR">
        <div class="form-row">
          <div *ngIf="dataStatus" class="form-group col-md-6">
            <label for="inputStatus">Estatus</label>
            <input type="text" class="form-control" formControlName="inputStatus">
          </div>
          <div *ngIf="dataStatus != undefined && status == 0" class="form-group col-md-6">
            <label for="inputMotive">Motivo</label>
            <input type="text" class="form-control" formControlName="inputMotive">
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="loading" class="text-center p-3">
      <div class="spinner-border" style="width: 3rem; height: 3rem; color: #49A446;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <hr *ngIf="enableBtnUpdateRfc">
    <div *ngIf="enableBtnUpdateRfc" class="col font-weight-bold size-title text-color-49A446 p-3">
      <p>Datos fiscales</p>
    </div>
    <div class="col font-weight-bold text-primary-color-3 p-1">
      <form [formGroup]="formRFC">
        <div class="form-group col-md-6">
          <label for="rfc">RFC
            <fa-icon [icon]="faInfoCircle" class="icon-faInfoCircle" (click)="infoRFC()"></fa-icon>
          </label>
          <input type="text" class="form-control" formControlName="rfc"
            maxlength="13" minlength="13">
          <div *ngIf="rfc.invalid && (rfc.dirty || rfc.touched)">
            <div *ngIf="rfc.errors?.minlength" class="alert alert-danger">
              El rfc debe tener al menos 13 caracteres
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="(enableDocRFC && !valueRfc) || enableBtnUpdateRfc" class="col">
        <div class="row">
          <div class="col-4">
            <div class="card text-center size-card2" style="width: 20rem;">
              <div class="card-body">
                <p class="card-title size-card-title font-weight-bold text-primary-color-3">Documentación RFC</p>
                <div class="image-upload">
                  <label for="idRfc">
                    <img src="assets/images/icon-add-photo@1x.png" class="card-img-bottom img-archive">
                    <p class="card-text size-card-subtitle font-weight-bold text-primary-color-3">Adjuntar archivo</p>
                  </label>
                  <input id="idRfc" type="file" accept="image/png, image/jpeg" (change)="changePhotoRfc($event)">
                </div>
              </div>
              <div>
                <fa-icon *ngIf="rfcImage" [icon]="faCheckCircle" class="icon-faCheckCircle"></fa-icon>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div *ngIf="valueRfc" class="col">
        <div class="row">
          <div class="col-4">
            <div class="card text-center size-card2" style="width: 20rem;">
              <img [src]="urlRfc" [style.height]="sizeImage2" class="card-img-top">
            </div>
          </div>
        </div>
    </div>
    <div class="col p-3">
      <button *ngIf="enableBtnUpdateRfc" class="btn btn-sec rounded-pill" (click)="updateRFC()">Enviar datos fiscales</button>
    </div>
    <div class="col p-3">
      <button *ngIf="enableBtnEnviarDocs" class="btn btn-sec rounded-pill" (click)="saveDocuments()">Enviar documentos
        de acreditación</button>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ "profile.modal.title" | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="user.password">{{ "profile.modal.old" | translate}}:</label>
      <input class="form-control" name="password" type="password" [(ngModel)]="oldPassword"
        [ngClass]="{ 'is-invalid': oldPasswordError }">
      <div *ngIf="oldPasswordError" class="invalid-feedback">
        <div> {{ "profile.modal.required" | translate}}</div>
      </div>
    </div>
    <div class="form-group">
      <label for="user.password">{{ "profile.modal.new" | translate}}:</label>
      <input class="form-control" name="password" type="password" [(ngModel)]="newPassword"
        [ngClass]="{ 'is-invalid': newPasswordError }">
      <div *ngIf="newPasswordError" class="invalid-feedback">
        <div> {{ "profile.modal.confirmation" | translate }}</div>
      </div>
    </div>
    <div class="form-group">
      <label for="repeat-password">{{ "profile.modal.confirm" | translate}}:</label>
      <input class="form-control" name="repeat-password" type="password" [(ngModel)]="confirmPassword"
        [ngClass]="{ 'is-invalid': newPasswordError }">
      <div *ngIf="newPasswordError" class="invalid-feedback">
        <div>{{ "profile.modal.confirmation" | translate }}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ "form.cancel" |
      translate}}</button>
    <button type="button" class="btn btn-primary" (click)="changePassword()">{{ "form.save" | translate}}</button>
  </div>
</ng-template>
