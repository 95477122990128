import { Component, OnInit } from '@angular/core';
import { 
  faTicketAlt,
  faDollarSign,
  faTrophy,
  faFileAlt,
  faWallet
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  faTicketAlt = faTicketAlt;
  faTrophy = faTrophy;
  faDollarSign = faDollarSign;
  faFileAlt = faFileAlt;
  faWallet = faWallet;
  

  constructor() { }

  ngOnInit(): void {
  }

}
