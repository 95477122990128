import { ResultsComponent } from './results/results.component';
import { WalletComponent } from './wallet/wallet.component';
import { DeliveryInformationComponent } from './delivery-information/delivery-information.component';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { RafflerWinnerDeliveryComponent } from './raffler-winner-delivery/raffler-winner-delivery.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RaffleComponent } from './raffle/raffle.component';
import { RaffleDetailComponent } from './raffle-detail/raffle-detail.component';
import { RaffleFormComponent } from './raffle-form/raffle-form.component';
import { DigitalPrizeComponent } from './digital-prize/digital-prize.component';
import { AuthGuard } from '../auth-module/auth.guard';

const routes: Routes = [
	{
		path: 'raffle',
		component: RaffleComponent
	}, {
		path: 'raffle/create',
		component: RaffleFormComponent,
        canActivate: [AuthGuard]
	}, {
		path: 'raffle/:id/raffler-winner-delivery',
		component: RafflerWinnerDeliveryComponent
	}, {
		path: 'raffle/:id/update-delivery-information',
		component: DeliveryInformationComponent
	}, {
		path: 'raffle/:id/digital-prize',
		component: DigitalPrizeComponent
	}, {
		path: 'raffle/:id/:slug',
		component: RaffleDetailComponent
	}, {
		path: 'my-tickets',
		component: MyTicketsComponent
	}, {
		path: 'results',
		component: ResultsComponent
	},{
		path: 'wallet',
		component: WalletComponent
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicModuleRoutingModule { }
