<div class="container">
    <div class="row justify-content-center">
        <div class="col-11">
            <form [formGroup]="form">
                <div class="row justify-content-between py-5">
                    <div class="col-auto">
                        <div class="text-orange font-weight-bold h2">
                            Agregar datos del premio
                        </div>
                    </div>
                    <div class="col-auto">
                        <button 
                            type="button" 
                            class="btn btn-outline-success rounded-pill font-weight-bold"
                            (click)="customShow(raffle.id, raffle.slug)">
                            Cancelar
                        </button>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="instructions">Instrucciones</label>
                            <input type="text" name="instructions" id="instructions" formControlName="instructions" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.instructions?.errors }">
                            <div *ngIf="submitted && f.instructions?.errors" class="invalid-feedback">
                                <div *ngIf="f.instructions?.errors.required">{{ "general.errors.required" | translate }} </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="redeem_code">Código de canjeo</label>
                            <input type="text" name="redeem_code" id="redeem_code" formControlName="redeem_code" class="form-control">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="external_link">Link externo</label>
                            <input type="url" name="external_link" id="external_link" formControlName="external_link" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.external_link?.errors }">
                            <div *ngIf="submitted && f.external_link?.errors" class="invalid-feedback">
                                <div *ngIf="f.external_link?.errors.pattern">Link directo no valido, ejemplo: https://wwww.google.com</div>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div *ngIf="loading" class="text-center">
                    <div class="spinner-border" style="width: 3rem; height: 3rem; color: #49A446;" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                <hr class="my-4">
                <div class="container" *ngIf="enableFile">
                    <div class="row">
                        <div class="col">
                            <div class="card text-center size-card" style="width: 20rem;">
                                <div class="card-body">
                                    <p class="card-title size-card-title font-weight-bold text-primary-color-3">
                                        Subir archivo
                                    </p>
                                    <p class="card-text size-card-subtitle">
                                        Tamaño ideal 2542 x 1944 pixeles - 150 ppp.
                                    </p>
                                    <div class="image-upload">
                                        <label for="idInstructions">
                                            <img src="assets/images/icon-add-photo@1x.png" class="card-img-bottom img-archive">
                                            <p
                                                class="card-text size-card-subtitle font-weight-bold text-primary-color-3">
                                                Adjuntar archivo
                                            </p>
                                        </label>
                                        <input id="idInstructions" type="file" accept="image/png, image/jpeg" (change)="changeUrlInstructions($event)">
                                    </div>
                                </div>
                                <div>
                                    <fa-icon *ngIf="instructionsImage" [icon]="faCheckCircle" class="icon-faCheckCircle"></fa-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" *ngIf="urlInstructions">
                    <div class="row">
                        <div class="col-4">
                            <div class="card text-center size-card2" style="width: 20rem;">
                                <img [src]="urlInstructions" [style.height]="sizeImage2" class="card-img-top">
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-4">
                <div class="row my-4">
                    <div class="col-12 mb-5">
                        <button 
                            type="submit" 
                            class="btn rounded-pill font-weight-bold create"
                            (click)="sendDigitalPrize(raffle.id)">
                            Enviar datos del premio
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>