import { Component, OnInit, Input } from '@angular/core';
import { RegisterService } from '../register.service';
import { ActivatedRoute } from '@angular/router';
import { AppInjector } from '../../app-injector.service';
import { AlertifyService } from '../../shared/alertify.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.css']
})
export class ResendComponent implements OnInit {
  public alertify: AlertifyService;
  protected translate: TranslateService;
  public route: ActivatedRoute;
  protected injector;
  @Input('id') public id;
  @Input('email') public email;
  loading = false;

  constructor(public regService: RegisterService) {
    const injector = AppInjector.getInjector();
    this.route = injector.get(ActivatedRoute);
    this.translate = injector.get(TranslateService);
    this.alertify = injector.get(AlertifyService);
  }

  ngOnInit() {
  }

  resend() {
    this.loading = true;
    this.regService.resend(this.id).subscribe((response) => {
      this.alertify.success(this.translate.instant('register.email_resend'));
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.alertify.error(this.translate.instant('general.error'));
    });
  }
}
