<div class="bodycards">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-auto">
                <h2 class="text-capitalize text-orange">Resultados</h2>
            </div>
        </div>
        <div class="container" *ngIf="raffles && raffles.length > 0">
            <div class="card mb-3" *ngFor="let raffle of raffles" [routerLink]="'/raffle/' +raffle.id+ '/'+raffle.slug" >
                <div class="row g-0">
                    <div class="col-md-4">
                        <div class="text-center p-2">
                            <img class="img-fluid img-thumbnail" [src]="raffle.image">
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <p class="card-title text-capitalize h5">
                                {{raffle.name}} 
                            </p>
                            <hr class="my-2">
                            <div class="row g-0">
                                <div class="col-md-8">
                                    <p class="card-text font-weight-bold">
                                        {{"Ganador: " + raffle?.raffle_winner.winner.nickname + " con el número " + raffle?.raffle_winner.ticket.number}}
                                    </p>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p class="card-text text-sm font-italic">
                                        <fa-icon [icon]="faCalendar" class="text-success"></fa-icon> 
                                        {{ "Finalizado el " + getDate(raffle.finish)}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>