<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faIdCard" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate }}</a>
		</li>
		<li class="breadcrumb-item"><a routerLink="/admin/application" class="text-primary-color-3">{{ "admin.applications" | translate }}</a>
		</li>
		<li class="breadcrumb-item active" aria-current="page">{{ (view() ? "application.view" : (create() ? "application.create" : "application.edit" )) | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-12">
		<form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
			<div class="d-flex justify-content-center">
				<div class="col-md-6 text-center">
					<h5>{{ "admin.applications" | translate }}</h5>
				</div>
			</div>
			<div class="d-flex justify-content-center pt-4" *ngIf="f.url_id_front.value">
				<div class="col-md-4 col-sm-12">
					<label for="name">{{ "application.id_front" | translate }}:</label>
					<img  class="img-fluid" src="{{ f.url_id_front.value }}">
				</div>
			</div>
			<div class="d-flex justify-content-center pt-4" *ngIf="f.url_id_back.value">
				<div class="col-md-4 col-sm-12">
					<label for="name">{{ "application.id_back" | translate }}:</label>
					<img  class="img-fluid" src="{{ f.url_id_back.value }}">
				</div>
			</div>
			<div class="d-flex justify-content-center pt-4" *ngIf="f.url_bank_information.value">
				<div class="col-md-4 col-sm-12">
					<label for="name">{{ "application.bank_information" | translate }}:</label>
					<img  class="img-fluid" src="{{ f.url_bank_information.value }}">
				</div>
			</div>
			<div class="d-flex justify-content-center pt-4" *ngIf="f.url_rfc_documentation.value">
				<div class="col-md-4 col-sm-12">
					<label for="name">{{ "application.rfc" | translate }}: <strong *ngIf="f.rfc.value"> {{ f.rfc.value }}</strong></label>
					<img  class="img-fluid" src="{{ f.url_rfc_documentation.value }}">
				</div>
			</div>
			<div class="form-group">
				<label for="active"> {{ "application.approved" | translate }}:</label><br>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null"
						formControlName="approval" type="radio" name="approval" id="approval1"
						value="1">
					<label class="form-check-label" for="approval1">{{ "form.active.yes" | translate}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null"
						formControlName="approval" type="radio" name="approval" id="approval2"
						value="0">
					<label class="form-check-label" for="approval2">{{  "form.active.no" | translate}}</label>
				</div>
			</div>
			<div class="form-group" *ngIf="f.approval.value == '0'">
				<label for="rejection_reason">{{ "application.rejection_reason" | translate }}:</label>
				<textarea class="form-control" [readonly]="view()" formControlName="rejection_reason"
					[ngClass]="{ 'is-invalid': submitted && f.rejection_reason.errors }" rows="3"></textarea>
				<div *ngIf="submitted && f.rejection_reason.errors" class="invalid-feedback">
					<div *ngIf="submitted && f.rejection_reason.errors.required">{{ "application.errors.rejection_reason" | translate }}</div>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button class="btn btn-danger mr-3" routerLink="/admin/application">{{ "form.cancel" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="create()">{{ "form.save" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="edit()">{{ "form.update" | translate }}</button>
			</div>
		</form>
	</div>
</div>