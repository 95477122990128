import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCalendarAlt, faTicketAlt, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { RaffleService } from 'src/app/raffle-module/raffle.service';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.css']
})
export class MyTicketsComponent implements OnInit {

  raffles: any = [];

  public faTicketAlt = faTicketAlt;
  public faTrophy = faTrophy;
  public faCalendarAlt = faCalendarAlt;

  constructor(
    private raffleService: RaffleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.raffleService.record().subscribe((record) => {
      this.raffles = record.data;
      this.raffles.forEach(rifa => {
        rifa.winner = false;
        rifa.buyed_tickets.forEach(boletos => {
          if (boletos.winner == 1) {
            rifa.winner = true;
          }
        });
      });
    });
  }

  customShow(id, slug, winner) {
    if (winner === false) {
      this.router.navigate(['/raffle/' + id + '/' + slug]);
    } else {
      this.router.navigate(['/raffle/' + id + '/update-delivery-information']);
    }
  }

  getFormatDate(date) {
    let day = new Date(date).toLocaleDateString('es', { weekday: 'short' });
    day = day.replace(/^\w/, (c) => c.toUpperCase());
    const month = new Date(date).toLocaleDateString('es', { month: 'short' });
    const numDay = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return day + ', ' + numDay + ' ' + month + ', ' + year;
  }
}
