import { Component, NgZone, OnInit, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';

import { 
  faWallet,
  faFileExcel
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faDownload, faHome } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { AG_GRID_LOCALE_EN } from 'src/app/shared/list/locale/en.locale';
import { ReportService } from '../report.service';
import { AG_GRID_LOCALE_ES } from 'src/app/shared/list/locale/es.locale';

@Component({
  selector: 'app-wallet-recharges',
  templateUrl: './wallet-recharges.component.html',
  styleUrls: ['./wallet-recharges.component.css'],
  providers: [ListService, DecimalPipe, CurrencyPipe]
})
export class WalletRechargesComponent extends ListComponent implements OnInit {

  faHome = faHome;
  faDownload = faDownload;
  faFileExcel = faFileExcel;
  faWallet = faWallet;

  constructor(
    public service: RaffleService,
		public reportService: ReportService,
		public listService: ListService,
		public translateService: TranslateService,
		public auth: AuthService,
		private zone: NgZone,
		public currencyPipe: CurrencyPipe,
		public decimalPipe: DecimalPipe
    ) {
      super(service, listService);
		  this.setObject('report');
    }

    ngOnInit() {
      super.ngOnInit();
      this.gridLocale = AG_GRID_LOCALE_ES;
      this.gridOptions = {
        defaultColDef: {
          resizable: true,
        },
        columnDefs: [
          { headerName: '#', field: 'id', sortable: true, width: 60 },
          { 
            headerName: 'recharges.amount',
            field: 'amount',
            sortable: true,
            headerValueGetter: this.localizeHeader.bind(this),
            cellRenderer: params =>
              this.currencyPipe.transform(params.data.amount, 'USD', 'symbol', '1.2-2')
            ,
          },
          { 
            headerName: 'recharges.fee',
            field: 'commission',
            sortable: true,
            headerValueGetter: this.localizeHeader.bind(this),
            cellRenderer: params =>
              this.currencyPipe.transform(params.data.commission, 'USD', 'symbol', '1.2-2')
            ,
          },
          { headerName: 'recharges.user_id', field: 'user_id', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
          { headerName: 'recharges.user', field: 'nickname', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
          { headerName: 'recharges.date', field: 'created_at', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
        ]
      };
  
      this.loadData();
    }
  
    onFilterTextBoxChanged($event): void {
      this.gridApi.setQuickFilter($event.target.value);
    }
  
    onGridReady(params) {
      this.gridApi = params.api;
        this.gridApi.refreshHeader();
      this.gridApi.sizeColumnsToFit();
      window.onresize = () => {
        this.gridApi.sizeColumnsToFit();
      }
    }
  
    loadData(){
      this.reportService.recharges().subscribe((data) => {
        this.data = data;
        this.listService.setData(data);
      }, (error) => {
        this.alertify.error(this.translate.instant('general.list.load_error'));
      });
    }
  
    public getExportUrlGeneral() {
      return environment.baseUrl + 'export/wallet-recharges';
    }

}
