import { Component, OnInit } from '@angular/core';
import { FormComponent } from '../../../shared/form/form.component';
import { RafflerPaymentService } from '../raffler-payment.service';
import { Validators } from '@angular/forms';
import { ImageCroppedEvent, Dimensions } from 'ngx-image-cropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { faDollarSign, faDownload } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-raffler-payment-form',
	templateUrl: './raffler-payment-form.component.html',
	styleUrls: ['./raffler-payment-form.component.css']
})
export class RafflerPaymentFormComponent extends FormComponent implements OnInit {
	public imageUrl;
	images = [];
	currentSlideIndex = 0;
	urlImage: any;
	loading = false;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	showCropper = false;
	isProvider = false;
	fileToUpload: File = null;
	imgResultBeforeCompress: string;
	imgResultAfterCompress: string;
	faDollarSign = faDollarSign;
	faDownload = faDownload;

	constructor(
		public rafflerPaymentService: RafflerPaymentService,
		public imageCompress: NgxImageCompressService
	) {
		super(rafflerPaymentService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.redirect = '/admin/raffler-payment';
		this.form = this.formBuilder.group({
			status: ['', Validators.required],
			evidence_url: [''],
			comments: [''],
		});
	}

	dataToForm(data) {
		this.data = data;
		this.imageUrl = data.evidence_url;
		return {
			status: data.status,
			evidence_url: data.evidence_url,
			comments: data.comments,
		};
	}

	formToData() {
		this.data = {
			status: this.f.status.value,
			evidence_url: this.imageUrl,
			comments: this.f.comments.value,
		};
	}

	fileChangeEvent(event: any): void {
		const FileSize = event.target.files.item(0).size / 1024 / 1024; // in MB
		if (FileSize > 10) {
			event.value = '';
			this.alertify.error('El tamaño del archivo excede los 10 MB');
			return;
		}
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		this.showCropper = true;
	}

	endCrop() {
		this.showCropper = false;
		var size = this.imageCompress.byteCount(this.croppedImage) / 1024 / 1024;
		var ratio = 50;
		if (size > 1) {
			if (size > 5) {
				ratio = 30;
			}

			if (size > 10) {
				ratio = 20;
			}

			this.imageCompress.compressFile(this.croppedImage, -1, ratio, 50).then(
				result => {
					const formData: FormData = new FormData();
					let fileToUp = this.dataURLtoFile(result, 'image');
					formData.append('file', fileToUp);
					this.loading = true;
					this.rafflerPaymentService.uploadEvidence(formData, this.currentID).subscribe((data) => {
						this.loading = false;
						let d = { url_image: data.evidence_url };
						this.images.push(d);
						this.imageUrl = data.evidence_url;
					}, (error) => {
						this.loading = false;
						this.alertify.error('Ocurrió un error al subir la imagen al servidor');
					});
				}
			);

		} else {
			const formData: FormData = new FormData();
			let fileToUp = this.dataURLtoFile(this.croppedImage, 'image');
			formData.append('file', fileToUp);
			this.loading = true;
			this.rafflerPaymentService.uploadEvidence(formData, this.currentID).subscribe((data) => {
				this.loading = false;
				let d = { url_image: data.evidence_url, default: false };
				this.imageUrl = data.evidence_url;
				this.images.push(d);
			}, (error) => {
				this.loading = false;
				this.alertify.error('Ocurrió un error al subir la imagen al servidor');
			});
		}
	}

	cropperReady(sourceImageDimensions: Dimensions) {
		// 
	}

	loadImageFailed() {
		// show message
	}

	checkUpload() {
		if (this.showCropper) {
			this.alertify.error('Presione el boton listo, para recortar la imagen');
			return;
		}

		if (this.fileToUpload != null) {
			const formData: FormData = new FormData();
			formData.append('file', this.fileToUpload);
			this.loading = true;
			this.rafflerPaymentService.uploadEvidence(formData, this.currentID).subscribe((data) => {
				this.loading = false;
				this.urlImage = data.evidence_url;
				this.onSubmit();
			}, (error) => {
				this.loading = false;
				this.alertify.error('Ocurrió un error al subir la imagen al servidor');
			});
		} else {
			this.onSubmit();
		}

	}

	onSubmit() {
		this.submitted = true;

		if (this.form.invalid || !this.validation() ||
			(this.f.status.value === 'accepted' && !this.imageUrl) ||
			(this.f.status.value === 'rejected' && (this.f.comments.value == '' || this.f.comments.value == undefined) )
		) {
			this.alertify.error(this.translate.instant('general.form.complete_fields'));
			return;
		}

		this.formToData();

		if (this.create()) {
			this.save();
		}

		if (this.edit()) {
			this.update();
		}
	}

	confirmDeleteImage(c) {
		let i = parseInt(c.activeId);
		this.alertify.confirm('Eliminar', '¿Esta seguro de eliminar esta imagen?', () => this.deleteImage(i));
	}

	deleteImage(index) {
		this.images.splice(index, 1);
	}

	checkDefaultImage(c) {
		let i = parseInt(c.activeId);
		return this.images[i].default;
	}

	checkChangeDefaultValue(c) {

		let i = parseInt(c.activeId);
		this.images.forEach((image, index) => {
			if (index != i) {
				image.default = false;
			} else {
				image.default = true;
			}
		});
	}
}
