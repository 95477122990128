import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { Component, OnInit } from '@angular/core';
import { faPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-raffles',
  templateUrl: './profile-raffles.component.html',
  styleUrls: ['./profile-raffles.component.css']
})
export class ProfileRafflesComponent implements OnInit {

  raffles: any = [];

  public faPlus = faPlus;
  public faCalendarAlt = faCalendarAlt;

  constructor(
    public raffleService: RaffleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.raffleService.admin().subscribe((data) => {
      this.raffles = data;
    });
  }

  customShow(id, slug) {
    this.router.navigate(['profile/raffles/' + id + '/' + slug]);
  }

  getFormatDate(date) {
    let day = new Date(date).toLocaleDateString('es', { weekday: 'short' });
    day = day.replace(/^\w/, (c) => c.toUpperCase());
    const month = new Date(date).toLocaleDateString('es', { month: 'short' });
    const numDay = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return day + ', ' + numDay + ' ' + month + ', ' + year;
  }

}
