import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './../shared/crud.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WalletService extends CrudService {

	constructor(protected http: HttpClient) {
		super(http);
		this.setObject('wallet');
	}

	wallet(): Observable<any> {
		return this.http.get(this.apiUrl + '/wallet');
	}

  	deposit(data): Observable<any> {
		return this.http.post(this.apiUrl + '/wallet/deposit',data);
	}
}
