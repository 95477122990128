<div class="bodycards">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-auto">
                <h2 class="text-capitalize text-orange">{{ "nav.myAddress" | translate }}</h2>
            </div>
        </div>
        <div *ngIf="totalAddress == 0" class="text-center font-weight-bold green-color text-lg">
            {{ "general.result_not_found" | translate }}
        </div>
        <div class="container" *ngIf="address && address.length > 0">
            <div class="card mb-3" *ngFor="let a of address" (click)="editAddress(a.id)">
                <div class="row no-gutters">
                    <div class="col-md-2">
                        <div class="text-center p-5">
                            <fa-icon [icon]="faHome" class="h1 text-secundary-back-2"></fa-icon>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="card-body">
                            <h5 class="card-title">{{ a.name }}</h5>
                            <p class="card-text">{{ a.city }} {{ a.cp }}</p>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="text-center p-5">
                            <fa-icon [icon]="faAngleRight" class="h1 text-orange"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col text-center p-3">
            <button type="submit" class="btn rounded-pill font-weight-bold create" (click)="createAddress()">Agregar dirección</button>
        </div>
    </div>
</div>