<div class="bodycards">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-auto">
                <h2 class="text-capitalize text-orange">Mis rifas</h2>
            </div>
        </div>
        <div class="container" *ngIf="raffles && raffles.length > 0">
            <div class="card mb-3" *ngFor="let raffle of raffles" (click)="customShow(raffle.id, raffle.slug)">
                <div class="row g-0">
                    <div class="col-md-3">
                        <div class="text-center p-3">
                            <img class="img-fluid img-thumbnail" [src]="raffle.image">
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="card-body">
                            <h5 class="card-title text-capitalize text-sm">
                                <fa-icon [icon]="faCalendarAlt" class="date-color"></fa-icon>
                                {{ raffle.status === 'pending' ? 'Pendiente' : raffle.status === 'raffled' ? 'Realizada'
                                : raffle.status === 'not_raffled' ? 'No realizada' : raffle.status === 'canceled' ?
                                'Cancelada' : '' }}
                                {{ getFormatDate(raffle.finish) }}
                                <hr>
                            </h5>
                            <div class="row g-0">
                                <div class="col-md-10">
                                    <p class="card-text font-weight-bold">${{raffle.ticket_price}}</p>
                                    <p class="card-text">{{raffle.description}}</p>
                                </div>
                                <div class="col-md-2">
                                    <circle-progress
                                        percent="{{(raffle.sold_tickets / raffle.num_tickets)*100}}"
                                        [radius]="42"
                                        [outerStrokeWidth]="20"
                                        [innerStrokeWidth]="6"
                                        [outerStrokeColor]="'#49A446'"
                                        [innerStrokeColor]="'#C7E596'"
                                        [animation]="true"
                                        [animationDuration]="300"
                                        [titleFontSize]="18"
                                        [backgroundPadding]="5"
                                        [maxPercent]="100"
                                        [showSubtitle] = false
                                        [showInnerStroke]=true
                                        [outerStrokeWidth]=6
                                        [titleFontWeight]=700
                                        [unitsFontSize]="18"
                                        [unitsFontWeight]=700
                                        [space]=-6
                                        [innerStrokeColor]="'#CACDCA'"
                                    >
                                    </circle-progress>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col p-1 text-center">
                <button class="btn btn-sec rounded-pill font-weight-bold" routerLink="/raffle/create">
                    <fa-icon [icon]="faPlus"></fa-icon> Crear rifa
                </button>
            </div>
        </div>
    </div>
