import { HttpClient } from '@angular/common/http';
import { CrudService } from './../shared/crud.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends CrudService{
  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('address');
  }

  index(): Observable<any> {
		return this.http.get(this.apiUrl + '/address');
	}

  update(data: any): Observable<any> {
    return this.http.post(this.apiUrl + `/address/${data.id}`, data);
  }
}
