import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { faCalendarAlt, faInfoCircle, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FormComponent } from 'src/app/shared/form/form.component';
import { RaffleService } from '../raffle.service';
import { CategoryService } from 'src/app/admin/category/category.service';
import { CatalogService } from 'src/app/shared/catalog.service';
import { NgbDate, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent, Dimensions } from 'ngx-image-cropper';

const MIN_PERCENTAGE_AMOUNT = 150;
const MAX_PERCENTAGE_AMOUNT = 300;
const RAFIKI_PERCENTAGE_COMISSION = 20;
const CONEKTA_FIXED_COMISSION_MXN = 2.5;
const CONEKTA_PERCENTAGE_COMISSION = 2.9;
const IVA_PERCENTAGE = 116;

@Component({
	selector: 'app-raffle-form',
	templateUrl: './raffle-form.component.html',
	styleUrls: ['./raffle-form.component.css'],
	providers: [NgbDatepickerConfig]
})
export class RaffleFormComponent extends FormComponent implements OnInit {
	categories = [];
	subcategories = [];
	states = [];
	products = [];
	public faInfoCircle = faInfoCircle;
	public faCalendarAlt = faCalendarAlt;
	faTicketAlt = faTicketAlt;

	minAmount = 0;
	maxAmount = 0;

	validAmount = true;
	showDetailInformation = false;
	suggestedAvailable = true;

	raffleOriginal;

	detail = {
		total: 0,
		comissionTicket: 0,
		comissionTotal: 0,
		comissionRafiki: 0,
		totalLessComissions: 0,
		profit: 0,
	};

	images = [];
	currentSlideIndex = 0;
	urlImage: any;
	loading = false;
	imageChangedEvent: any = '';
	croppedImage: any = '';
	showCropper = false;
	isProvider = false;
	fileToUpload: File = null;
	imgResultBeforeCompress: string;
	imgResultAfterCompress: string;

	constructor(
		public service: RaffleService,
		public categoryService: CategoryService,
		public catalogsService: CatalogService,
		public config: NgbDatepickerConfig,
		public modalService: NgbModal,
		public imageCompress: NgxImageCompressService) {
		super(service);
		config.minDate = { year: 1980, month: 1, day: 1 };
	}

	ngOnInit() {
		super.ngOnInit();
		this.redirect = '/raffle';
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			brand: [''],
			model: [''],
			prize_value: ['', Validators.required],
			total_amount: [''],
			suggested_value: [''],
			num_tickets: ['', Validators.required],
			ticket_price: ['', Validators.required],
			category_id: [{ value: '', disabled: this.view() }, Validators.required],
			subcategory_id: [{ value: '', disabled: this.view() }, Validators.required],
			description: [''],
			product_type: [{ value: '', disabled: this.view() }, Validators.required],
			prize_type: [{ value: '', disabled: this.view() }, Validators.required],
			cat_state_id: [{ value: '', disabled: this.view() }, Validators.required],
			city: ['', Validators.required],
			start: ['', Validators.required],
			start_time: ['', Validators.required],
			finish: ['', Validators.required],
			finish_time: ['', Validators.required],
			active: [{ value: '', disabled: this.view() }, Validators.required],
		});
	}

	getExtras() {
		this.categoryService.all().subscribe((response) => {
			this.categories = response;
			if (!this.create()) {
				this.changeCategory();
			}
		}, (error) => {
			this.alertify.error('No fue posible obtener las categorias');
		});

		this.catalogsService.states().subscribe((response) => {
			this.states = response;
		}, (error) => {
			this.alertify.error('No fue posible obtener las categorias');
		});
	}

	dataToForm(data) {
		this.raffleOriginal = data;

		const startDate = this.strToObjDate(data.start);
		const startTime = this.strToObjTime(data.start);
		const endDate = this.strToObjDate(data.finish);
		const endTime = this.strToObjTime(data.finish);

		this.images = data.images;

		return {
			name: data.name,
			brand: data.brand,
			model: data.model,
			description: data.description,
			prize_value: data.prize_value,
			total_amount: data.num_tickets * data.ticket_price,
			suggested_value: data.suggested_value,
			num_tickets: data.num_tickets,
			ticket_price: data.ticket_price,
			category_id: data.category_id,
			subcategory_id: data.subcategory_id,
			product_type: data.product_type,
			prize_type: data.prize_type,
			cat_state_id: data.cat_state_id,
			city: data.city,
			start: startDate,
			start_time: startTime,
			finish: endDate,
			finish_time: endTime,
			active: '' + data.active
		};
	}

	formToData() {
		this.data = {
			name: this.f.name.value,
			description: this.f.description.value,
			active: this.f.active.value,
			brand: this.f.brand.value,
			model: this.f.model.value,
			prize_value: this.f.prize_value.value,
			suggested_value: this.f.suggested_value.value,
			num_tickets: this.f.num_tickets.value,
			ticket_price: this.f.ticket_price.value,
      category_id: this.f.category_id.value,
			subcategory_id: this.f.subcategory_id.value,
			product_type: this.f.product_type.value,
			prize_type: this.f.prize_type.value,
			cat_state_id: this.f.cat_state_id.value,
			city: this.f.city.value,
			start: this.objDateToStr(this.f.start.value, this.f.start_time.value),
			finish: this.objDateToStr(this.f.finish.value, this.f.finish_time.value),
			images: this.images
		};
	}

	searchPrices() {
		if (this.f.name.value == '' || this.f.name.value == null) {
			this.alertify.error('Agregue el nombre del producto');
			return;
		}
		this.service.searchMercadoLibre(this.f.name.value).subscribe((response) => {

			this.products = response.results;
			let count = this.products.length;

			if (count == 0) {
				this.f.suggested_value.setValue(0);
				this.suggestedAvailable = false;
				return;
			}
			let sumPrices = 0;
			this.products.forEach((product) => {
				sumPrices += product.price;
			});
			let avg = sumPrices / count;
			this.f.suggested_value.setValue(avg.toFixed(2));
			this.suggestedAvailable = true;

		}, (error) => {
			this.alertify.error('No se obtuvieron resultados');
		});
	}

	checkLimitAmounts() {
		if (this.f.prize_value.value == '') {
			this.minAmount = 0;
			this.maxAmount = 0;
			return;
		}

		this.minAmount = (parseInt(this.f.prize_value.value) * MIN_PERCENTAGE_AMOUNT) / 100;
		this.maxAmount = (parseInt(this.f.prize_value.value) * MAX_PERCENTAGE_AMOUNT) / 100;
	}

	checkPercentagePrizeValue() {
		if (this.f.prize_value.value == '' || this.f.total_amount.value == '') {
			this.validAmount = true;
			return true;
		}

		let percentage = (parseInt(this.f.total_amount.value) / parseInt(this.f.prize_value.value)) * 100;

		if (percentage >= MIN_PERCENTAGE_AMOUNT && percentage <= MAX_PERCENTAGE_AMOUNT) {
			this.validAmount = true;
			return true;
		}

		this.validAmount = false;
		return false;
	}

	checkTicketPrice(changed) {
		let price = 0;
		let number = 0;
		this.paymentDetailInformation();

		if (this.f.total_amount.value == '') {
			return;
		}


		if (changed == 'number') {
			if (this.f.num_tickets.value == '' || this.f.num_tickets.value == null) {
				return;
			}

			price = parseInt((this.f.total_amount.value / this.f.num_tickets.value) + '');

			this.f.ticket_price.setValue(price);
			this.paymentDetailInformation();

		}

		if (changed == 'price') {
			if (this.f.ticket_price.value == '' || this.f.ticket_price.value == null) {
				return;
			}

			number = parseInt((this.f.total_amount.value / this.f.ticket_price.value) + '');

			this.f.num_tickets.setValue(number);
			this.paymentDetailInformation();
		}
	}

	suggestTicketPrice() {
		//TODO: modificar en base a las reglas que nos de sergio guzman
		let number = 100;
		let price = 0;
		if ((this.f.num_tickets.value == '' || this.f.num_tickets.value == null)
			&& (this.f.ticket_price.value == '' || this.f.ticket_price.value == null)
		) {
			price = parseInt((this.f.total_amount.value / number) + '');

			this.f.num_tickets.setValue(number);
			this.f.ticket_price.setValue(price);
		} else if (this.f.num_tickets.value != '' && this.f.num_tickets.value != null) {
			price = parseInt((this.f.total_amount.value / this.f.num_tickets.value) + '');

			this.f.ticket_price.setValue(price);
		} else if (this.f.ticket_price.value != '' && this.f.ticket_price.value != null) {
			number = parseInt((this.f.total_amount.value / this.f.ticket_price.value) + '');

			this.f.num_tickets.setValue(number);
		}

		this.paymentDetailInformation();
	}

	paymentDetailInformation() {
		if ((this.f.ticket_price.value != '' && this.f.ticket_price.value != null) &&
			(this.f.num_tickets.value != '' && this.f.num_tickets.value != null) &&
			(this.f.total_amount.value != '' && this.f.total_amount.value != null) &&
			(this.f.prize_value.value != '' && this.f.prize_value.value != null)
		) {
			this.detail.total = this.f.ticket_price.value * this.f.num_tickets.value;
			this.detail.comissionRafiki = (this.detail.total * RAFIKI_PERCENTAGE_COMISSION) / 100;
			this.detail.comissionTicket =
				(CONEKTA_FIXED_COMISSION_MXN +
					(this.f.ticket_price.value * (CONEKTA_PERCENTAGE_COMISSION / 100))) *
				(IVA_PERCENTAGE / 100);
			this.detail.comissionTotal = this.detail.comissionTicket * this.f.num_tickets.value;
			this.detail.totalLessComissions = this.detail.total - this.detail.comissionRafiki - this.detail.comissionTotal;
			this.detail.profit = this.detail.totalLessComissions - this.f.prize_value.value;
			this.showDetailInformation = true;
		} else {
			this.detail.total = 0;
			this.detail.comissionRafiki = 0;
			this.detail.comissionTicket = 0;
			this.detail.comissionTotal = 0;
			this.detail.totalLessComissions = 0;
			this.detail.profit = 0;
			this.showDetailInformation = false;
		}
	}

	information(modal) {
		if (this.products.length == 0) {
			this.alertify.error('No se tienen productos para mostrar');
			return;
		}
		this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	changeCategory() {
		this.categoryService.subcategories(this.f.category_id.value).subscribe((response) => {
			this.subcategories = response;
		}, (error) => {
			this.alertify.error('No fue posible cargar las subcategorias');
		});
	}

	public openCalendar(d) {
		d.toggle();
		return false;
	}

	public strToObjDate(date) {
		const dateTime = date.split(' ');
		const dateParts = dateTime[0].split('-');
		return new NgbDate(parseInt(dateParts[0]), parseInt(dateParts[1]), parseInt(dateParts[2]));
	}

	public strToObjTime(date) {
		const dateTime = date.split(' ');
		const timeParts = dateTime[1].split(':');
		return {
			hour: parseInt(timeParts[0]),
			minute: parseInt(timeParts[1])
		};
	}

	public objDateToStr(obj, time) {
		if (!obj) {
			return '00-00-00 00:00:00';
		}
		return obj.year + '-' + obj.month + '-' + obj.day
			+ ' ' + time.hour + ':' + (time.minute == '0' ? '00' : time.minute) + ':00';
	}

	fileChangeEvent(event: any): void {
		const FileSize = event.target.files.item(0).size / 1024 / 1024; // in MB
		if (FileSize > 10) {
			event.value = '';
			this.alertify.error('El tamaño del archivo excede los 10 MB');
			return;
		}
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		this.showCropper = true;
	}

	endCrop() {
		this.showCropper = false;
		var size = this.imageCompress.byteCount(this.croppedImage) / 1024 / 1024;
		var ratio = 50;
		if (size > 1) {
			if (size > 5) {
				ratio = 30;
			}

			if (size > 10) {
				ratio = 20;
			}

			this.imageCompress.compressFile(this.croppedImage, -1, ratio, 50).then(
				result => {
					const formData: FormData = new FormData();
					let fileToUp = this.dataURLtoFile(result, 'image');
					formData.append('file', fileToUp);
					this.loading = true;
					this.service.upload(formData).subscribe((data) => {
						this.loading = false;
						let d = { url_image: data.url };
						this.images.push(d);
					}, (error) => {
						this.loading = false;
						this.alertify.error('Ocurrió un error al subir la imagen al servidor');
					});
				}
			);

		} else {
			const formData: FormData = new FormData();
			let fileToUp = this.dataURLtoFile(this.croppedImage, 'image');
			formData.append('file', fileToUp);
			this.loading = true;
			this.service.upload(formData).subscribe((data) => {
				this.loading = false;
				let d = { url_image: data.url, default: false };
				this.images.push(d);
			}, (error) => {
				this.loading = false;
				this.alertify.error('Ocurrió un error al subir la imagen al servidor');
			});
		}
	}

	cropperReady(sourceImageDimensions: Dimensions) {
		//
	}

	loadImageFailed() {
		// show message
	}

	checkUpload() {
		if (this.showCropper) {
			this.alertify.error('Presione el boton listo, para recortar la imagen');
			return;
		}

		if (this.fileToUpload != null) {
			const formData: FormData = new FormData();
			formData.append('file', this.fileToUpload);
			this.loading = true;
			this.service.upload(formData).subscribe((data) => {
				this.loading = false;
				this.urlImage = data.url;
				this.onSubmit();
			}, (error) => {
				this.loading = false;
				this.alertify.error('Ocurrió un error al subir la imagen al servidor');
			});
		} else {
			this.onSubmit();
		}

	}

	confirmDeleteImage(c) {
		let i = parseInt(c.activeId);
		this.alertify.confirm('Eliminar', '¿Esta seguro de eliminar esta imagen?', () => this.deleteImage(i));
	}

	deleteImage(index) {
		this.images.splice(index, 1);
	}

	checkDefaultImage(c) {
		let i = parseInt(c.activeId);
		return this.images[i].default;
	}

	checkChangeDefaultValue(c) {

		let i = parseInt(c.activeId);
		this.images.forEach((image, index) => {
			if (index != i) {
				image.default = false;
			} else {
				image.default = true;
			}
		});
	}

	slideChange(slide) {
		this.currentSlideIndex = slide.current;
	}

	createValidationTicket() {
		if (this.f.active.value == 1) {
			this.alertify.confirm(
				'Rifa activa',
				'Al activar la rifa ya no se permitirá editar ningun dato de la misma, ¿Esta seguro de activar la rifa?',
				() => {
					this.checkUpload();
				}
			);
		} else {
			this.checkUpload();
		}
	}

	updateValidationTicket() {
		if (this.raffleOriginal.active == 0 && this.f.active.value == 1) {
			this.alertify.confirm(
				'Rifa activa',
				'Al activar la rifa ya no se permitirá editar ningun dato de la misma, ¿Esta seguro de activar la rifa?',
				() => {
					this.checkUpload();
				}
			);
		} else {
			this.checkUpload();
		}

	}

	checkActive() {
		if (this.create()) {
			this.createValidationTicket();
		} else if (this.edit()) {
			this.updateValidationTicket();
		}
	}
}
