<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faExclamationCircle" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate
				}}</a>
		</li>
		<li class="breadcrumb-item"><a routerLink="/admin/raffle-report" class="text-primary-color-3">{{ "admin.reports" |
				translate }}</a>
		</li>
		<li class="breadcrumb-item active" aria-current="page">{{ (view() ? "raffle_report.view" : (create() ?
			"raffle_report.create" : "raffle_report.edit" )) | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-12">
		<form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
			<div class="d-flex justify-content-center">
				<div class="col-md-6 text-center">
					<h5>{{ "admin.reports" | translate }}</h5>
				</div>
			</div>
			<div class="form-group">
				<label for="raffle">{{ "raffle_report.raffle" | translate }}:</label>
				<input class="form-control" [readonly]="view() || edit()" formControlName="raffle" type="text">
			</div>
			<div class="form-group">
				<label for="user">{{ "raffle_report.user" | translate }}:</label>
				<input class="form-control" [readonly]="view() || edit()" formControlName="user" type="text">
			</div>
			<div class="form-group">
				<label for="comments">{{ "raffle_report.comments" | translate }}:</label>
				<textarea class="form-control" [readonly]="view() || edit()" formControlName="comments" rows="3"></textarea>
			</div>
			<div class="form-group" *ngIf="view()">
				<label for="admin">{{ "raffle_report.admin" | translate }}:</label>
				<input class="form-control" [readonly]="view()" formControlName="admin" type="text">
			</div>
			<div class="form-group">
				<label for="active"> {{ "raffle_report.status.label" | translate }}:</label><br>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status"
						type="radio" name="status" id="status1" value="pending">
					<label class="form-check-label" for="status1">{{ "raffle_report.status.pending" | translate
						}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status"
						type="radio" name="status" id="status2" value="accepted">
					<label class="form-check-label" for="status2">{{ "raffle_report.status.accepted" | translate
						}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null" formControlName="status"
						type="radio" name="status" id="status3" value="rejected">
					<label class="form-check-label" for="status3">{{ "raffle_report.status.rejected" | translate
						}}</label>
				</div>
			</div>
			<div class="form-group">
				<label for="admin_comments">{{ "raffle_report.admin_comments" | translate }}:</label>
				<textarea class="form-control" [readonly]="view()" formControlName="admin_comments" rows="3" [ngClass]="{ 'is-invalid': submitted && f.admin_comments.errors }">
				</textarea>
				<div *ngIf="submitted && f.admin_comments.errors" class="invalid-feedback">
					<div *ngIf="submitted && f.admin_comments.errors.required">{{ "raffle_report.errors.admin_comments" | translate
						}}</div>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button class="btn btn-danger mr-3" routerLink="/admin/raffle-report">{{ "form.cancel" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="create()">{{ "form.save" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="edit()">{{ "form.update" | translate }}</button>
			</div>
		</form>
	</div>
</div>