<div class="d-flex justify-content-center pt-3 bg-cards" >
    <div class="container">
      <div class="row justify-content-around" >
          <div class="col-auto font-weight-bold text-orange h3 ml-2">
              <p> {{ "nav.cards" | translate }} </p>
            </div>
            <div class="col-auto">
              <div class="mb-4">
                <button class="btn btn-sec rounded-pill" (click)="createCardModal(createCard)">{{ "card.create" | translate }}</button>
              </div>
          </div>
      </div>
      <div class="row justify-content-around">
        <div class="col-md-9 mb-3 card-list"  *ngFor="let item of data; let i = index;">
          <div class="div-activity">
              <div class="row align-items-center justify-content-center">
                <div class="col-auto">
                  <div class="text-primary-color-4">
                    {{ item.type_formatted }}
                </div>
                </div>
                <div class="col-3">
                    <div class="float-left text-left">
                        <div class="label-activity-name font-weight-bold">
                            {{ item.number_formatted }}
                        </div>
                        <label class="label-activity-name">
                          {{ item.alias }}
                        </label>
                    </div>
                </div>
                <div class="col-3">
                  <div class="float-right text-right">
                      <div (click)="editModal(editCard,item,i)" class="link-pointer label-activity-name font-weight-bold text-primary-color-4">
                        {{ "form.update" | translate }}
                      </div>
                      <div (click)="deleteModal(deleteCardModal,item,i)" class="link-pointer font-weight-bold text-danger">
                        {{ "form.delete" | translate }}
                      </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div *ngIf="data?.length == 0">
          {{ "card.empty" | translate }}
        </div>
      </div>
    </div>
  </div> 
  
  <ng-template #editCard let-modal>
    <div class="modal-body">
      <div class="row height-100">
        <div class="col-12">
          <div class="row justify-content-center align-items-center">
            <div class="col-12" >
              <div>
                <h5 class="text-success text-center">{{ "card.update" | translate }}</h5>
              </div>
            </div>
            <div class="col-12 px-5">
              <form>
                <div class="form-group pb-2">
                  <div>
                    <label for="">{{ "card.form.alias" | translate }}:</label>
                    <input
                      type="text"
                      [(ngModel)]="currentAlias"
                      [ngModelOptions]="{standalone: true}"
                      placeholder="{{ 'card.form.alias' | translate }}"
                      class="form-control"
                    />
                  </div>
                </div>
                <label class="label-activity-name font-weight-bold text-success pb-4">
                  {{ "card.termination" | translate }}: {{ currentCard.number }} ({{ currentCard.type_formatted }})
                </label>
                <div class="text-center pb-4">
                  <div class="form-group">
                    <button
                      class="btn btn-sec text-white font-weight-bold btn-block rounded-pill"
                      type="button"
                      (click)="updateCard()"
                    >
                    {{ "form.update" | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #deleteCardModal let-modal>
    <div class="modal-body">
      <div class="row height-100">
        <div class="col-12">
          <div class="row justify-content-center align-items-center">
            <div class="col-12" >
              <div>
                <h5 class="text-success text-center">{{ "card.delete" | translate }}</h5>
              </div>
            </div>
            <div class="col-12 px-5">
              <form>
                <label class="h5 label-activity-name font-weight-bold pb-2">{{ "card.confirmation_delete" | translate }} </label>
                <label class="label-activity-name font-weight-bold">{{ currentCard.alias }} </label>
                <br/>
                <label class="label-activity-name font-weight-bold text-success pb-4">
                  {{ "card.termination" | translate }}: {{ currentCard.number }} ({{ currentCard.type_formatted }})
                </label>
                
                <div class="text-center form-inline pb-4">
                  <div class="form-group col-6">
                    <button
                      class="btn btn-outline-sec font-weight-bold btn-block rounded-pill"
                      type="button"
                      (click)="modal.dismiss('Cross click')"
                    >
                    {{ "form.cancel" | translate }}
                    </button>
                  </div>
                  <div class="form-group col-6">
                    <button
                      class="btn btn-danger text-white font-weight-bold btn-block rounded-pill"
                      type="button"
                      (click)="deleteCard()"
                    >
                    {{ "form.delete" | translate }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <ng-template #createCard let-modal>
    <div class="modal-body">
      <div class="row height-100">
        <div class="col-12">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 pb-4">
              <app-create-card action="create" (updated)="createResponse($event)" (cancel)="cancelCreateCardAction($event)"></app-create-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
      
