<nav aria-label="breadcrumb">
	<ol class="breadcrumb nav-breadcrumb pl-5">
		<li><fa-icon [icon]="faClipboard" class="mr-2 h3"></fa-icon></li>
		<li class="breadcrumb-item"><a routerLink="/admin" class="text-primary-color-3">{{ "home.home" | translate }}</a>
		</li>
		<li class="breadcrumb-item"><a routerLink="/admin/category" class="text-primary-color-3">{{ "admin.categories" | translate }}</a>
		</li>
		<li class="breadcrumb-item active" aria-current="page">{{ (view() ? "category.view" : (create() ? "category.create" : "category.edit" )) | translate }}</li>
	</ol>
</nav>
<div class="d-flex justify-content-center pt-3">
	<div class="col-md-12">
		<form [formGroup]="form" (ngSubmit)="onSubmit()" class="bg-form shadow">
			<div class="d-flex justify-content-center">
				<div class="col-md-6 text-center">
					<h5>{{ "admin.categories" | translate }}</h5>
				</div>
			</div>
			<div class="form-group">
				<label for="name">{{ "category.name" | translate }}:</label>
				<input class="form-control" [readonly]="view()" formControlName="name" type="text"
					[ngClass]="{ 'is-invalid': submitted && f.name.errors }">
				<div *ngIf="submitted && f.name.errors" class="invalid-feedback">
					<div *ngIf="f.name.errors.required">{{ "category.errors.name" | translate }}</div>
				</div>
			</div>
			<div class="form-group">
				<label for="description">{{ "category.description" | translate }}:</label>
				<textarea class="form-control" [readonly]="view()" formControlName="description"
					[ngClass]="{ 'is-invalid': submitted && f.description.errors }" rows="3"></textarea>
				<div *ngIf="submitted && f.description.errors" class="invalid-feedback">
					<div *ngIf="submitted && f.description.errors.required">{{ "category.errors.description" | translate
						}}</div>
				</div>
			</div>
			<div class="form-group">
				<label for="active"> {{ "form.active.label" | translate }}:</label><br>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null"
						formControlName="active" type="radio" name="active" id="active1"
						value="1">
					<label class="form-check-label" for="price_by_person1">{{ "form.active.yes" | translate
						}}</label>
				</div>
				<div class="form-check form-check-inline">
					<input class="form-check-input" [attr.disabled]="view() ? '' : null"
						formControlName="active" type="radio" name="active" id="active2"
						value="0">
					<label class="form-check-label" for="active2">{{  "form.active.no" | translate
						}}</label>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button class="btn btn-danger mr-3" routerLink="/admin/category">{{ "form.cancel" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="create()">{{ "form.save" | translate }}</button>
				<button type="submit" class="btn btn-primary" *ngIf="edit()">{{ "form.update" | translate }}</button>
			</div>
		</form>
	</div>
</div>