import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from '../shared/crud.service';

@Injectable({
  providedIn: 'root'
})
export class RaffleService extends CrudService {
  private mercadoLibreSearchEndpoint = 'https://api.mercadolibre.com/sites/MLM/search?q=';

  constructor(protected http: HttpClient) {
    super(http);
    this.setObject('raffle');
  }

  searchMercadoLibre(search: string): Observable<any> {
    return this.http.get(this.mercadoLibreSearchEndpoint + search + '&limit=5');
  }

  admin(): Observable<any> {
    return this.http.get(this.apiUrl + '/raffle/admin');
  }

  upload(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/raffle/upload-photo', data);
  }

  cancel(id: any, comments: string): Observable<any> {
    return this.http.post(this.apiUrl + `/raffle/${id}/cancel`, { comments: comments });
  }

  allWithFilter(filter): Observable<any> {
    return this.http.get(this.apiUrl + `/raffle${filter}`);
  }

  winnerDetail(idRaffle): Observable<any> {
    return this.http.get(this.apiUrl + `/raffle/${idRaffle}/winner-detail`);
  }

  record(): Observable<any> {
    return this.http.get(this.apiUrl + '/raffle/record');
  }

  getTickets(id): Observable<any> {
    return this.http.get(this.apiUrl + `/raffle/${id}/tickets`);
  }

  lockTicket(id: any): Observable<any> {
    return this.http.put(this.apiUrl + `/ticket/lock/${id}`, {});
  }

  unlockTicket(id: any): Observable<any> {
    return this.http.put(this.apiUrl + `/ticket/unlock/${id}`, {});
  }

  randomTicket(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/ticket/random', data);
  }

  wallet(): Observable<any> {
    return this.http.get(this.apiUrl + '/wallet');
  }

  cards(): Observable<any> {
    return this.http.get(this.apiUrl + '/card');
  }

  order(id, data): Observable<any> {
    return this.http.post(this.apiUrl + `/ticket/payment/${id}/order`, data);
  }

  results(): Observable<any> {
    return this.http.get(this.apiUrl + '/raffle/results');
  }

  digitalPrize(idRaffle, data: any): Observable<any> {
    return this.http.post(this.apiUrl + `/raffle/${idRaffle}/digital-prize`, data);
  }

  raffleReport(data: any): Observable<any> {
    return this.http.post(this.apiUrl + '/raffle-report', data);
  }
}
