import { Component, NgZone, OnInit, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ListService } from 'src/app/shared/list-service.service';
import { ListComponent } from 'src/app/shared/list/list.component';


import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faDownload, faHome, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { RaffleService } from 'src/app/raffle-module/raffle.service';
import { AG_GRID_LOCALE_EN } from 'src/app/shared/list/locale/en.locale';
import { ReportService } from '../report.service';
import { AG_GRID_LOCALE_ES } from 'src/app/shared/list/locale/es.locale';

const PENDING = 'pending';
const ACCEPTED = 'accepted';
const NOT_GENERATED = 'not_generated';

@Component({
  selector: 'app-raffles',
  templateUrl: './raffles.component.html',
  styleUrls: ['./raffles.component.css'],
  providers: [ListService, DecimalPipe, CurrencyPipe]
})
export class RafflesComponent extends ListComponent implements OnInit {

  faHome = faHome;
  faDownload = faDownload;
  faTicketAlt = faTicketAlt;


	constructor(
		public service: RaffleService,
		public reportService: ReportService,
		public listService: ListService,
		public translateService: TranslateService,
		public auth: AuthService,
		private zone: NgZone,
		public currencyPipe: CurrencyPipe,
		public decimalPipe: DecimalPipe,
	) {
		super(service, listService);
		this.setObject('admin/report');
	}

	ngOnInit() {
    super.ngOnInit();
		this.gridLocale = AG_GRID_LOCALE_ES;
		this.gridOptions = {
			defaultColDef: {
				resizable: true,
			},
			columnDefs: [
				{ headerName: '#', field: 'id', sortable: true, width: 60 },
				{ headerName: 'raffle.name', field: 'name', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.description', field: 'description', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.report.raffler_id', field: 'user_id', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.report.raffler', field: 'nickname', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.report.raffler_rfc', field: 'rfc', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.state', field: 'state', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.city', field: 'city', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ 
          headerName: 'raffle.prize_type',
          field: 'prize_type',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this) ,
          cellRenderer: params => 
              this.translate.instant('raffle.prize_types.' + params.value.toLowerCase())
					, 
        },
				{ 
          headerName: 'raffle.product_type',
          field: 'product_type',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
						this.translate.instant('raffle.product_types.' + params.value.toLowerCase())
					,
        },
				{ headerName: 'raffle.end', field: 'finish', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ 
          headerName: 'raffle.prize_value',
          field: 'prize_value',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
            this.currencyPipe.transform(params.data.prize_value, 'USD', 'symbol', '1.2-2')
					,
        },
				{ headerName: 'raffle.tickets', field: 'num_tickets', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
          headerName: 'raffle.ticket_price',
          field: 'ticket_price',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
            this.currencyPipe.transform(params.data.ticket_price, 'USD', 'symbol', '1.2-2')
					,
        },
				{ headerName: 'raffle.sold_tickets', field: 'sold_tickets', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.report.canceled', field: 'canceled', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.report.total_orders', field: 'total_orders', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.report.conekta_orders', field: 'conekta_orders', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{ headerName: 'raffle.report.rafiki_orders', field: 'rafiki_orders', sortable: true, headerValueGetter: this.localizeHeader.bind(this) },
				{
          headerName: 'raffle.report.conekta_fees',
          field: 'conekta_fees',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
            this.currencyPipe.transform(params.data.conekta_fees, 'USD', 'symbol', '1.2-2')
					,
        },
				{
          headerName: 'raffle.report.ticket_avg_orders',
          field: 'avg_ticket_order',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
          this.decimalPipe.transform(params.data.avg_ticket_order, '1.2-2')
        },
        {
					headerName: 'raffler-payment.status',
					field: 'payment_status',
					sortable: true,
					cellRenderer: params => {
						if (params.data.payment_status == PENDING) {
							return this.translate.instant('raffler-payment.status_raffler.pending');
						} else if (params.data.payment_status == ACCEPTED) {
							return this.translate.instant('raffler-payment.status_raffler.accepted');
						} else if (params.data.payment_status == NOT_GENERATED) {
							return this.translate.instant('raffler-payment.status_raffler.not_generated');
						}
					},
					headerValueGetter: this.localizeHeader.bind(this)
				},
        { 
          headerName: 'raffle.report.amount',
          field: 'amount',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
            this.currencyPipe.transform(params.data.amount, 'USD', 'symbol', '1.2-2')
					,
        },
        {
          headerName: 'raffle.report.rafiki_fees',
          field: 'fee_amount',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
          this.currencyPipe.transform(params.data.fee_amount, 'USD', 'symbol', '1.2-2')
        },
        {
          headerName: 'raffle.report.rafiki_fees_taxes',
          field: 'fee_taxes',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
          this.currencyPipe.transform(params.data.fee_taxes, 'USD', 'symbol', '1.2-2')
        },
        {
          headerName: 'raffle.report.isr',
          field: 'isr_taxes',
          sortable: true,
          headerValueGetter: this.localizeHeader.bind(this),
          cellRenderer: params =>
          this.currencyPipe.transform(params.data.isr_taxes, 'USD', 'symbol', '1.2-2')
        },
          
			]
		};

		this.loadData();
	}

	onFilterTextBoxChanged($event): void {
		this.gridApi.setQuickFilter($event.target.value);
	}

	onGridReady(params) {
		this.gridApi = params.api;
    	this.gridApi.refreshHeader();
		this.gridApi.sizeColumnsToFit();
		window.onresize = () => {
			this.gridApi.sizeColumnsToFit();
		}
	}

  loadData(){
    this.reportService.raffles().subscribe((data) => {
      this.data = data;
      this.listService.setData(data);
    }, (error) => {
      this.alertify.error(this.translate.instant('general.list.load_error'));
    });
  }

  public getExportUrlGeneral() {
    return environment.baseUrl + 'export/raffles';
  }

}
