<div class="bodycards">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-auto">
                <h2 class="text-capitalize text-orange">Mis boletos</h2>
            </div>
        </div>
        <div class="container" *ngIf="raffles && raffles.length > 0">
            <div class="card mb-3" *ngFor="let raffle of raffles" (click)="customShow(raffle.id, raffle.slug, raffle.winner)">
                <div class="row g-0">
                    <div class="col-md-4">
                        <div class="text-center p-3">
                            <img class="img-fluid img-thumbnail" [src]="raffle.image">
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title text-capitalize">
                                {{raffle.name}}
                                <hr>
                            </h5>
                            <div class="row g-0">
                                <div class="col-md-8 font-weight-bold">
                                    <p class="card-text">${{raffle.ticket_price}}</p>
                                    <p class="card-text text-capitalize text-sm text-orange"><fa-icon [icon]="faTicketAlt"></fa-icon> {{raffle.buyed_tickets.length}} {{ "raffle.tickets" | translate }}</p>
                                    <p class="text-sm"><fa-icon [icon]="faCalendarAlt" class="date-color"></fa-icon> {{ getFormatDate(raffle.finish) }}</p>
                                </div>
                                <div class="col-md-4 text-center text-sm">
                                    <div *ngIf="raffle.status === 'canceled' && raffle.winner === false">
                                        <img src="assets/images/icon-cancelado.png" style="height: 90px; width: 90px;">
                                        <p>Cancelado</p>
                                    </div>
                                    <div *ngIf="raffle.status === 'pending' && raffle.winner === false">
                                        <img src="assets/images/icon-pendiente.png" style="height: 90px; width: 90px;">
                                        <p>Pendiente</p>
                                    </div>
                                    <div *ngIf="raffle.winner === false && (raffle.status !== 'canceled' && raffle.status !== 'pending')">
                                        <img src="assets/images/icon-finalizado.png" style="height: 90px; width: 90px;">
                                        <p>Finalizado sin premio</p>
                                    </div>
                                    <div *ngIf="raffle.winner === true">
                                        <img src="assets/images/icon-ganador.png" style="height: 90px; width: 90px;">
                                        <p class="green-color">Ganador</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
