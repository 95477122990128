import { Component, OnInit } from '@angular/core';
import { FormComponent } from 'src/app/shared/form/form.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup , Validators, FormArray, FormControl } from '@angular/forms';
import { RoleService } from 'src/app/role-module/role.service';
import { PermissionService } from 'src/app/role-module/permission.service';
import { AlertifyService } from 'src/app/shared/alertify.service';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.css']
})
export class RoleFormComponent extends FormComponent implements OnInit {
  faUserCircle = faUserCircle;
  permissions: any[];
  rolePermissions: any[];
  permissionsForm: FormArray;
  selectedPreferences: any[];
  constructor(
    public service: RoleService,
    public permissionService: PermissionService) {
      super(service);
    }

    ngOnInit() {
      super.ngOnInit();
      this.redirect = '/role';
      this.rolePermissions = [];
      this.form  = this.formBuilder.group({
        name: ['', Validators.required],
        permissions: this.formBuilder.array([])
      });
    }

    loadError() {
      this.alertify.error('Ocurrio un error al cargar los permisos.');
      this.router.navigate([this.redirect]);
    }

    dataToForm(data: any) {
      this.rolePermissions = data.permissions;
      return {
        name: data.name,
        permissions: []
      };
    }

    formToData() {
      this.data = { name : this.f.name.value,
        permissions: this.selectedPreferences
      };
    }

    getExtras() {
      const checkPermissions = this.rolePermissions || [];
      this.permissionService.all().subscribe((permissions) => {
        this.permissions = permissions;

        this.permissions.forEach((o, i) => {
          const control = new FormControl({value: checkPermissions.indexOf(o.name) !== -1, disabled: this.view()});
          (this.form.controls.permissions as FormArray).push(control);
        });
      }, (error) => {
        this.loadError();
      });
    }

    validation() {
      this.selectedPreferences = this.form.value.permissions
          .map((checked, index) => checked ? this.permissions[index].name : null)
          .filter(value => value !== null);
      if (!this.selectedPreferences || this.selectedPreferences.length === 0) {
        this.alertify.error('Debe seleccionar al menos un permiso');
        return false;
      }
      return true;
    }

}
