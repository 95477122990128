import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { AdminUserService } from '../admin-user.service';
import { RoleService } from '../../role-module/role.service';
import { CatalogService } from 'src/app/shared/catalog.service';
import { FormComponent } from 'src/app/shared/form/form.component';
import { AuthService } from 'src/app/auth-module/auth.service';
import { faUsers } from '@fortawesome/free-solid-svg-icons';


@Component({
	selector: 'app-admin-user-form',
	templateUrl: './admin-user-form.component.html',
	styleUrls: ['./admin-user-form.component.css']
})
export class AdminUserFormComponent extends FormComponent implements OnInit {
	roles: any;
	areas: any;
	typeTests: any;
	events: any;
	selectedRole: any;
	faUsers = faUsers;
	permissionCapture = false;
	selectedEvents = [];
	selectedTests = [];

	currentUser = this.authenticationService.currentUserValue;
	constructor(
		public userService: AdminUserService,
		public roleService: RoleService,
		public catalogService: CatalogService,
		private authenticationService: AuthService) {
		super(userService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.redirect = '/admin';
		if (this.create()) {
			this.form = this.formBuilder.group({
				name: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				password: ['', Validators.required],
				confirmPassword: ['', Validators.required],
				active: ['1']
			});
		} else {
			this.form = this.formBuilder.group({
				name: ['', Validators.required],
				email: ['', [Validators.required, Validators.email]],
				active: []
			});
		}
	}

	dataToForm(data) {
		return {
			name: data.name,
			email: data.email,
			active: '' + data.active
		};
	}

	formToData() {
		this.data = {
			name: this.f.name.value,
			email: this.f.email.value,
			active: this.f.active.value,
			password: this.f.password ? this.f.password.value : null
		};
	}

	save() {
		this.service.save(this.data).subscribe((response) => {
		  
		  if (this.updated.observers.length > 0) {
			this.updated.emit(response);
		  }else {
			this.alertify.success(this.translate.instant('general.form.success_save'));
			this.router.navigate([this.redirect],{ queryParams : this.getRedirectParams()});
	
		  }
		}, (error) => {
			if(error.status == 422){
				this.alertify.error(this.translate.instant('admin-user.errors.email_repeated'));
			}else{
				this.alertify.error(this.translate.instant('general.error'));
			}
		  
		});
	  }
}